import { useGetDataPointDefinitions } from "@/app/shared/hooks/get";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import {
  IApplyBlueprintPayload,
  useApplyBlueprint
} from "@/app/shared/hooks/post/apply-blueprint";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IOption } from "@/interfaces";
import { Button, Callout } from "@tremor/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useMemo, useState } from "react";
import ReactSelect from "react-select";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FieldError } from "@/app/shared/components";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import { useGetFleetDeviceBlueprints } from "@/app/shared/hooks/get/device-blueprints";

interface ApplyBlueprintProps {
  blueprintIds: string[];
}

enum APPLY_TO {
  DATA_POINT_DEF = "DATA_POINT_DEF",
  TAGS = "TAGS",
  DEVICE_BLUEPRINTS = "DEVICE_BLUEPRINTS",
  DEVICES_IN_FLEETS = "DEVICES_IN_FLEETS",
  FLEET = "FLEET"
}

const validationSchema = yup.object({
  applyTo: yup.string().required("Select where to apply this blueprint.")
});

const applyToOptions = [
  {
    label: "Devices in Data Point Definition",
    value: APPLY_TO.DATA_POINT_DEF
  },
  // {
  //   label: "Device Blueprints",
  //   value: APPLY_TO.DEVICE_BLUEPRINTS
  // },
  {
    label: "Devices with Tags",
    value: APPLY_TO.TAGS
  },
  {
    label: "Devices in Fleets",
    value: APPLY_TO.DEVICES_IN_FLEETS
  },
  {
    label: "Fleet",
    value: APPLY_TO.FLEET
  }
];

const initialValues = {
  applyTo: "",
  [APPLY_TO.DATA_POINT_DEF]: "",
  [APPLY_TO.DEVICE_BLUEPRINTS]: "",
  [APPLY_TO.DEVICES_IN_FLEETS]: "",
  [APPLY_TO.FLEET]: "",
  [APPLY_TO.TAGS]: []
};

const ApplyBlueprint: React.FC<ApplyBlueprintProps> = ({ blueprintIds }) => {
  const { data: dpds, isLoading: dpdLoading } = useGetDataPointDefinitions();
  const { data: fleets, isLoading: fleetsLoading } = useGetFleets();
  const { data: tags, isLoading: tagsLoading } = useGetTags();

  const [selectedFleet, setSelectedFleet] = useState<IOption>(null);
  const { data: deviceBlueprints, isLoading: deviceBlueprintsLoading } =
    useGetFleetDeviceBlueprints(selectedFleet?.value);

  const applyBlueprintMutation = useApplyBlueprint();

  const dpdOptions = useMemo(
    () =>
      dpds?.map((dpd) => ({
        label: dpd.name,
        value: dpd.id
      })) ?? [],
    [dpds]
  );

  const fleetOptions = useMemo(
    () =>
      fleets?.map((dpd) => ({
        label: dpd.fleet_name,
        value: dpd.id
      })) ?? [],
    [fleets]
  );

  const tagOptions = useMemo(
    () =>
      tags?.map((tag) => ({
        label: tag.name,
        value: tag.id
      })),
    [tags]
  );

  const devBlueprintOptions = useMemo(
    () =>
      deviceBlueprints?.map((tag) => ({
        label: tag.template_name,
        value: tag.id
      })),
    [deviceBlueprints]
  );

  const onApplyClick = (values: typeof initialValues, { resetForm }) => {
    const payload: IApplyBlueprintPayload = {
      blueprint_ids: blueprintIds,
      apply_to: values.applyTo === APPLY_TO.FLEET ? "FLEET" : "DEVICE"
    };

    switch (values.applyTo) {
      case APPLY_TO.DATA_POINT_DEF:
        payload.data_point_definition_id = values[APPLY_TO.DATA_POINT_DEF];
        break;

      case APPLY_TO.DEVICES_IN_FLEETS:
        payload.fleet_id = values[APPLY_TO.DEVICES_IN_FLEETS];
        break;

      case APPLY_TO.FLEET:
        payload.fleet_id = values[APPLY_TO.FLEET];
        break;

      case APPLY_TO.DEVICE_BLUEPRINTS:
        payload.device_template_id = values[APPLY_TO.DEVICE_BLUEPRINTS];
        break;

      case APPLY_TO.TAGS:
        payload.tag_ids = values[APPLY_TO.TAGS].map((opt) => opt.value);
        break;
    }

    applyBlueprintMutation.mutate(payload, {
      onSuccess: (appliedCount) => {
        toast.success(
          values.applyTo === APPLY_TO.FLEET
            ? `Applied Blueprint(s) to Fleet!`
            : `Applied Blueprint(s)! Devices Affected: ${appliedCount}`
        );
        resetForm();
      }
    });
  };

  // TODO: Add validation, error for other fields

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onApplyClick}
    >
      {({ values }) => (
        <Form className="flex flex-col h-full">
          <div>
            <h2 className="text-lg text-contentColor">Apply Blueprints</h2>
            <div className="mt-6 flex flex-col text-sm gap-4">
              <label>
                Where to apply this blueprint?
                <Field
                  id="applyTo"
                  name="applyTo"
                  component={({ field, form, ...props }) => (
                    <ReactSelect
                      {...field}
                      {...props}
                      options={applyToOptions}
                      value={applyToOptions.find(
                        (opt) => opt.value === values["applyTo"]
                      )}
                      onChange={(opt: IOption) =>
                        form.setFieldValue("applyTo", opt.value)
                      }
                      classNames={reactSelectClassNames}
                      className="w-10/12 mt-1"
                    />
                  )}
                  placeholder="Apply To"
                />
                <ErrorMessage name="applyTo">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </label>
              {values.applyTo === APPLY_TO.DATA_POINT_DEF ? (
                <label>
                  Select Data Point Definition
                  <Field
                    id={APPLY_TO.DATA_POINT_DEF}
                    name={APPLY_TO.DATA_POINT_DEF}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isLoading={dpdLoading}
                        options={dpdOptions}
                        value={dpdOptions.find(
                          (opt) =>
                            opt.value === values[APPLY_TO.DATA_POINT_DEF]
                        )}
                        onChange={(opt: IOption) =>
                          form.setFieldValue(
                            APPLY_TO.DATA_POINT_DEF,
                            opt.value
                          )
                        }
                        menuPortalTarget={document.body}
                        classNames={reactSelectClassNames}
                        className="w-10/12 mt-1"
                      />
                    )}
                    placeholder="Select Data Point Defintion"
                  />
                  <ErrorMessage name={APPLY_TO.DATA_POINT_DEF}>
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              ) : values.applyTo === APPLY_TO.DEVICES_IN_FLEETS ? (
                <label>
                  Select Fleet
                  <Field
                    id={APPLY_TO.DEVICES_IN_FLEETS}
                    name={APPLY_TO.DEVICES_IN_FLEETS}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isLoading={fleetsLoading}
                        options={fleetOptions}
                        value={fleetOptions.find(
                          (opt) =>
                            opt.value === values[APPLY_TO.DEVICES_IN_FLEETS]
                        )}
                        onChange={(opt: IOption) =>
                          form.setFieldValue(
                            APPLY_TO.DEVICES_IN_FLEETS,
                            opt.value
                          )
                        }
                        menuPortalTarget={document.body}
                        classNames={reactSelectClassNames}
                        className="w-10/12 mt-1"
                      />
                    )}
                    placeholder="Select Fleets"
                  />
                  <ErrorMessage name={APPLY_TO.DEVICES_IN_FLEETS}>
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              ) : values.applyTo === APPLY_TO.FLEET ? (
                <label>
                  Select Fleet
                  <Field
                    id={APPLY_TO.FLEET}
                    name={APPLY_TO.FLEET}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isLoading={fleetsLoading}
                        options={fleetOptions}
                        value={fleetOptions.find(
                          (opt) => opt.value === values[APPLY_TO.FLEET]
                        )}
                        onChange={(opt: IOption) =>
                          form.setFieldValue(APPLY_TO.FLEET, opt.value)
                        }
                        menuPortalTarget={document.body}
                        classNames={reactSelectClassNames}
                        className="w-10/12 mt-1"
                      />
                    )}
                    placeholder="Select Fleets"
                  />
                  <ErrorMessage name={APPLY_TO.FLEET}>
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              ) : values.applyTo === APPLY_TO.TAGS ? (
                <label>
                  Select Tags
                  <Field
                    id={APPLY_TO.TAGS}
                    name={APPLY_TO.TAGS}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isMulti
                        isLoading={tagsLoading}
                        options={tagOptions}
                        value={values[APPLY_TO.TAGS]}
                        onChange={(opts: IOption[]) =>
                          form.setFieldValue(APPLY_TO.TAGS, opts)
                        }
                        menuPortalTarget={document.body}
                        classNames={reactSelectClassNames}
                        className="w-10/12 mt-1"
                      />
                    )}
                    placeholder="Select Tags"
                  />
                  <ErrorMessage name={APPLY_TO.TAGS}>
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              ) : values.applyTo === APPLY_TO.DEVICE_BLUEPRINTS ? (
                <>
                  <label>
                    Select Fleet
                    <ReactSelect
                      isLoading={fleetsLoading}
                      options={fleetOptions}
                      value={selectedFleet}
                      onChange={(opt: IOption) => setSelectedFleet(opt)}
                      menuPortalTarget={document.body}
                      classNames={reactSelectClassNames}
                      className="w-10/12 mt-1"
                    />
                  </label>
                  <label>
                    Select Device Blueprints
                    <Field
                      id={APPLY_TO.DEVICE_BLUEPRINTS}
                      name={APPLY_TO.DEVICE_BLUEPRINTS}
                      component={({ field, form, ...props }) => (
                        <ReactSelect
                          {...field}
                          {...props}
                          isLoading={deviceBlueprintsLoading}
                          options={devBlueprintOptions}
                          value={devBlueprintOptions.find(
                            (opt) =>
                              opt.value === values[APPLY_TO.DEVICE_BLUEPRINTS]
                          )}
                          onChange={(opt: IOption) =>
                            form.setFieldValue(
                              APPLY_TO.DEVICE_BLUEPRINTS,
                              opt.value
                            )
                          }
                          menuPortalTarget={document.body}
                          classNames={reactSelectClassNames}
                          className="w-10/12 mt-1"
                        />
                      )}
                      placeholder="Select Device Blueprint"
                    />
                    <ErrorMessage name={APPLY_TO.DEVICE_BLUEPRINTS}>
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </label>
                </>
              ) : null}
            </div>
          </div>

          <div className="mt-auto">
            {values.applyTo === APPLY_TO.DEVICE_BLUEPRINTS ? (
              <Callout className="mb-4" title="Note">
                This blueprint will only be applied to new devices created from
                the selected device blueprint.
              </Callout>
            ) : null}
            <Button
              loading={applyBlueprintMutation.isLoading}
              className="self-center"
            >
              Apply
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplyBlueprint;
