import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import Chance from "chance";
import { Smplr, loadSmplrJs } from "@smplrspace/smplr-loader";
import { Button } from "@tremor/react";
import {
  Bars3Icon,
  EllipsisHorizontalIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Space } from "@smplrspace/smplr-loader/dist/generated/smplr";
import { toast } from "react-toastify";
import {
  DeviceColorMapping,
  DeviceLabelMapping,
  EDeviceType,
  LEVEL_2_INITIAL_STATE,
  LEVEL_3_INITIAL_STATE
} from "./bms-helper";
import BmsDeviceModal from "./components/bms-device-modal.component";

const chance = new Chance();

export const SpaceViewer: FC = () => {
  const [showAddEditDevices, setShowAddEditDevices] = useState(false);
  const [task, setTask] = useState("idle");
  const [smplr, setSmplr] = useState<Smplr>();
  const [space, setSpace] = useState<Space>();
  const [addingDeviceType, setAddingDeviceType] = useState<EDeviceType>();

  const [deviceModalOpen, setDeviceModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    console.log({ action });
    switch (action.type) {
      case "add":
        return {
          ...icons,
          [action.deviceType]: [...icons[action.deviceType], action.icon]
        };
      case "update":
        return {
          ...icons,
          [action.deviceType]: icons[action.deviceType].map((pt) =>
            pt.id === action.id ? { ...pt, ...action.updates } : pt
          )
        };
      case "remove":
        return {
          ...icons,
          [action.deviceType]: icons[action.deviceType].filter(
            (i) => i.id !== action.id
          )
        };
      default:
        console.error(`Unknown action type ${action.type}`);
    }
  }, LEVEL_3_INITIAL_STATE);

  console.log(icons);

  useEffect(() => {
    // we recommend using the default value 'esm' in your code but stackblitz required 'umd'
    loadSmplrJs("umd")
      .then((smplr) => {
        const space = new smplr.Space({
          spaceId: "f434cafa-1dbf-4401-a0b2-73b52b61df08",
          clientToken: "pub_8332e2f025c44069a57d9fe83e714964",
          containerId: "test"
        });
        space.startViewer({
          preview: true,
          allowModeChange: true,
          onReady: () => {
            setSpace(space);
            setSmplr(smplr);
          },
          onError: (error) => console.error("Could not start viewer", error)
        });
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (!space || !smplr) return;

    if (task === "drawIcon") {
      space.enablePickingMode({
        onPick: ({ coordinates }) => {
          dispatchIcon({
            type: "add",
            deviceType: addingDeviceType,
            icon: {
              id: chance.guid(),
              name: `Device-${addingDeviceType}-${chance.letter({
                casing: "upper"
              })}-${chance.integer({ min: 1, max: 9 })}`,
              position: coordinates,
              type: addingDeviceType,
              color: smplr.Color.cssToSmplrColor(
                DeviceColorMapping[addingDeviceType]
              )
            }
          });
        }
      });
    }

    return () => {};
  }, [addingDeviceType, smplr, space, task]);

  // render elements
  useEffect(() => {
    if (!space) {
      return;
    }
    Object.keys(icons).forEach((dType) => {
      space.addDataLayer({
        id: "points-L1-" + dType,
        type: "point",
        shape: "sphere",
        data: icons[dType] || [],
        color: icons[dType]?.[0]?.color ?? undefined,
        diameter: 0.5,
        anchor: "bottom",
        tooltip: (d: any) => d.name,
        onClick: (el) => {
          setSelectedDevice(el);
          setDeviceModalOpen(true);
          console.log(el);
        },
        onDrop: ({ data, position }) =>
          dispatchIcon({
            type: "update",
            deviceType: dType,
            id: data.id,
            updates: { position }
          })
      });
    });

    // Object.keys(LEVEL_2_INITIAL_STATE).forEach((dType) => {
    //   space.addDataLayer({
    //     id: "points-L2-" + dType,
    //     type: "point",
    //     shape: "sphere",
    //     data: icons[dType] || [],
    //     color: icons[dType]?.[0]?.color ?? undefined,
    //     diameter: 0.5,
    //     anchor: "bottom",
    //     tooltip: (d: any) => d.name,
    //     onClick: (el) => {
    //       toast.info(`${el.name} clicked`);
    //       console.log(el);
    //     },
    //     onDrop: ({ data, position }) =>
    //       dispatchIcon({
    //         type: "update",
    //         deviceType: dType,
    //         id: data.id,
    //         updates: { position }
    //       })
    //   });
    // });
  }, [space, icons, addingDeviceType]);

  return (
    <>
      <div className="smplr-wrapper h-full w-full relative">
        {showAddEditDevices ? (
          <div className="absolute z-10 top-4 left-4 flex flex-col gap-2 border border-primary bg-background p-2 shadow-xl rounded-md">
            <div className="flex gap-2 flex-nowrap w-full justify-between ml-1">
              <span className="text-lg">Add Devices:</span>
              <XMarkIcon
                width={18}
                className="text-contentColor cursor-pointer"
                onClick={() => setShowAddEditDevices(false)}
              />
            </div>
            <div>
              <h4>Devices</h4>
              {(
                Object.keys(EDeviceType) as Array<keyof typeof EDeviceType>
              ).map((dType) => (
                <React.Fragment key={dType}>
                  <hr />
                  <div className="mt-2">
                    <h3 className="flex justify-between gap-2 items-center">
                      <span className="text-lg">
                        {DeviceLabelMapping[dType]}
                      </span>
                      {task === "idle" || addingDeviceType !== dType ? (
                        <Button
                          size="xs"
                          variant="secondary"
                          disabled={
                            task === "drawIcon" && addingDeviceType !== dType
                          }
                          onClick={() => {
                            setAddingDeviceType(dType as EDeviceType);
                            setTask("drawIcon");
                          }}
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          size="xs"
                          variant="secondary"
                          onClick={() => {
                            setTask("idle");
                          }}
                        >
                          Done
                        </Button>
                      )}
                    </h3>
                    <div className="flex flex-col my-2 ">
                      {icons[dType]?.map((p) => (
                        <span
                          className="flex justify-between gap-4"
                          key={p.id}
                        >
                          {p.name}{" "}
                          <Button
                            icon={TrashIcon}
                            variant="light"
                            color="red"
                            onClick={() =>
                              dispatchIcon({
                                type: "remove",
                                id: p.id,
                                deviceType: dType
                              })
                            }
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          // <div className="">
          <Button
            icon={Bars3Icon}
            className="absolute z-10 top-4 left-4 shadow-xl"
            onClick={() => setShowAddEditDevices(true)}
          >
            {/* <
            className="h-6 w-6 text-contentColor cursor-pointer"
          /> */}
            Add Devices
          </Button>
        )}
        <div id="test" className="smplr-embed h-full w-full"></div>
      </div>
      <BmsDeviceModal
        open={deviceModalOpen}
        setOpen={setDeviceModalOpen}
        selectedDevice={selectedDevice}
      />
    </>
  );
};
