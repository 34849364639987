import { IOption } from "@/interfaces";
import {
  EDeviceDataInputCompression,
  EDeviceDataInputFormat
} from "@/interfaces/data-policies.interface";

export const defaultDatapoint = `/*
*
* The following is an example of a datapoint definition.
* Here we create a root message called battery and define its fields.
* The root message can have nested messages and fields.
* 
* Battery has a sub message called cell, which is defined above it.
* We also have an enum called battery_state, which is used in the battery message.
*
* Uncomment by using Ctrl + / or Cmd + / on Mac
* 
* Note: The root message name, fields, and enums should be unique.
* 
**/

syntax="proto3";

enum battery_state{
    UNKNOWN = 0;
    CHARGING = 1;
    DISCHARGING = 2;
    CHARGED = 3;
    DISCHARGED = 4;
    OVERCHARGED = 5;
    OVERDISCHARGED = 6;
    OVERTEMPERATURE = 7;
    OVERCURRENT = 8;
    OVERVOLTAGE = 9;
    UNDERVOLTAGE = 10;
    UNDERTEMPERATURE = 11;
    UNDERCURRENT = 12;
    FAULT = 13;
}

message cell{
    float voltage = 1;
    float in_current = 2;
    float out_current = 3;
    float temperature = 4;
}

message battery{
    float nominal_voltage = 1;
    float nominal_capacity = 2;
    float percentage_capacity = 3;
    uint32 num_cells = 4;
    repeated cell cells = 5;
    float voltage = 6;
    float in_current = 7;
    float out_current = 8;
    battery_state state = 9;
}
`;

export type TDeviceDataPoliciesState = Record<
  string,
  {
    showCreatePolicy: boolean;
    existingPolicy: IOption; // if existing data policy selected
    name: string;
    inputFormat: IOption<EDeviceDataInputFormat>;
    compression: IOption<EDeviceDataInputCompression>;
  }
>;

export const policyInputFormatOptions: IOption<EDeviceDataInputFormat>[] = [
  { label: "PROTOBUF", value: EDeviceDataInputFormat.PROTOBUF },
  { label: "CSV", value: EDeviceDataInputFormat.CSV },
  { label: "JSON", value: EDeviceDataInputFormat.JSON }
];

export const dataCompressionOptions: IOption<EDeviceDataInputCompression>[] = [
  { label: "None", value: EDeviceDataInputCompression.NONE },
  { label: "GZIP", value: EDeviceDataInputCompression.GZIP },
  { label: "BZIP", value: EDeviceDataInputCompression.BZIP },
  { label: "ZLIB", value: EDeviceDataInputCompression.ZLIB },
  { label: "LZ4", value: EDeviceDataInputCompression.LZ4 }
];
