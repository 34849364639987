import { IDevice } from "../../../../interfaces";
import Details from "../../../dashboard/dash-panel-details.component";
import AllDashboards from "@/app/dashboard/dashboard-all-dashbaords.component";
import { useGetDeviceBlueprints } from "@/app/shared/hooks/get/device-panels";
import { useDashboardStore } from "@/store";
import { useMemo } from "react";

interface IDetailsTab {
  device: IDevice;
}

function DeviceDashboard(props: IDetailsTab) {
  const { device } = props;

  const { data: _dashboardBlueprints } = useGetDeviceBlueprints(
    device.fleet_id,
    device.id,
    { kind: "DASHBOARD" }
  );

  const dashboardBlueprints = useMemo(() => {
    if (_dashboardBlueprints) {
      return _dashboardBlueprints;
    } else {
      return [];
    }
  }, [_dashboardBlueprints]);

  const activeDashboard = useDashboardStore((state) => state.activeDashboard);

  return (
    <>
      {!!device ? (
        <div className="bg-background-layer1">
          <AllDashboards
            renderAs="DEVICE_DASHBOARD"
            device={device}
            customDashboardHeader={device.device_name}
            configs={{ hideCreateBlueprints: true, hideCreateDashboard: true }}
            helpText="Dashboards can be applied to devices using Dashboard Blueprints."
            dashboardBlueprints={dashboardBlueprints}
          />
          <Details
            dashBlueprint={activeDashboard?.dashBlueprint}
            deviceId={device?.id}
            dashboardConfigs={{
              hideAddPanel: true,
              hideDelete: true,
              hideEditLayout: true
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default DeviceDashboard;
