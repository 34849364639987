import { FieldError } from "@/app/shared/components";
import Switch from "@/app/shared/components/switch.component";
import { useGetProjectProps } from "@/app/shared/hooks/get/project-props";
import { useUpdateConsumerSettings } from "@/app/shared/hooks/patch/update-consumer-settings";
import { copyToClipboard } from "@/app/shared/utils/helper.util";
import { EProjectPropType } from "@/interfaces/project.interface";
import { useAuthStore, useFleetAndDevicesStore } from "@/store";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";

type TMetaDataForm = Array<{ key: string; perm: number }>;

const UaOidcSettingsComponent = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [enableConsumerSignup, setEnableConsumerSignup] = useState(null);
  const [metaDataForm, setMetaDataForm] = useState<TMetaDataForm>([]);

  const { data: projectProps } = useGetProjectProps();
  const updateProjectPropsMutation = useUpdateConsumerSettings();

  const { props } = selectedProject;

  useEffect(() => {
    setEnableConsumerSignup(
      projectProps?.[EProjectPropType.CONSUMER_ENABLE_SIGNUPS] === "true"
        ? true
        : false
    );
    setMetaDataForm(
      JSON.parse(
        projectProps?.[
          EProjectPropType.CONSUMER_USER_METADATA_KEY_PERMISSIONS
        ] || "[]"
      )
    );
  }, [
    projectProps?.[EProjectPropType.CONSUMER_USER_METADATA_KEY_PERMISSIONS],
    projectProps?.[EProjectPropType.CONSUMER_ENABLE_SIGNUPS],
    projectProps
  ]);

  return (
    <Formik
      initialValues={{ connectionName: "", issuerURL: "", clientId: "" }}
      onSubmit={() => {}}
    >
      <Form className="flex flex-col flex-1">
        <div className="flex gap-2">
          <div className=" w-[396px]">
            <div className="mb-5">
              <label className="text-sm font-medium text-contentColor">
                Connection Name
              </label>

              {selectedProject.consumer_enabled ? (
                <div className="flex items-end">
                  <Field
                    type="text"
                    id="connectionName"
                    name="connectionName"
                    value={props?.consumer_connection_name ?? ""}
                    placeholder="Connection Name"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(props?.consumer_connection_name ?? "")
                      }
                      className="block ml-3.5 bg-background-layer1 p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <Field
                    type="text"
                    id="connectionName"
                    name="connectionName"
                    placeholder="Connection Name"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="connectionName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </>
              )}
            </div>

            <div className="mb-5">
              <label className="text-sm font-medium text-contentColorLight">
                Issuer URL
              </label>

              {selectedProject.consumer_enabled ? (
                <div className="flex items-end">
                  <Field
                    type="text"
                    id="issuerURL"
                    name="issuerURL"
                    value={props?.consumer_issuer_url ?? ""}
                    placeholder="Issuer URL"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(props?.consumer_connection_name ?? "")
                      }
                      className="block ml-3.5 bg-background-layer1 p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <Field
                    type="text"
                    id="issuerURL"
                    name="issuerURL"
                    placeholder="Issuer URL"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="issuerURL">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </>
              )}
            </div>

            <div className="mb-5">
              <label className="text-sm font-medium text-contentColorLight">
                Client ID
              </label>

              {selectedProject.consumer_enabled ? (
                <div className="flex items-end">
                  <Field
                    type="text"
                    id="clientId"
                    name="clientId"
                    value={props?.consumer_client_id ?? ""}
                    placeholder="Client ID"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(props?.consumer_client_id ?? "")
                      }
                      className="block ml-3.5 bg-background-layer1 p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <Field
                    type="text"
                    id="clientId"
                    name="clientId"
                    placeholder="Client ID"
                    className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="clientId">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="mb-5 ml-10 w-full">
              <div className="mb-2">
                <label className="text-sm flex gap-2 font-medium text-contentColor">
                  Enable Consumer Signup:
                  <Switch
                    checked={enableConsumerSignup}
                    className="!m-0"
                    onChange={setEnableConsumerSignup}
                  />
                </label>
              </div>
              <label className="text-sm font-medium text-contentColor">
                Meta Data
              </label>

              <div className="flex flex-col mt-2 gap-2 h-[200px] bg-background-layer1 p-3 rounded-md min-w-[400px] overflow-y-auto">
                {metaDataForm.length ? (
                  metaDataForm.map((keyPerm, ind) => (
                    <div key={ind + "keyperm"} className="flex gap-2">
                      <input
                        value={keyPerm.key}
                        onChange={(e) => {
                          const newFormData = [...metaDataForm];
                          newFormData[ind].key = e.target.value.trim();

                          setMetaDataForm(newFormData);
                          e.target.focus();
                        }}
                        className="block w-10/12 p-3 border border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <select
                        className="nodrag bg-background text-xs w-full px-2 py-1  text-contentColor border-background-layer3 rounded-md border"
                        value={keyPerm.perm}
                        onChange={(e) => {
                          const newFormData = [...metaDataForm];
                          newFormData[ind].perm = Number(e.target.value);

                          setMetaDataForm(newFormData);
                        }}
                      >
                        <option value={0}>Consumer Private</option>
                        <option value={1}>Consumer Public</option>
                        <option value={2}>Consumer Read Only</option>
                        <option value={3}>Project Private</option>
                      </select>
                      <Button
                        type="button"
                        icon={XMarkIcon}
                        color="red"
                        variant="light"
                        onClick={() => {
                          const newFormData = [...metaDataForm];
                          newFormData.splice(ind, 1);

                          setMetaDataForm(newFormData);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-xs text-contentColorLight">
                    No Meta Data Properties yet.
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-2">
                <Button
                  icon={PlusIcon}
                  type="button"
                  size="xs"
                  variant="secondary"
                  onClick={() => {
                    const newFormData = [...metaDataForm];
                    newFormData.push({
                      key: "",
                      perm: 0
                    });

                    setMetaDataForm(newFormData);
                  }}
                >
                  Permission
                </Button>
              </div>
              <div className="flex justify-end mt-2">
                <Button
                  type="button"
                  onClick={() => {
                    updateProjectPropsMutation.mutate({
                      connection_name: props?.consumer_connection_name ?? "",
                      consumer_metadata_key_perms: metaDataForm.filter(
                        (keyPerm) => keyPerm.key
                      ),
                      consumer_enable_signups: enableConsumerSignup,
                      project_id: selectedProject.id,
                      org_id: user.selectedOrg.id
                    });
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default UaOidcSettingsComponent;
