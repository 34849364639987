import appConstants from "@/app/shared/config";
import { copyToClipboard } from "@/app/shared/utils/helper.util";
import { useFleetAndDevicesStore } from "@/store";
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/24/outline";
import {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Tooltip } from "react-tooltip";

interface HTTPTriggerFullPathProps {
  path: string;
  triggerName: string;
}

const SCROLL_PIXELS = 100;

const HTTPTriggerFullPath: FunctionComponent<
  HTTPTriggerFullPathProps & HTMLAttributes<HTMLDivElement>
> = ({ path, triggerName }) => {
  const projectId = useFleetAndDevicesStore(
    (state) => state.selectedProject?.id
  );

  const wrapperRef = useRef<HTMLDivElement>();
  const scrollerRef = useRef<HTMLDivElement>();

  const [tooltipText, setTooltipText] = useState("Click to copy!");
  const [curScroll, setCurScroll] = useState(0);

  const PATH_PREFIX = useMemo(
    () =>
      `${
        new URL(appConstants.urls.baseUrl).origin
      }/${projectId}/wke/${triggerName}${path?.startsWith("/") ? "" : "/"}`,
    [path, projectId, triggerName]
  );

  useEffect(() => {
    if (!scrollerRef.current) return;

    const scrollDiv = scrollerRef.current;

    const cb = (ev) => {
      setCurScroll(scrollDiv.scrollLeft);
    };

    scrollDiv.addEventListener("scroll", cb);

    return () => {
      scrollDiv.removeEventListener("scroll", cb);
    };
  }, []);

  const onPathClick = () => {
    setTooltipText(`Copied! Make sure to replace the variable path <br />
                     elements with their actual values!`);
    copyToClipboard(PATH_PREFIX + path);

    setTimeout(() => {
      setTooltipText("Click to copy!");
    }, 7000);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        data-tooltip-id="path-tooltip"
        className="relative flex-grow items-center whitespace-nowrap overflow-x-auto overflow-y-visible mr-2 cursor-pointer"
        data-tooltip-html={tooltipText}
        onClick={onPathClick}
      >
        {scrollerRef.current?.scrollWidth > scrollerRef.current?.clientWidth &&
        curScroll > 20 ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrollerRef.current?.scrollBy({
                left: -SCROLL_PIXELS,
                behavior: "smooth"
              });
            }}
            className="absolute left-0 top-0 opacity-70 p-1 bg-background-layer3 cursor-pointer"
          >
            <ChevronLeftIcon width={16} />
          </button>
        ) : null}
        <div
          ref={scrollerRef}
          className="flex items-center text-sm whitespace-nowrap overflow-x-auto"
        >
          {triggerName
            ? PATH_PREFIX + path
            : "Please enter a trigger name for getting the Rule URL."}
        </div>
        {scrollerRef.current?.scrollWidth > scrollerRef.current?.clientWidth &&
        curScroll + scrollerRef.current?.clientWidth <
          scrollerRef.current?.scrollWidth - 20 ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrollerRef.current?.scrollBy({
                left: SCROLL_PIXELS,
                behavior: "smooth"
              });
            }}
            className="absolute right-0 top-0 opacity-70 p-1 bg-background-layer3 cursor-pointer"
          >
            <ChevronRightIcon width={16} />
          </button>
        ) : null}
      </div>
      <Tooltip float={true} id="path-tooltip" />
    </>
  );
};

export default HTTPTriggerFullPath;
