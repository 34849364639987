import { useMemo } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import useSettingsStore from "@store/settings/settings.store";
import CreateInvite from "../components/settings-org-create-invite-form.component";
import dateService from "@services/date.service";
import { useGetInvites } from "@app/shared/hooks/get/invites";
import { Button } from "@tremor/react";

function Teams() {
  const [invites, setInvites, setShowCreateInviteForm] = useSettingsStore(
    (state) => [state.invites, state.setInvites, state.setShowCreateInviteForm]
  );

  useGetInvites({}, (invites) => {
    if (invites) {
      setInvites(invites);
    }
  });

  return (
    <>
      <CreateInvite />
      <div className="h-full flex-grow flex-1 pb-12">
        <div className="flex justify-end">
          <Button
            //   icon={}
            onClick={() => setShowCreateInviteForm(true)}
            className="uppercase mb-4 !text-white"
          >
            new team
          </Button>
        </div>
        <div className="bg-background-layer0.5 rounded-md">
          <div className="flex justify-center text-center items-center text-contentColorLight text-sm min-h-[200px]">
            Each team has members and projects. <br /> Team members have access
            the team projects at the specified permission level.
          </div>
        </div>
      </div>
    </>
  );
}

export default Teams;
