import { IOption } from "./shared.interface";

export type TTriggerType = "HTTP" | "MQTT" | "PLATFORM" | "LOCATION";

export type THTTPMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";

export interface IHTTPTriggerFormState {
  name: string;
  authMode: IOption | null;
  pathPattern: string;
  headers: {
    key: string;
    defaultValue: string;
    required: boolean;
  }[];
  queryParams: {
    name: string;
    type: {
      value: string;
      label: string;
    };
    defaultValue: string;
    required: boolean;
  }[];
  bodySchema: string;
  httpMethods: THTTPMethod[];
}

export interface ITrigger {
  id: string;
  name: string;
  description?: string | null;
  trigger_type: TTriggerType;
  active: boolean;
  definition: ILocationTriggerDefinition | IHTTPTriggerDefinition;
  created_at: Date;
  deleted_at?: Date | null;
  rule_id: string;
  project_id: string;
  org_id: string;
}

export interface IHTTPTriggerDefinition {
  allowed_methods: THTTPMethod[];
  auth_type: THTTPTriggerAuthType;
  headers: HTTPTriggerHeaders;
  path_pattern?: string;
  query_params?: HTTPTriggerParams[];
  body_schema: object;
}

interface HTTPTriggerHeaders {
  required: string[];
  optional: { [key: string]: string };
}

export type THTTPTriggerAuthType =
  | "Basic"
  | "BearerTokenPlatform"
  | "BearerTokenConsumer"
  | "ApiKey";

interface HTTPTriggerParams {
  param_name: string;
  param_type: TParamType;
  default: any;
}

export type TParamType = "string" | "number" | "bool" | "struct" | "array";

export enum EFenceEvent {
  FenceEventEnter = "enter",
  FenceEventExit = "exit",
  FenceEventCross = "cross",
  FenceEventInside = "inside",
  FenceEventOutside = "outside"
}

export interface ILocationTriggerDefinition {
  fence: any;
  device_filters?: {
    tag_ids: string[];
    fleet_ids: string[];
  };
  fence_event: EFenceEvent;
}

export interface ILocationTriggerFormState {
  name: string;
  fence: any;
  fenceEvent: { label: string; value: EFenceEvent };
}
