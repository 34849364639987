import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const setDeviceMeta = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  payload: any
) => {
  const { ok } = await networkService.post<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/meta`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useSetDeviceMeta = (fleetId: string, deviceId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: any) =>
      await setDeviceMeta(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeviceMetaData");
      }
    }
  );

  return res;
};
