import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useFleetAndDevicesStore, useDashboardStore } from "../../store";
import { IDashboard, IDevice } from "../../interfaces";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowDashCreate from "./components/dash-create-modal";
import ShowError from "../shared/components/error.component";
import {
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PlusIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { useGetDashboards } from "@app/shared/hooks/get/dashboards";
import ShowLoading from "@app/shared/components/loading.component";
import { useCreateDashboard } from "@app/shared/hooks/post/create-dashboard";
import { Button } from "@tremor/react";
import DashPanelCreateBlueprintModal from "./components/dash-panel-create-blueprint-modal.component";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

interface IAllDashboardsProps {
  dashboardBlueprints?: IBlueprint[];
  renderAs: "PROJECT_DASHBOARD" | "DEVICE_DASHBOARD" | "FLEET_DASHBOARD";
  device?: IDevice;
  configs?: {
    hideCreateBlueprints: boolean;
    hideCreateDashboard: boolean;
  };
  helpText?: string;
  customDashboardHeader?: string;
}

const AllDashboards: React.FC<IAllDashboardsProps> = ({
  configs,
  device,
  renderAs,
  helpText,
  dashboardBlueprints,
  customDashboardHeader
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [showDashCreateOpen, setShowDashCreateOpen] = useState(false);

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [
    dashboards,
    activeDashboard,
    setCreateBlueprintKind,
    setCreateBlueprintModalOpen,
    setLayouts,
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.activeDashboard,
    state.setCreateBlueprintKind,
    state.setCreateBlueprintModalOpen,
    state.setLayouts,
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels
  ]);

  // Project Level Dashboards
  const {
    data: projectDashboards,
    isFetching: isFetchingDashboards,
    error
  } = useGetDashboards({}, renderAs === "PROJECT_DASHBOARD");

  // For Project Dashboards
  useEffect(() => {
    if (renderAs !== "PROJECT_DASHBOARD" || isFetchingDashboards) {
      return;
    }
    if (projectDashboards?.length) {
      setDashboards(projectDashboards);
      // get dashboard id from url
      const dashboardId = location.pathname.split("/")[2];

      const dashboard = projectDashboards.find((d) => d.id === dashboardId);

      if (dashboard) {
        // if (dashboard.meta_data?.layouts) {
        //   setLayouts(dashboard.meta_data.layouts);
        // }
        setActiveDashboard(dashboard);
      } else {
        setActiveDashboard(projectDashboards[0]);
        if (projectDashboards[0].meta_data?.layouts) {
          setLayouts(projectDashboards[0].meta_data.layouts);
        }
        navigate(`/dashboard/${projectDashboards[0].id}`, { replace: true });
      }
    } else {
      setPanels([]);
      setCarousels({});
      setDashboards([]);
      setActiveDashboard({} as IDashboard);

      navigate("/dashboard/", { replace: true });
    }

    return () => {};
  }, [projectDashboards, isFetchingDashboards, renderAs]);

  // For Device/Fleet Dashboards
  useEffect(() => {
    if (renderAs === "DEVICE_DASHBOARD" || renderAs === "FLEET_DASHBOARD") {
      if (!dashboardBlueprints?.length) {
        setActiveDashboard({} as IDashboard);
        setDashboards([]);

        return;
      }

      const blueprintToDashboards: IDashboard[] =
        dashboardBlueprints?.map((dashBlueprint) => ({
          created_at: dashBlueprint.created_at,
          id: dashBlueprint.id,
          dashboard_description: dashBlueprint.definition.description,
          dashboard_name: dashBlueprint.name,
          meta_data: dashBlueprint.meta_data,
          project_id: dashBlueprint.project_id,
          org_id: "",
          dashBlueprint,
          dashboardType: renderAs
        })) ?? [];

      setDashboards(blueprintToDashboards ?? []);

      if (blueprintToDashboards.length) {
        // get dashboard id from url
        const dashboardId = searchParams.get("dashboardId");
        const dashboard = blueprintToDashboards.find(
          (d) => d.id === dashboardId
        );

        const dashboardToRender = dashboard ?? blueprintToDashboards[0];

        if (dashboardToRender.meta_data?.layouts) {
          setLayouts(dashboardToRender.meta_data.layouts);
        }

        setActiveDashboard(dashboardToRender);
        setSearchParams((prev) => ({
          tab: prev.get("tab"),
          dashboardId: dashboardToRender?.id
        }));
      }
    }
  }, [dashboardBlueprints, renderAs, searchParams, setSearchParams]);

  const newDashboardMutation = useCreateDashboard();

  const handleCreateNewTab = (name: string, description: string) => {
    newDashboardMutation.mutate(
      {
        dashboard_name: name,
        dashboard_description: description
      },
      {
        onSuccess: (dashboardId) => {
          navigate(`/dashboard/${dashboardId}`);
          toast.success("Dashboard created successfully");
          setShowDashCreateOpen(false);
        }
      }
    );
  };

  const handleTabChange = (dashboard: IDashboard) => {
    if (dashboard.id === activeDashboard.id) {
      return;
    }

    if (!dashboardBlueprints) {
      if (dashboard.meta_data?.layouts) {
        setLayouts(dashboard.meta_data.layouts);
      } else {
        setLayouts({});
      }
    }

    if (renderAs === "PROJECT_DASHBOARD") {
      navigate(`/dashboard/${dashboard.id}`);
    } else {
      setSearchParams({
        ...searchParams,
        dashboardId: dashboard.id
      });
    }

    setPanels([]);
    setActiveDashboard(dashboard);
  };

  const renderTabHeader = (tab: IDashboard, index: number) => {
    return (
      <div
        className={`relative flex flex-row items-center !w-full text-sm font-medium ${
          tab.id === activeDashboard.id
            ? "text-primaryLight"
            : "text-contentColor"
        }`}
      >
        <h4>{tab.dashboard_name}</h4>
        {tab.id === activeDashboard.id && (
          <ChevronDownIcon className="w-4 h-4 ml-1 mt-0 text-primary" />
        )}
      </div>
    );
  };

  const renderTabList = (width: string) => {
    return dashboards.map((dashboard, index) => (
      <div
        key={index}
        className={`relative mr-4 px-2 pb-1 mt-2 ${width} cursor-pointer border-b-2  ${
          activeDashboard.id === dashboard.id
            ? "border-primaryLight"
            : "border-transparent"
        } `}
        onClick={() => handleTabChange(dashboard)}
      >
        {renderTabHeader(dashboard, index)}
      </div>
    ));
  };

  const renderCreateButton = () => {
    return (
      <Button
        icon={PlusIcon}
        variant="light"
        className="px-2 mt-2 pb-1 w-max text-sm font-medium border-b-2 border-transparent hover:border-primary"
        onClick={() => setShowDashCreateOpen(true)}
      >
        New Dashboard
      </Button>
    );
  };

  const renderCreateBlueprintButton = () => {
    return (
      <Button
        variant="light"
        icon={DocumentDuplicateIcon}
        onClick={() => {
          setCreateBlueprintKind("DASHBOARD");
          setCreateBlueprintModalOpen(true);
        }}
        className="mr-4 px-2 mt-2 pb-1 w-max text-sm font-medium border-b-2 border-transparent hover:border-primary"
      >
        Create Blueprint
      </Button>
    );
  };
  if (error) {
    return <ShowError />;
  }

  if (isFetchingDashboards) {
    return <ShowLoading />;
  }

  return (
    <>
      <ShowDashCreate
        showDashCreateOpen={showDashCreateOpen}
        setShowDashCreateOpen={setShowDashCreateOpen}
        handleCreate={handleCreateNewTab}
      />

      <div className="bg-background border-b lg:px-8 sm:px-6 border-b-background-layer2">
        <div className="relative flex flex-col justify-bet">
          <div
            className={`flex flex-col justify-between items-start w-full ${
              renderAs === "PROJECT_DASHBOARD" ||
              renderAs === "FLEET_DASHBOARD"
                ? "pt-4"
                : "mb-2"
            }`}
          >
            {renderAs === "DEVICE_DASHBOARD" ? (
              <h5 className="text-contentColorLight text-sm">{"Device"}</h5>
            ) : null}
            <h4 className="text-xl font-semibold text-contentColor">
              {customDashboardHeader ?? selectedProject.project_name}
            </h4>
          </div>
          <div className="relative flex flex-row justify-start items-center mt-1 w-full">
            {error ? (
              <ShowError />
            ) : dashboards.length > 6 ? (
              <React.Fragment>
                <div className="relative w-10/12 mb-2 justify-start">
                  <TransitionedMenu
                    buttonComponent={
                      <div className="pb-1 -mb-4 cursor-pointer border-b-2  border-primary">
                        {renderTabHeader(activeDashboard, 0)}
                      </div>
                    }
                  >
                    {dashboards.map((dashbaord) => (
                      <Menu.Item key={dashbaord.id}>
                        <div
                          key={dashbaord.id}
                          className={`relative pl-3 text-sm py-2 pr-10 cursor-pointer hover:bg-gray-100 ${
                            activeDashboard.id === dashbaord.id
                              ? "bg-gray-100"
                              : ""
                          } `}
                          onClick={() => handleTabChange(dashbaord)}
                        >
                          {dashbaord.dashboard_name}
                        </div>
                      </Menu.Item>
                    ))}
                  </TransitionedMenu>
                </div>
                {configs?.hideCreateBlueprints &&
                configs?.hideCreateDashboard ? null : (
                  <div className="flex relative mr-10">
                    {configs?.hideCreateBlueprints
                      ? null
                      : renderCreateBlueprintButton()}
                    {configs?.hideCreateDashboard
                      ? null
                      : renderCreateButton()}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-row justify-start items-center">
                  {renderTabList("w-30")}
                </div>
                {configs?.hideCreateBlueprints &&
                configs?.hideCreateDashboard ? null : (
                  <div className="w-max flex items-center gap-2">
                    {configs?.hideCreateBlueprints
                      ? null
                      : renderCreateBlueprintButton()}
                    {configs?.hideCreateDashboard
                      ? null
                      : renderCreateButton()}
                  </div>
                )}
              </div>
            )}
          </div>
          {helpText ? (
            <div className="absolute right-3 top-3">
              <span data-tooltip-id="helpText">
                <QuestionMarkCircleIcon width={24} className="text-primary" />
              </span>
            </div>
          ) : null}
        </div>
      </div>

      <Tooltip
        id="helpText"
        variant="light"
        border={"1px solid"}
        className="border-contentColor"
      >
        {helpText}
      </Tooltip>

      <DashPanelCreateBlueprintModal dashboard={activeDashboard} />

      {/* Outlet for all routes!*/}
      <Outlet />
    </>
  );
};

export default AllDashboards;
