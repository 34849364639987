import { PanelDetails } from "@/app/dashboard";
import ShowLoading from "@/app/shared/components/loading.component";
import { IDashboard } from "@/interfaces";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { useDashboardStore } from "@/store";
import { useEffect, useState } from "react";

interface DashBlueprintPreviewProps {
  blueprint: IBlueprint;
}

const DashBlueprintPreview: React.FC<DashBlueprintPreviewProps> = ({
  blueprint
}) => {
  const [setLayouts, setActiveDashboard] = useDashboardStore((state) => [
    state.setLayouts,
    state.setActiveDashboard
  ]);

  const [layoutSet, setLayoutSet] = useState(null);

  useEffect(() => {
    setActiveDashboard({} as IDashboard);

    if ("blueprint_ids" in blueprint?.definition) {
      if (blueprint.meta_data && "layouts" in blueprint.meta_data) {
        setLayouts(blueprint.meta_data.layouts);
      }

      setLayoutSet(true);
    }

    return () => {
      setLayoutSet(false);
    };
  }, [blueprint, layoutSet]);

  if (layoutSet === null) return <ShowLoading />;

  return (
    <div className="bg-background-layer1">
      <PanelDetails
        dashBlueprint={blueprint}
        dashboardConfigs={{ hideAddPanel: true, hideDelete: true }}
      />
    </div>
  );
};

export default DashBlueprintPreview;
