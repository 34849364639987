export interface ICreateDeviceResponse {
  data: {
    deviceId: string;
  };
  ok: number;
}

export interface IDevice {
  id: string;
  device_name: string;
  certificate_id: string | null;
  current_shadow: string;
  shadow_id: string;
  status_flags: string | null;
  meta_data?: any;
  tags?: string[];
  connected: boolean | null;
  last_seen: string;
  fleet_id: string;
  project_id: string;
  org_id: string;
  created_at: string;
  shadow_definition_id: string;
  pending_ota_update_id: string;
  topic_slug: string;
  updated_by: string;
}

export interface IDeviceResponse {
  data: {
    devices: IDevice[];
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  ok: number;
}

export interface IFleetsResponse {
  data: {
    fleets: IFleet[];
  };
  ok: number;
}

export interface IFleet {
  id: string;
  fleet_name: string;
  fleet_description: string;
  shadow_definition_id: string;
  provisioning_policy_id: string | null;
  logging_policy_id: string | null;
  user_authentication_policy_id: string | null;
  ota_policy_id: string | null;
  project_id: string;
  org_id: string;
  created_at: string;
  slug?: string;
  device_count?: number;
}

export enum EFleetProp {
  TRACK_CONNECTION_EVENTS = "track_connection_events",
  DATA_POLICY = "data_policy"
}

export interface IProtoStructObj {
  cardinality: string;
  name: string;
  structure: string;
  options?: string[];
}

export interface IShadowProtoStruct {
  id: string;
  name: string;
  shadow_proto: string;
  shadow_proto_descriptor: string;
  project_id: string;
  org_id: string;
  created_at: string;
  shadow_proto_structure: {
    [key: string]: IProtoStructObj;
  };
}

export interface IDeviceShadowResponse {
  data: {
    shadowDefinitions: IShadowProtoStruct[];
  };
  ok: number;
}

export interface IShadowResponse {
  data: {
    shadow: any;
  };
  ok: number;
}
