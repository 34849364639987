import appConstants from "@app/shared/config";
import {
  TGroupBy,
  IJoin,
  TOrderBy,
  IQuery,
  ISelect,
  TWhere
} from "@interfaces/query-builder";
import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface IQueryState {
  query: IQuery;
  // Add more properties as needed

  setQuery: (query: IQuery | ((prevQuery: IQuery) => IQuery)) => void;

  // setSelectClauses: (clauses: ISelect[]) => void;
  // setGroupByClauses: (clauses: IGroupBy[]) => void;
  // setWhereClauses: (clauses: IWhere[]) => void;
  // setOrderByClauses: (clauses: IOrderBy[]) => void;
  // setJoinClauses: (clauses: IJoin[]) => void;
  // Add more setter functions as needed
}

const useQueryBuilderStore = create<IQueryState>()(
  persist(
    devtools(
      (set) => ({
        query: {
          dataPointId: "",
          contextDefinitionId: "",
          select: [],
          tableName: "",
          alias: "",
          view: "",
          group: [],
          order: [],
          limit: 0,
          offset: 0,
          createView: false
        },
        // Initialize more properties as needed

        setQuery: (queryOrFunc) =>
          set(
            produce((state: IQueryState) => {
              if (typeof queryOrFunc === "function") {
                state.query = queryOrFunc(state.query);
              } else {
                state.query = queryOrFunc;
              }
            })
          ),
        setSelectClauses: (clauses) =>
          set(
            produce((state) => {
              state.selectClauses = clauses;
            })
          )
        // setGroupByClauses: (clauses) =>
        //   set(
        //     produce((state) => {
        //       state.groupByClauses = clauses;
        //     })
        //   ),
        // setWhereClauses: (clauses) =>
        //   set(
        //     produce((state) => {
        //       state.whereClauses = clauses;
        //     })
        //   ),
        // setOrderByClauses: (clauses) =>
        //   set(
        //     produce((state) => {
        //       state.orderByClauses = clauses;
        //     })
        //   ),
        // setJoinClauses: (clauses) =>
        //   set(
        //     produce((state) => {
        //       state.joinClauses = clauses;
        //     })
        //   )
        // Add more setter functions as needed
      }),
      { name: "queryBuilder", serialize: { options: true } }
    ),
    {
      name: "queryBuilder", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useQueryBuilderStore;
