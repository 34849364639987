import React from "react";
import Modal from "@app/shared/components/modal.component";
import {
  DeviceDescriptionMapping,
  DeviceLabelMapping,
  EDeviceType,
  TDeviceType,
  avgConsumptionHvac
} from "../bms-helper";
import { Badge, Button, Card, LineChart } from "@tremor/react";
import dateService from "@/services/date.service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useFleetAndDevicesStore } from "@/store";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import Gauge from "../../gauge/gauge";

interface IBmsDeviceModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  selectedDevice: {
    id: string;
    name: string;
    type: TDeviceType;
    color: string;
    position: {
      levelIndex: number;
      x: number;
      z: number;
      elevation: number;
    };
  };
}

const BmsDeviceModal: React.FC<IBmsDeviceModalProps> = ({
  selectedDevice,
  open,
  setOpen
}) => {
  const { data: fleets } = useGetFleets();
  const [setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setSelectedFleet
  ]);

  const navigate = useNavigate();
  console.log({ selectedDevice });
  return (
    <Modal
      className="overflow-visible max-w-xl w-full"
      open={open}
      setOpen={setOpen}
    >
      <div className="p-4">
        <h1 className="text-2xl mb-2 pb-2 border-b border-background-layer3 font-medium">
          {selectedDevice?.name}
        </h1>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Badge size={"xl"}>
              Floor-{selectedDevice?.position.levelIndex + 1}
            </Badge>
            <Badge size={"xl"}>Lounge</Badge>
            <Badge size={"xl"} className={`!bg-[${selectedDevice?.color}]`}>
              {DeviceLabelMapping[selectedDevice?.type]}
            </Badge>
          </div>
          <p>{DeviceDescriptionMapping[selectedDevice?.type]}</p>
          <hr />
          <div className="">
            {/* <h4 className="text-contentColorLight font-medium">Parameters:</h4> */}
            {selectedDevice?.type === EDeviceType.AQI ? (
              <div className="mt-2 flex flex-col gap-2">
                <div className="flex gap-2">
                  Current AQI Index: <Badge color={"green"}>49</Badge>
                </div>
                {/* <div className="flex gap-2">
                  AQI Category: 
                </div> */}
                <div className="flex gap-2">
                  Last Reading: <Badge>{moment().fromNow()}</Badge>
                </div>
                <Card className="mx-auto w-full mt-4" decoration="top">
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Today's AQI Readings
                  </p>
                  <div className="flex justify-end">
                    <Badge size={"xl"} color={"green"}>
                      GOOD
                    </Badge>
                  </div>
                  <div className="mt-8">
                    <Gauge
                      max={600}
                      min={0}
                      title={"panel.title"}
                      minValue={49}
                      maxValue={600}
                      units={"AQI"}
                      key={"adw"}
                      zoom={undefined}
                    />
                  </div>
                </Card>
              </div>
            ) : selectedDevice?.type === EDeviceType.HVAC ? (
              <div>
                <Card
                  className="mx-auto w-full mt-4"
                  decoration="top"
                  //   decorationColor="indigo"
                >
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Today's Consumption
                  </p>
                  <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
                    3,130 KWHr
                  </p>
                </Card>
                <Card
                  className="w-full mt-4"
                  decoration="top"
                  //   decorationColor="indigo"
                >
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Today's Avg. Consumption
                  </p>
                  <LineChart
                    className="mt-4 h-72"
                    data={avgConsumptionHvac}
                    index="date"
                    categories={["Power Consumption"]}
                    colors={["blue"]}
                    // yAxisWidth={46}
                  />
                </Card>
              </div>
            ) : selectedDevice?.type === EDeviceType.ENERGY_METERS ? (
              <div className="flex gap-2">
                <Card
                  className="mx-auto  mt-4"
                  decoration="top"
                  //   decorationColor="indigo"
                >
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Power Usage
                  </p>
                  <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
                    124 kWh
                  </p>
                </Card>
                <Card className=" mt-4" decoration="top">
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Cost Estimation
                  </p>
                  <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
                    ₹1,220.16
                  </p>
                </Card>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex w-full justify-end gap-2 mt-6">
          <Button
            className="mt-auto"
            onClick={() => {
              if (fleets) {
                switch (selectedDevice?.type) {
                  case "AQI":
                    setSelectedFleet(
                      fleets.find(
                        (f) => f.id === "bb6eaa05-755a-4cd2-aaf0-28b912a41158"
                      )
                    );
                    navigate(
                      "/fleet-and-devices/projects/device-details/cd709115-923c-4010-922b-a873e29cf737"
                    );
                    break;
                  case "BULBS":
                    setSelectedFleet(
                      fleets.find(
                        (f) => f.id === "e02e0a11-7a7e-4df5-b608-2f74628baf2f"
                      )
                    );
                    navigate(
                      "/fleet-and-devices/projects/device-details/9b0f6bb7-93e7-4671-a192-f55cf11374e6"
                    );
                    break;
                  case "ENERGY_METERS":
                    setSelectedFleet(
                      fleets.find(
                        (f) => f.id === "be2b45c2-36cf-4cac-8b7d-7a4baed209c0"
                      )
                    );
                    navigate(
                      "/fleet-and-devices/projects/device-details/bd9d33d7-a5bc-4818-920f-f185f8c470a2"
                    );
                    break;
                  case "HVAC":
                    setSelectedFleet(
                      fleets.find(
                        (f) => f.id === "1026db0b-b8d7-4310-989a-d9a7b71c5659"
                      )
                    );
                    navigate(
                      "/fleet-and-devices/projects/device-details/a7a1b806-6c04-44a9-a550-61c107f4503b"
                    );
                    break;

                  default:
                    break;
                }
              }
            }}
          >
            View Device
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default BmsDeviceModal;
