import React, { useId, useState } from "react";
import { TGroupBy } from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

interface GroupByClauseProps {
  groupBy: TGroupBy;
  selectedDPDStructure: any;
  userContextProps?: string[];
  onChangeHandler: (groupBy: TGroupBy) => void;
}

const GroupByClause: React.FC<GroupByClauseProps> = ({
  groupBy,
  selectedDPDStructure,
  userContextProps,
  onChangeHandler
}) => {
  const [hoveredGroup, setHoveredGroup] = useState(null);
  const randomId = useId();

  const onHover = (ind) => {
    setHoveredGroup(ind);
  };

  const onMouseLeave = () => {
    setHoveredGroup(null);
  };

  return (
    <div className="flex gap-4 border bg-background-layer1 border-background-layer3 p-4 mb-4 ml-12">
      {groupBy.map((group, ind) => (
        <div
          onMouseEnter={() => onHover(ind)}
          onMouseLeave={() => onMouseLeave()}
          className="flex relative flex-col"
        >
          <div className="flex justify-between mb-3 px-1">
            <span className="text-xs font-mono">GROUP {ind + 1}</span>
            {hoveredGroup === ind ? (
              <Button
                type="button"
                icon={TrashIcon}
                color="red"
                variant="light"
                className="absolute !text-sm right-1 top-0"
                onClick={() => {
                  const newGroups = [...groupBy];
                  newGroups.splice(ind, 1);
                  onChangeHandler(newGroups);
                }}
              />
            ) : null}
          </div>
          <input
            type="text"
            value={group}
            name={`${randomId}-group-by-${ind}`}
            list={`${randomId}-group-by`}
            placeholder="Column Name"
            onChange={(e) => {
              const newGroups = [...groupBy];
              newGroups[ind] = e.target.value.trim();
              onChangeHandler(newGroups);
            }}
            className="text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
          />
        </div>
      ))}
      {selectedDPDStructure ? (
        <datalist id={`${randomId}-group-by`}>
          {[
            ...Object.keys(selectedDPDStructure),
            ...["timestamp", "device_id", "fleet_id"]
          ].map((param) => (
            <option key={param}>{param}</option>
          ))}
        </datalist>
      ) : null}
      {userContextProps ? (
        <datalist id={`${randomId}-group-by`}>
          {[...userContextProps, ...["timestamp", "context_id"]].map(
            (param) => (
              <option key={param}>{param}</option>
            )
          )}
        </datalist>
      ) : null}
      <Button
        icon={PlusIcon}
        disabled={groupBy.some((group) => !group)}
        size="xs"
        className="h-min self-end mb-1"
        onClick={() => {
          onChangeHandler([...groupBy, ""]);
        }}
      />
    </div>
  );
};

export default GroupByClause;
