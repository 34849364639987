import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";

interface NoDataProps {
  msg: React.ReactNode;
}

const NoData: React.FC<NoDataProps> = ({ msg }) => {
  return (
    <div className="min-h-[60vh] w-full opacity-40 flex flex-col gap-2 justify-center items-center">
      <EnvelopeOpenIcon width={64} className="!stroke-1" />
      {msg ?? "No Data Available!"}
    </div>
  );
};

export default NoData;
