// QueryBuilder.tsx

import React, { useEffect, useState } from "react";
import QueryComponent from "./query.component";
import { useQueryBuilderStore } from "@store/query-builder";
import Modal from "@app/shared/components/modal.component";
import { Editor } from "@monaco-editor/react";
import { Button } from "@tremor/react";
import {
  deserializeQueryJSON,
  serializeQueryJSON
} from "./query-builder.helper";
import { toast } from "react-toastify";
import {
  CheckIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import { IQuery } from "@interfaces/query-builder";
import { QueryContext } from "./query-builder-provider";

interface IQueryBuiderProps {
  queryType: "user-context" | "device-data";
  error?: string;
  query?: IQuery;
  setQuery?: (q: IQuery) => void;
}

const QueryBuilder: React.FC<IQueryBuiderProps> = ({
  queryType,
  query,
  error,
  setQuery
}) => {
  const { query: savedQuery, setQuery: setSavedQuery } =
    useQueryBuilderStore();
  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState({});
  const [importError, setImportError] = useState("");
  const [showCopied, setShowCopied] = useState(false);

  const onModalSaveClick = () => {
    try {
      const _query = deserializeQueryJSON(modalJSON);
      if (setQuery) {
        setQuery(_query);
      } else {
        setSavedQuery(_query);
      }

      setJsonEditorModalOpen(false);
      toast.success("Imported Query JSON Successfully!");
      setModalJSON({});
      setImportError("");
    } catch (err) {
      toast.error(err);
      console.error(err);
      setImportError(err.message);
    }
  };

  useEffect(() => {
    let timeout;
    if (showCopied) {
      timeout = setTimeout(() => {
        setShowCopied(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showCopied]);

  return (
    <QueryContext.Provider value={{ queryType }}>
      <div className="container bg-background-layer1 mx-auto ">
        <div className="flex gap-2 items-center mb-4 justify-between">
          <div className="flex items-center gap-2">
            <h1 className="text-3xl font-bold">Query Builder</h1>
            <Button
              onClick={() => {
                const outputQuery = serializeQueryJSON(query);

                navigator.clipboard.writeText(JSON.stringify(outputQuery));
                setShowCopied(true);
              }}
            >
              {showCopied ? (
                <div className="flex gap-2">
                  <CheckIcon width={18} /> Copied!
                </div>
              ) : (
                "Generate & Copy"
              )}
            </Button>
          </div>
          <Button
            onClick={() => setJsonEditorModalOpen(true)}
            className="bg-background-layer2 hover:bg-background-layer3 text-xs h-min text-contentColor  rounded-sm"
          >
            Import
          </Button>
        </div>
        {error ? (
          <div className="flex gap-2 my-2 text-red-500 text-sm items-start">
            <ExclamationTriangleIcon width={16} />

            <p>
              Please resolve your import errors: <br /> {error}
            </p>
          </div>
        ) : null}
        <div className="max-h-[80vh] max-w-[90vw] overflow-auto">
          <QueryComponent
            isSub={false}
            query={query ?? savedQuery}
            setQuery={setQuery ?? setSavedQuery}
          />
        </div>

        {/* <pre className="mt-4">{JSON.stringify(query, null, 2)}</pre> */}
        <Modal
          open={!!jsonEditorModalOpen}
          setOpen={setJsonEditorModalOpen}
          title="JSON Editor"
          className="w-full max-w-2xl"
          disableClickOutside
        >
          <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
            <h1 className="text-lg font-bold">Import Query</h1>
            <p>
              Paste your query in JSON-format. This will over-write any changes
              you have made previously!
              <br />
            </p>
            {importError ? (
              <div className="flex gap-2 text-red-500 text-sm items-start">
                <ExclamationTriangleIcon width={16} />

                <p>
                  Please resolve your import errors: <br /> {importError}
                </p>
              </div>
            ) : null}
            <Editor
              height="500px"
              width="100%"
              theme="vs-dark"
              language="json"
              value={JSON.stringify(modalJSON, null, 2)}
              onChange={(e) => {
                try {
                  setModalJSON(JSON.parse(e));
                } catch (err) {}
              }}
              options={{
                readOnly: false,
                minimap: {
                  enabled: false
                }
              }}
            />
            <div className="flex gap-4 justify-end">
              <Button
                variant="secondary"
                onClick={() => setJsonEditorModalOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={onModalSaveClick}>Save</Button>
            </div>
          </div>
        </Modal>
      </div>
    </QueryContext.Provider>
  );
};

export default QueryBuilder;
