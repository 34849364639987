import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export interface IApplyBlueprintPayload {
  apply_to: "DEVICE" | "FLEET";
  blueprint_ids: string[];
  data_point_definition_id?: string;
  tag_ids?: string[];
  fleet_id?: string;
  device_template_id?: string;
}

interface IApplyBlueprintResponse {
  data: { appliedCount: number };
  ok: number;
}

export const applyBlueprint = async (
  projectId: string,
  orgId: string,
  payload: any
) => {
  const { data, ok } = await networkService.post<IApplyBlueprintResponse>(
    `projects/${projectId}/blueprints/apply`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.appliedCount;
  } else return null;
};

export const useApplyBlueprint = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IApplyBlueprintPayload) =>
      await applyBlueprint(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getBlueprints");
      }
    }
  );

  return res;
};
