import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from "react";
import { useDashboardStore, useFleetAndDevicesStore } from "@store/index";
import { useLocation } from "react-router-dom";

import GraphHeader from "./components/graphs/dash-graph-header.component";

import { PanelErrorProvider } from "../shared/utils/dash-panel-error.context";
import { useGetDashboardPanelData } from "@app/shared/hooks/get/panel-data";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import { useGetBlueprintPanelData } from "../shared/hooks/get/blueprint-panel-data";
import { useGetDevices } from "../shared/hooks/get/devices";
import RenderPanel from "./dash-render-panel.component";
import { getPanelDataParams } from "../shared/utils/dashboard.utils";
import { IDashboardPanel } from "@/interfaces";
import { GEO_MAP_INITIAL_BOUNDING_BOX } from "./dash-constants";
import { useGetFleetBlueprintPanelData } from "../shared/hooks/get/fleet-blueprint-panel-data";

interface IPanelWrapperProps {
  panel: IDashboardPanel;
  inCarousel?: boolean;
  deviceId?: string;
  fleetId?: string;
  handlePanelError?: (
    panelId: string,
    panelTitle: string,
    error: string
  ) => void;
}

const PanelWrapper: React.FC<IPanelWrapperProps> = ({
  panel,
  deviceId,
  fleetId,
  inCarousel = false,
  handlePanelError
}) => {
  const location = useLocation();

  const [panelError, setPanelError] = useState<string>("");

  const [activeDashboard, activeFilter, editingLayout] = useDashboardStore(
    (state) => [state.activeDashboard, state.activeFilter, state.editingLayout]
  );
  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const boundingBox = useRef(GEO_MAP_INITIAL_BOUNDING_BOX);

  const params = useMemo(
    () => getPanelDataParams(panel, activeFilter, boundingBox.current),
    [activeFilter, panel]
  );

  const { data: dashboardPanelData, error } = useGetDashboardPanelData(
    activeDashboard.id,
    panel.id,
    !editingLayout && location.pathname.startsWith("/dashboard"),
    params,
    { blockErrorToast: true, noLoader: true }
  );

  const geoMapDeviceIds = useMemo(() => {
    if (
      panel.panel_type === DASH_PANEL_TYPE.GEO_MAP &&
      dashboardPanelData &&
      "devices" in dashboardPanelData
    ) {
      return dashboardPanelData?.devices?.map((d) => d.device_id).join(",");
    }
    return "";
  }, [dashboardPanelData, panel.panel_type]);

  // for GEO_MAP panel,
  const { data: deviceWithShadows } = useGetDevices({
    shadow_format: "json",
    device_id: geoMapDeviceIds
  });

  const { data: fleetBlueprintPanelData, error: fleetBlueprintPanelError } =
    useGetFleetBlueprintPanelData(fleetId, panel.blueprint_id, true, params, {
      blockErrorToast: true,
      noLoader: true,
      refetchOnParamsChange: panel.panel_type === DASH_PANEL_TYPE.GEO_MAP,
      enabled: !!fleetId
    });

  const fleetGeoMapDeviceIds = useMemo(() => {
    if (
      panel.panel_type === DASH_PANEL_TYPE.GEO_MAP &&
      dashboardPanelData &&
      "devices" in dashboardPanelData
    ) {
      return dashboardPanelData?.devices?.map((d) => d.device_id).join(",");
    }
    return "";
  }, [dashboardPanelData, panel.panel_type]);

  // for GEO_MAP panel,
  const { data: fleetBlueprintDeviceWithShadows } = useGetDevices({
    shadow_format: "json",
    device_id: fleetGeoMapDeviceIds
  });

  const { data: blueprintPanelData, error: blueprintPanelError } =
    useGetBlueprintPanelData(
      selectedFleet?.id,
      deviceId,
      panel.blueprint_id,
      !editingLayout,
      params,
      { blockErrorToast: true, noLoader: true }
    );

  useEffect(() => {
    const _err = (error ||
      blueprintPanelError ||
      fleetBlueprintPanelError) as { message: string };
    if (_err) {
      handlePanelError &&
        handlePanelError(panel.id, panel.title, _err.message);
      setPanelError(_err.message);
    }

    return () => {};
  }, [
    error,
    blueprintPanelError,
    fleetBlueprintPanelError,
    handlePanelError,
    panel.id,
    panel.title
  ]);

  const onChangeBoundingBox = (bBox: []) => {
    if (bBox.length) {
      boundingBox.current = bBox;
    }
  };

  const renderNoDataPanel = useCallback(() => {
    return (
      <div
        className={`flex flex-col w-full h-full bg-background text-contentColor rounded-md`}
        key={panel.id}
      >
        <GraphHeader panel={panel} disableZoom />
        <div className="flex w-full flex-grow justify-center items-center">
          <h1 className="text-base">No Data Available</h1>
        </div>
      </div>
    );
  }, [panel]);

  return (
    <PanelErrorProvider value={{ panelError, setPanelError }}>
      <RenderPanel
        panel={panel}
        panelData={
          dashboardPanelData || fleetBlueprintPanelData || blueprintPanelData
        }
        renderNoDataPanel={renderNoDataPanel}
        onChangeBoundingBox={onChangeBoundingBox}
        inCarousel={inCarousel}
        boundingBox={boundingBox.current}
        geoMapDevicesWithShadows={
          deviceWithShadows?.devices ||
          fleetBlueprintDeviceWithShadows?.devices ||
          []
        }
      />
    </PanelErrorProvider>
  );
};

export default PanelWrapper;
