import Modal from "@app/shared/components/modal.component";
import useRuleEngineStore, {
  IRule
} from "@store/rule-engine/rule-engine.store";
import React, { useMemo, useState } from "react";
import RuleTriggerTypeSelector from "./rule-trigger-type-selector.component";
import { Button, Callout } from "@tremor/react";
import { toast } from "react-toastify";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { TTriggerType } from "@/interfaces/triggers.interface";

interface ICreateRuleModalProps {
  open: boolean;
  setOpen: (x: boolean | ((x: boolean) => boolean)) => void;
  onCreateRule: (newRuleData: IRule) => void;
}

const CreateRuleModal = ({
  open,
  setOpen,
  onCreateRule
}: ICreateRuleModalProps) => {
  const [newRuleData, setNewRuleData] = useState({
    name: "",
    description: "",
    triggerType: "HTTP" as TTriggerType
  });

  const rules = useRuleEngineStore((state) => state.rules);

  const ruleNameExists = useMemo(
    () => rules.hasOwnProperty(newRuleData.name),
    [newRuleData.name, rules]
  );

  return (
    <Modal
      className="overflow-visible max-w-xl w-full"
      open={open}
      setOpen={setOpen}
    >
      <div className="p-4">
        <h1 className="text-2xl mb-2 pb-2 border-b border-background-layer3 font-medium">
          Create New Rule
        </h1>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-start justify-start w-full">
            <label className="text-sm font-medium text-contentColor w-full max-w-sm">
              Rule Name*
              <input
                placeholder="Rule Name"
                value={newRuleData.name}
                className="block py-2 px-3 w-full bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                onChange={(e) => {
                  setNewRuleData({
                    ...newRuleData,
                    name: e.target.value
                  });
                }}
              />
            </label>
            <label className="text-sm font-medium text-contentColor flex-grow">
              Rule Type
              <RuleTriggerTypeSelector
                ruleTriggerType={newRuleData.triggerType}
                setRuleTriggerType={(newVal) => {
                  setNewRuleData({
                    ...newRuleData,
                    triggerType: newVal as TTriggerType
                  });
                }}
                buttonClassNames="h-full py-2 min-w-[120px] !bg-background border border-background-layer3"
              />
            </label>
          </div>
          <label className="text-sm font-medium text-contentColor w-full">
            Description*
            <textarea
              placeholder="Description"
              value={newRuleData.description}
              className="block py-2 px-3 w-full bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
              onChange={(e) => {
                setNewRuleData({
                  ...newRuleData,
                  description: e.target.value
                });
              }}
            />
          </label>
        </div>
        <Callout
          title="INFO"
          icon={InformationCircleIcon}
          className="mt-4 text-sm"
        >
          {newRuleData.triggerType === "PLATFORM" ? (
            <>
              Need to notify your team when a <b>device goes offline?</b> This
              rule will help you execute any arbitrary action or call external
              APIs whenever your device is disconnected from Golain.
              <p className="mt-2 text-xs italic">
                <b>Note: </b>Please define a{" "}
                <Link
                  className="underline"
                  to={"/fleet-and-devices/projects?tab=details"}
                >
                  {" "}
                  fleet-level policy
                </Link>{" "}
                or a{" "}
                <Link
                  className="underline"
                  to={"/settings/project-settings/project-details"}
                >
                  project-level policy
                </Link>{" "}
                for tracking device disconnection events.
              </p>
            </>
          ) : newRuleData.triggerType === "HTTP" ? (
            <>
              Define a custom <b>HTTP endpoint</b> using this rule type and
              execute any business logic! This Rule can be executed by making
              an HTTP request to the endpoint defined in the trigger.
            </>
          ) : newRuleData.triggerType === "MQTT" ? (
            <>
              This Rule will be triggered every time the device changes it's
              shadow or posts a new data point.
              <p className="mt-2 text-xs italic">
                <b>Note: </b>You can filter the devices that will execute this
                Rule inside the trigger definition.
              </p>
            </>
          ) : newRuleData.triggerType === "LOCATION" ? (
            <>
              This Rule will be triggered as soon as a device satisfies the
              GeoFence event chosen (Enter, Exit or Near).
              <p className="mt-2 text-xs italic">
                <b>Note: </b>You can create a GeoFence by drawing a polygon on
                the map, and editing it as needed.
              </p>
            </>
          ) : null}
        </Callout>
        <div className="flex w-full justify-end gap-2 mt-6">
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !newRuleData.name.trim() ||
              !newRuleData.description.trim() ||
              ruleNameExists
            }
            tooltip={ruleNameExists ? "Rule name already exists!" : null}
            onClick={() => {
              setOpen(false);
              toast.info(
                "A new rule has been created. Click Edit to open the Rule Engine!"
              );
              onCreateRule(newRuleData);
              setNewRuleData({
                name: "",
                description: "",
                triggerType: "HTTP" as TTriggerType
              });
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRuleModal;
