import React from "react";
import { IUserContextDefinition } from "@/interfaces/user-contexts.interface";
import CreateEditUserContext from "@/app/fleet-and-devices/user-contexts/fad-create-contexts.component";

interface IUserContextEditProps {
  context: IUserContextDefinition;
}

const UserContextDefinitionPreview: React.FC<IUserContextEditProps> = ({
  context
}) => {
  return (
    <div className="pt-4 px-4">
      <fieldset disabled>
        <CreateEditUserContext context={context} />
      </fieldset>
    </div>
  );
};

export default React.memo(UserContextDefinitionPreview);
