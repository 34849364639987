import { IOption } from "@/interfaces";
import {
  IHTTPTriggerDefinition,
  IHTTPTriggerFormState,
  THTTPTriggerAuthType,
  TParamType
} from "@/interfaces/triggers.interface";

export const authTypes: { label: string; value: THTTPTriggerAuthType }[] = [
  {
    label: "Platform User",
    value: "BearerTokenPlatform"
  },
  {
    label: "Consumer",
    value: "BearerTokenConsumer"
  },
  {
    label: "API Key",
    value: "ApiKey"
  }
];

export const paramTypes: IOption[] = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "bool", label: "Bool" },
  { value: "struct", label: "Struct" }
  // { value: "array", label: "Array" }
];

const getFormattedDefaultValue = (
  defaultValue: any,
  paramType: TParamType
) => {
  switch (paramType) {
    case "string":
      return defaultValue;
    case "number":
      if (defaultValue === "") {
        return 0;
      }
      return defaultValue;
    case "bool":
      if (defaultValue === "") {
        return true;
      }
      return defaultValue;

    default:
      return defaultValue;
  }
};

const serializeHTTPTrigger = (
  values: IHTTPTriggerFormState,
  projectId: string
): IHTTPTriggerDefinition => {
  const triggerDefinition: IHTTPTriggerDefinition = {
    allowed_methods: values.httpMethods,
    auth_type: values.authMode.value as THTTPTriggerAuthType,
    headers: {
      optional: values.headers
        .filter((header) => !header.required)
        .reduce((acc, cur) => {
          acc[cur.key] = cur.defaultValue;
          return acc;
        }, {}),
      required: values.headers
        .filter((header) => header.required)
        .reduce((acc, cur) => {
          acc.push(cur.key);
          return acc;
        }, [])
    },
    body_schema: JSON.parse(values.bodySchema)
  };

  if (values.pathPattern?.trim()) {
    let path = values.pathPattern?.trim();

    if (!path.startsWith("/")) {
      path = "/" + path;
    }

    if (!path.endsWith("/")) {
      path = path + "/";
    }

    path = "/" + projectId + "/wke/" + values.name + path;

    triggerDefinition.path_pattern = path;
  }

  if (values.queryParams?.length) {
    triggerDefinition.query_params = values.queryParams.map((qParam) => ({
      param_name: qParam.name,
      param_type: qParam.type.value as TParamType,
      default: qParam.required
        ? undefined
        : getFormattedDefaultValue(
            qParam.defaultValue,
            qParam.type.value as TParamType
          )
    }));
  }

  return triggerDefinition;
};

export const deserializeHTTPTrigger = (
  triggerName: string,
  definition: IHTTPTriggerDefinition,
  projectId: string
): IHTTPTriggerFormState => {
  let pathPattern = "";

  if (definition.path_pattern) {
    const escapedTriggerName = triggerName.replace(
      /[.*+?^${}()|[\]\\-]/g,
      "\\$&"
    );
    const prefixRegex = `^/${projectId}/wke/${escapedTriggerName}/`;
    pathPattern = definition.path_pattern.replace(new RegExp(prefixRegex), "");
  }

  const headers = [];

  definition.headers?.required?.forEach((header) =>
    headers.push({
      key: header,
      defaultValue: "",
      required: true
    })
  );

  Object.entries({
    ...definition.headers.optional
  }).forEach(([key, defaultValue]) =>
    headers.push({
      key,
      defaultValue,
      required: false
    })
  );

  const queryParams = (definition.query_params || []).map((qParam) => ({
    name: qParam.param_name,
    type: {
      value: qParam.param_type,
      label: getLabelForParamType(qParam.param_type)
    },
    defaultValue: qParam.default || "",
    required: true // Assuming all query params are required
  }));

  const bodySchema = JSON.stringify(definition.body_schema, undefined, 4);

  const httpMethods = definition.allowed_methods;

  return {
    name: triggerName,
    authMode: {
      value: definition.auth_type,
      label: getLabelForAuthType(definition.auth_type)
    },
    pathPattern: pathPattern,
    headers,
    queryParams,
    bodySchema,
    httpMethods
  };
};

// Helper functions to map enums to labels
const getLabelForAuthType = (authType: THTTPTriggerAuthType): string => {
  return authTypes.find((opt) => opt.value === authType)!.label;
};

const getLabelForParamType = (paramType: TParamType): string => {
  return paramTypes.find((opt) => opt.value === paramType)!.label;
};

export { serializeHTTPTrigger };
