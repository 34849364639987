import React, { MutableRefObject } from "react";

interface IDashPanelErrorToast {
  panelErrors: MutableRefObject<
    Record<string, { panelTitle: string; error: string }>
  >;
}

const DashPanelErrorToast: React.FC<IDashPanelErrorToast> = ({
  panelErrors
}) => {
  return (
    <div>
      <span className="text-sm font-bold mb-1 block">
        There were some errors fetching the data of following panels:{" "}
      </span>
      <div className="flex flex-col">
        {Object.keys(panelErrors.current).map((panelId) => (
          <div className="flex items-center" key={panelId}>
            <span className="text-xs font-medium whitespace-nowrap  ">
              {panelErrors.current[panelId].panelTitle}:
            </span>
            <span className="text-xs ml-2">
              {panelErrors.current[panelId].error}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(DashPanelErrorToast);
