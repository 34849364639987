import {
  EFenceEvent,
  ILocationTriggerDefinition,
  ILocationTriggerFormState
} from "@/interfaces/triggers.interface";
import { LatLngExpression } from "leaflet";

export const fenceEventOptions = [
  {
    label: "On Exit",
    value: EFenceEvent.FenceEventExit
  },
  {
    label: "On Enter",
    value: EFenceEvent.FenceEventEnter
  },
  {
    label: "Cross In / Out",
    value: EFenceEvent.FenceEventCross
  },
  {
    label: "While Inside",
    value: EFenceEvent.FenceEventInside
  },
  {
    label: "While Outside",
    value: EFenceEvent.FenceEventOutside
  }
];

export const serializeLocationTrigger = (
  values: ILocationTriggerFormState,
  drafts: any
): ILocationTriggerDefinition => {
  const fence = {
    type: "FeatureCollection",
    features: Object.values(drafts).map((val) => val["layer"])
  };

  const triggerDefinition: ILocationTriggerDefinition = {
    device_filters: null,
    fence,
    fence_event: values.fenceEvent.value
  };

  return triggerDefinition;
};

export const deserializeLocationTrigger = (
  triggerName: string,
  definition: ILocationTriggerDefinition
): ILocationTriggerFormState => {
  return {
    fenceEvent: fenceEventOptions.find(
      (opt) => opt.value === definition.fence_event
    ),
    fence: definition.fence,
    name: triggerName
  };
};

export const getMapCenter = (triggerDef: ILocationTriggerFormState) => {
  const firstPointCoords =
    triggerDef?.fence?.features?.[0]?.geometry?.coordinates?.[0]?.[0];
  if (!firstPointCoords) {
    return [18.5642, 73.7769] as LatLngExpression;
  }

  const center = [
    firstPointCoords[1],
    firstPointCoords[0]
  ] as LatLngExpression;

  return center;
};
