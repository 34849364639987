import { useState } from "react";
import { Buffer } from "buffer";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "@store/shadow/shadow.store";
import { toast } from "react-toastify";
import { downloadFile } from "@utils/helper.util";
import DataPointEditor from "@app/fleet-and-devices/components/fad-data-point-editor.component";
import { useNavigate } from "react-router-dom";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useUnlinkDataPoint } from "@app/shared/hooks/delete/unlink-data-point";
import { useGetDeviceDataPolicies } from "@app/shared/hooks/get/device-data-policies";
import {
  CloudArrowDownIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import JSZip from "jszip";
import usePyScrtiptStore from "@/store/pyscript.store";
import { IDevice } from "@/interfaces";
import { EDeviceDataInputCompression } from "@/interfaces/data-policies.interface";
import ApplyDataPoints from "../fad-apply-data-points.component";
import ShowLoading from "@/app/shared/components/loading.component";
import { useGetDeviceDataPoints } from "@/app/shared/hooks/get/device-data-points";

interface IDataPointsTabProps {
  device: IDevice;
}

function DataPointsTab(props: IDataPointsTabProps) {
  const { device } = props;
  const navigate = useNavigate();

  const [loadingState] = usePyScrtiptStore((state) => [state.loadingState]);

  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);
  const [setMaximizeDatapoint, setDatapoint] = useShadowStore((state) => [
    state.setMaximizeDatapoint,
    state.setDatapoint
  ]);

  const [addNewDataPoint, setAddNewDataPoint] = useState(false);

  const { data: datapoints, isFetching: dpdFetching } =
    useGetDataPointDefinitions({
      devices: device.id,
      fields: "message_name,data_point_proto,data_point_proto_descriptor"
    });

  const { data: deviceDataPoints, isFetching: ddPointsFetching } =
    useGetDeviceDataPoints(device.fleet_id, device.id);

  const { data: deviceDataPolicies, isFetching: ddPoliciesFetching } =
    useGetDeviceDataPolicies(selectedFleet.id);

  const unlinkDataPoint = async (dpId: string, dpName: string) => {
    const ddp = deviceDataPoints?.find(
      (ddp) =>
        ddp.data_point_definition_id === dpId && ddp.device_id === device.id
    );

    if (!ddp) {
      toast.error("Could not find Device Data Point ID");
      return;
    }

    unlinkDataPointMutation.mutate(ddp.id, {
      onSuccess: (ok) => {
        if (ok) {
          toast.warning(`data-point ${dpName} unlinked`);
        }
      }
    });
  };

  const handleMaximizeEditor = (datapoint: string) => {
    setDatapoint(datapoint);
    setMaximizeDatapoint({ state: true, readOnly: true });
  };

  const decodeBase64toUTF8 = (encodedData: string) => {
    return Buffer.from(encodedData, "base64").toString("utf8");
  };

  const unlinkDataPointMutation = useUnlinkDataPoint(
    selectedFleet.id,
    device.id
  );

  const onDownloadNanoPBFiles = () => {
    if (loadingState === "error") {
      toast.error(
        "Error loading the template generation files, please reload the page and try again."
      );
      return;
    }
    const zip = new JSZip();

    datapoints?.forEach((dataPointDef) => {
      const fileName = dataPointDef.name.replace(/[\W]/gi, "");

      const [header, source] = globalThis["generate_header_and_source"](
        dataPointDef.data_point_proto_descriptor,
        fileName
      );

      zip.file(`${fileName}.pb.h`, header);
      zip.file(`${fileName}.pb.c`, source);
    });

    zip.generateAsync({ type: "blob" }).then((blob) => {
      downloadFile("header_files", blob, "zip");
      toast.success("Downloaded Data Points header files!");
    });
  };

  return (
    <div className="flex w-full lg:h-[88%] md:h-[60vh]">
      {addNewDataPoint ? (
        <ApplyDataPoints
          hideSteps
          deviceId={device.id}
          fleetId={device.fleet_id}
          enableDefaultNextOperation
          onBackClick={() => {
            setAddNewDataPoint(false);
          }}
          onNextClick={() => {
            setAddNewDataPoint(false);
          }}
        />
      ) : (
        <>
          <div className="w-5/12 pb-8">
            <h1 className="text-lg text-left font-medium mb-2.5">
              Currently Available
            </h1>
            <div className="w-10/12 ">
              {dpdFetching || ddPointsFetching || ddPoliciesFetching ? (
                <ShowLoading />
              ) : (
                deviceDataPoints?.map((ddp, index) => {
                  const datapoint = datapoints?.find(
                    (dp) => dp.id === ddp.data_point_definition_id
                  );
                  const policy = deviceDataPolicies?.find(
                    (policy) => policy.id === ddp.device_data_policy_id
                  );

                  if (!policy || !datapoint) return null;

                  return (
                    <div
                      key={index}
                      className="w-full h-auto flex justify-between border border-primary rounded-lg px-5 py-3.5 mb-4"
                    >
                      <div>
                        <h1 className="flex items-center text-base text-left font-medium">
                          <span
                            onClick={() =>
                              unlinkDataPoint(datapoint.id, datapoint.name)
                            }
                            className="mr-2 cursor-pointer"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1701_2674)">
                                <rect
                                  width="16"
                                  height="16"
                                  rx="2"
                                  fill="#546CCC"
                                />
                                <path
                                  d="M12 5.40625L6.5 10.9062L4 8.40625"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1701_2674">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          {datapoint.name}
                        </h1>
                        {
                          <span className="text-sm text-left text-contentColorLight">
                            {datapoint.message_name}
                          </span>
                        }
                      </div>
                      <div>
                        <h3 className="font-medium flex gap-1">
                          {policy.policy_name}
                          <Button
                            tooltip="Edit Device Data Policy"
                            icon={PencilSquareIcon}
                            size="xs"
                            className="ml-auto"
                            variant="light"
                            onClick={() => {
                              navigate(
                                "/fleet-and-devices/projects?tab=policies",
                                {
                                  state: {
                                    policyToEdit: policy
                                  }
                                }
                              );
                            }}
                          />
                        </h3>
                        <Badge size={"xs"} tooltip="Input Format">
                          {policy.policy.input_format}
                        </Badge>
                        {policy.policy.input_compression !==
                        EDeviceDataInputCompression.NONE ? (
                          <Badge
                            size={"xs"}
                            className="ml-2"
                            color={"indigo"}
                            tooltip="Input Compression"
                          >
                            {policy.policy.input_compression}
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              )}
              <button
                className="flex items-center mb-5"
                onClick={() => setAddNewDataPoint(true)}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3.75V14.25"
                    stroke="#546CCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.75 9H14.25"
                    stroke="#546CCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ml-1.5 text-[#546CCC] font-medium">
                  Add Data point
                </span>
              </button>
            </div>
          </div>
          <div className="border-4 border-background-layer3 rounded mx-3"></div>
          <div className="w-7/12 ">
            <div className="mb-6">
              <Button
                loading={loadingState === "loading" || dpdFetching}
                disabled={!datapoints?.length}
                icon={CloudArrowDownIcon}
                onClick={onDownloadNanoPBFiles}
              >
                Download NanoPB Header Files
              </Button>
            </div>
            <div className="max-h-[80vh] flex flex-col gap-12 px-2 pb-20 overflow-y-auto">
              {ddPointsFetching || dpdFetching
                ? null
                : deviceDataPoints?.map((ddp) => {
                    const datapoint = datapoints?.find(
                      (dp) => dp.id === ddp.data_point_definition_id
                    );

                    if (!datapoint) return null;
                    return (
                      <div key={datapoint.id} className="h-48">
                        <DataPointEditor
                          isShadow={false}
                          label={datapoint.name}
                          readOnly={false}
                          customShadow={decodeBase64toUTF8(
                            datapoint.data_point_proto
                          )}
                          onEdit={() => {
                            navigate(
                              `/definitions/data-points/${datapoint.id}`
                            );
                          }}
                          handleMaximizeEditor={() =>
                            handleMaximizeEditor(
                              decodeBase64toUTF8(datapoint.data_point_proto)
                            )
                          }
                          downloadFile={() =>
                            downloadFile(
                              datapoint.name,
                              decodeBase64toUTF8(datapoint.data_point_proto)
                            )
                          }
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DataPointsTab;
