import { FieldError } from "@/app/shared/components";
import { useGetBlueprints } from "@/app/shared/hooks/get/blueprints";
import { useGetFleetDeviceBlueprints } from "@/app/shared/hooks/get/device-blueprints";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IOption } from "@/interfaces";
import { useFleetAndDevicesStore } from "@/store";
import { DocumentDuplicateIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Badge, Button, NumberInput } from "@tremor/react";
import { useMemo, useState } from "react";
import ReactSelect from "react-select";

export interface IBulkCreateFormState {
  deviceBlueprintId: {
    value: string;
    error: boolean;
  };
  deviceCount: number;
  selectedTagIds: string[];
  selectedPanelBlueprintIds: string[];
}

interface IBulkCreateDevicesProps {
  bulkCreateFormState: IBulkCreateFormState;
  setBulkCreateFormState: (x: IBulkCreateFormState) => void;
}

const BulkCreateDevices: React.FC<IBulkCreateDevicesProps> = ({
  bulkCreateFormState,
  setBulkCreateFormState
}) => {
  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const [showAddBlueprints, setShowAddBlueprints] = useState(false);

  const { data: deviceBlueprints } = useGetFleetDeviceBlueprints(
    selectedFleet.id
  );
  const { data: dashPanelBlueprints } = useGetBlueprints({
    kind: "PANEL"
  });

  const deviceBlueprintOptions = useMemo<IOption[]>(
    () =>
      deviceBlueprints?.map((blueprint) => ({
        label: blueprint.template_name,
        value: blueprint.id
      })),
    [deviceBlueprints]
  );

  const panelBlueprintOptions = useMemo<IOption[]>(
    () =>
      dashPanelBlueprints?.map((blueprint) => ({
        label: blueprint.name,
        value: blueprint.id
      })),
    [dashPanelBlueprints]
  );

  return (
    <>
      <h1 className="text-lg text-left font-medium mb-2.5">
        Create from Blueprint
      </h1>
      <div className="w-10/12">
        <div className="mb-5">
          <label className="text-sm font-medium text-contentColorLight">
            Select Device Blueprint
          </label>

          <ReactSelect
            options={deviceBlueprintOptions}
            onChange={(opt: IOption) =>
              setBulkCreateFormState({
                ...bulkCreateFormState,
                deviceBlueprintId: { value: opt.value, error: false }
              })
            }
            menuPortalTarget={document.body}
            classNames={reactSelectClassNames}
            className="mt-1"
          />
          {bulkCreateFormState?.deviceBlueprintId?.error ? (
            <FieldError message="Select Device Blueprint" />
          ) : null}
        </div>
        <div className="mb-5">
          <label className="text-sm font-medium text-contentColorLight">
            Device Count
          </label>

          <NumberInput
            value={bulkCreateFormState.deviceCount}
            onValueChange={(val) =>
              setBulkCreateFormState({
                ...bulkCreateFormState,
                deviceCount: val
              })
            }
            min={1}
            max={100}
            className="mt-1 w-6/12"
          />
        </div>

        {!showAddBlueprints ? (
          <Badge
            color="sky"
            size="xs"
            onClick={() => setShowAddBlueprints(true)}
            className="cursor-pointer mb-1 mt-2"
          >
            <span className="flex gap-2">
              <DocumentDuplicateIcon width={16} />
              Add Blueprints
            </span>
          </Badge>
        ) : (
          <div className="mb-5">
            <div className="flex items-center justify-between mb-1">
              <label className="text-sm font-medium text-contentColorLight">
                Select Panel Blueprints
              </label>
              <Button
                color="red"
                type="button"
                variant="light"
                icon={TrashIcon}
                onClick={() => setShowAddBlueprints(false)}
              />
            </div>

            <ReactSelect
              isMulti
              value={bulkCreateFormState.selectedPanelBlueprintIds.map(
                (bpId) =>
                  panelBlueprintOptions.find((opt) => opt.value === bpId)
              )}
              options={panelBlueprintOptions}
              onChange={(opts: IOption[]) =>
                setBulkCreateFormState({
                  ...bulkCreateFormState,
                  selectedPanelBlueprintIds:
                    opts?.map((opt) => opt.value) ?? []
                })
              }
              menuPortalTarget={document.body}
              classNames={reactSelectClassNames}
              className="mt-2"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BulkCreateDevices;
