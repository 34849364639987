import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDeviceDataPolicy } from "@/interfaces/data-policies.interface";

// Define the payload type for creating a device data policy
export interface ICreateDeviceDataPolicyPayload {
  policy_name: string;
  policy: IDeviceDataPolicy;
}

interface ICreateDataPolicyResponse {
  ok: number;
  data: {
    deviceDataPolicyId: string;
  };
}

export const createDeviceDataPolicy = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  payload: ICreateDeviceDataPolicyPayload
) => {
  const { ok, data } = await networkService.post<ICreateDataPolicyResponse>(
    `projects/${projectId}/fleets/${fleetId}/device_data_policies`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.deviceDataPolicyId;
  }

  return "";
};

export const useCreateDeviceDataPolicy = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      fleetId
    }: {
      fleetId: string;
      data: ICreateDeviceDataPolicyPayload;
    }) =>
      await createDeviceDataPolicy(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeviceDataPolicies");
      }
    }
  );

  return res;
};
