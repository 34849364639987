import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import { useFleetAndDevicesStore } from "@store/index";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { DebouncedInput } from "@app/shared/components/deboundced-input.component";
import { reactTableFuzzyFilter } from "@app/shared/utils/helper.util";
import { IFleet } from "@interfaces/fad.interface";
import { useGetBlueprintUsage } from "@/app/shared/hooks/get/blueprint-usage";
import { useGetDevices } from "@/app/shared/hooks/get/devices";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import { IBlueprintUsage, IBlueprint } from "@/interfaces/blueprint.interface";
import { Badge } from "@tremor/react";
import ShowLoading from "@/app/shared/components/loading.component";

interface IBlueprintUsageRow extends IBlueprintUsage {
  fleet: IFleet;
}

interface BlueprintUsageProps {
  blueprint: IBlueprint;
}

const BlueprintUsage: React.FC<BlueprintUsageProps> = ({ blueprint }) => {
  const [deviceIds, setDeviceIds] = useState([]);

  const [setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setSelectedFleet
  ]);
  const [globalFilter, setGlobalFilter] = useState("");

  const { data: fleets, isLoading: fleetsLoading } = useGetFleets();
  const { data: devicesResponse, isLoading: devicesLoading } = useGetDevices({
    device_id: deviceIds.join(",")
  });
  const { data: blueprintUsage, isLoading: usageLoading } =
    useGetBlueprintUsage(blueprint.id, {}, (usage) => {
      const _deviceIds = usage.map((el) => el.device_id);

      setDeviceIds(_deviceIds);
    });

  const navigate = useNavigate();

  const rows = useMemo(() => {
    if (!fleets || !devicesResponse || !blueprintUsage) return [];
    let _blueprintRows = [];

    if (
      devicesResponse?.devices?.length &&
      fleets?.length &&
      blueprintUsage?.length
    ) {
      blueprintUsage.forEach((blueprint) => {
        const dev = devicesResponse.devices.find(
          (d) => d.id === blueprint.device_id
        );
        const fleet = fleets.find((f) => f.id === dev?.fleet_id);
        _blueprintRows.push({
          ...blueprint,
          fleet
        });
      });
    }

    return _blueprintRows;
  }, [blueprintUsage, devicesResponse, fleets]);

  const columns = useMemo<ColumnDef<IBlueprintUsageRow, any>[]>(
    () => [
      {
        id: "device_id",
        accessorKey: "device_id",
        header: "Device",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <span
                className="hover:underline flex-grow cursor-pointer"
                onClick={() => {
                  if (row.original.fleet) {
                    setSelectedFleet(row.original.fleet);
                    navigate(
                      `/fleet-and-devices/projects/device-details/${getValue()}`
                    );
                  }
                }}
              >
                {
                  devicesResponse?.devices?.find(
                    (dev) => dev.id === getValue()
                  )?.device_name
                }
              </span>
            </div>
          );
        },
        size: 30
      },
      {
        id: "applied_from",
        accessorKey: "applied_from",
        header: "Applied From",
        cell: ({ getValue }) => {
          return <Badge>{getValue()}</Badge>;
        },
        size: 30
      }
    ],
    [devicesResponse]
  );

  const table = useReactTable({
    data: rows,
    columns,
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: reactTableFuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (fleetsLoading || devicesLoading || usageLoading) {
    return <ShowLoading />;
  }

  return (
    <div>
      <div className="my-6 max-w-sm">
        <DebouncedInput
          value={globalFilter ?? ""}
          icon={MagnifyingGlassIcon}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-background-layer3 bg-background text-contentColor"
          placeholder="Search all columns..."
        />
      </div>
      <hr className="border-background-layer3" />
      <div>
        {/* table */}
        <table className="w-full h-full my-6">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <>
                  <tr
                    key={row.id}
                    className="bg-background-layer1 border-b border-background-layer3"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="mx-2 text-sm text-center p-2 py-3"
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BlueprintUsage;
