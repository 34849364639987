import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FleetAndDevicesProjects } from ".";
import FleetHeader from "./components/fleet-header.component";
import { useGetFleets } from "../shared/hooks/get/fleets";
import { useFleetAndDevicesStore } from "@/store";
import ShowLoading from "../shared/components/loading.component";
import { Tooltip } from "react-tooltip";
import { Unauthorized } from "../shared/components";
import ShowError from "../shared/components/error.component";

const FleetAndDevicesProvider: React.FC = () => {
  const location = useLocation();

  const [setFleets] = useFleetAndDevicesStore((state) => [state.setFleets]);

  const {
    data: fleets,
    isLoading: fleetsLoading,
    error,
    isError
  } = useGetFleets({}, (fleets) => {
    setFleets(fleets);
  });

  const flag =
    location.pathname === "/fleet-and-devices/projects" ||
    location.pathname === "/fleet-and-devices/projects/";

  if (fleetsLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    const msg = (error as any).message;
    if (msg === "Missing sufficient permissions") {
      return <Unauthorized />;
    } else {
      return <ShowError />;
    }
  }

  return (
    <>
      {fleets?.length > 0 ? (
        <FleetHeader
          baseURL={"/fleet-and-devices/projects"}
          showCreate
          showDelete
          showRefresh
        />
      ) : null}
      {flag ? (
        <>
          <FleetAndDevicesProjects />
        </>
      ) : (
        <>
          <main className="flex-1 flex flex-col mx-8 mt-7 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <Outlet />
          </main>
        </>
      )}
      {/* device disconnected tooltip used in render devices & device details */}
      <Tooltip
        id="disconnected-tooltip"
        variant="light"
        border={"1px solid black"}
        offset={24}
      />
    </>
  );
};

export default FleetAndDevicesProvider;
