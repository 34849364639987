import {
  deserializeVariable,
  serializeVariable
} from "@/app/shared/utils/helper.util";
import {
  ActionType,
  IContext,
  IInput,
  deserializeFetchedContexts
} from "@app/rule-engine/rule-engine.helper";

interface IAction {
  name: string;
  description: string;
  action_type: string;
  additional_params: Record<string, any>;
  fetched_context: any[];
  definition: any;
}

const serializeActionJSON = (actionData) => {
  if (
    !actionData.inputs &&
    !actionData.actionDefinition &&
    !actionData?.actionType &&
    !actionData?.fetchedContexts
  )
    return actionData;

  const _actionData = { ...actionData };

  const _actionInputs = [...actionData?.inputs];
  const _actionDefinition = { ..._actionData?.actionDefinition };

  if (_actionDefinition["deviceId"]) {
    _actionDefinition.device_id = serializeVariable(
      _actionDefinition["deviceId"]
    );
    delete _actionDefinition["deviceId"];
  }

  if (_actionDefinition["userId"]) {
    _actionDefinition.user_id = serializeVariable(_actionDefinition["userId"]);
    delete _actionDefinition["userId"];
  }

  if (_actionDefinition["userId"]) {
    _actionDefinition.fleet_id = serializeVariable(
      _actionDefinition["userId"]
    );

    delete _actionDefinition["userId"];
  }

  const additional_params = _actionInputs?.reduce((acc, curr) => {
    acc[curr.key] = curr.type;
    return acc;
  }, {});

  const actionJSON: IAction = {
    name: actionData.name,
    description: actionData.name,
    action_type: _actionData?.actionType,
    additional_params: additional_params,
    fetched_context: _actionData?.fetchedContexts || [],
    definition: _actionDefinition || {}
  };

  switch (_actionData?.actionType) {
    case ActionType.HTTP:
      const newHeaders = _actionDefinition?.headers.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});

      actionJSON.definition.headers = newHeaders;

      if (_actionDefinition.method === "get") {
        const paramsString = _actionDefinition.params?.reduce(
          (acc, cur) => acc + cur.key + "=" + cur.value,
          ""
        );
        if (paramsString) {
          actionJSON.definition.url += "?" + paramsString;
        }
      }

      actionJSON.definition.method =
        _actionData?.actionDefinition?.method.toUpperCase();

      delete _actionDefinition["hasErrors"];
      delete _actionDefinition["params"];
      break;

    case ActionType.DEVICE_SHADOW:
      actionJSON.definition.meta = {
        shadow_definition_id: _actionDefinition.shadow_definition_id
      };

      // delete additional payload
      delete _actionDefinition["shadow_definition_id"];
      break;

    case ActionType.INSERT_DATA:
      actionJSON.definition.meta = {
        context_definition_id: _actionDefinition.context_definition_id,
        contextValues: _actionDefinition.contextValues ?? {}
      };
      actionJSON.definition["context_id"] =
        _actionDefinition.context_definition_id;
      actionJSON.definition["payload"] = Object.keys(
        _actionDefinition.contextValues ?? {}
      ).reduce((acc, field) => {
        if (_actionDefinition.contextValues[field].value) {
          acc[field] = serializeVariable(
            _actionDefinition.contextValues[field].value
          );
        }
        return acc;
      }, {});

      delete _actionDefinition["contextValues"];
      delete _actionDefinition["context_definition_id"];
      break;

    case ActionType.FCMNotification:
      const _data = _actionData?.actionDefinition?.fcm_message?.data?.reduce(
        (acc, cur) => {
          acc[cur.key] = serializeVariable(cur.value);
          return acc;
        },
        {}
      );

      actionJSON.definition = {
        credentials: serializeVariable(
          _actionData?.actionDefinition?.credentials
        ),
        environments: _actionData?.actionDefinition?.environments.map(
          (env: string) => serializeVariable(env)
        ),
        fcm_message: {
          body: serializeVariable(
            _actionData?.actionDefinition?.fcm_message?.body
          ),
          title: serializeVariable(
            _actionData?.actionDefinition?.fcm_message?.title
          ),
          token: serializeVariable(
            _actionData?.actionDefinition?.fcm_message?.token
          ),
          topic: serializeVariable(
            _actionData?.actionDefinition?.fcm_message?.topic
          ),
          data: _data
        }
      };

      break;
  }

  return actionJSON;
};

interface ISerializedActionData {
  actionType: string;
  name: string;
  inputs: IInput[];
  fetchedContexts: IContext[];
  actionDefinition: any;
}

const deserializeActionData = (actionData: IAction) => {
  const _actionData: ISerializedActionData = {
    actionType: actionData.action_type,
    name: actionData.name,
    inputs: Object.keys(actionData.additional_params ?? {}).map((key) => ({
      key,
      type: actionData.additional_params[key]
    })),
    fetchedContexts: deserializeFetchedContexts(
      actionData.fetched_context ?? []
    ),
    actionDefinition: null
  };

  switch (actionData.action_type as ActionType) {
    case ActionType.USER_METADATA:
      _actionData.actionDefinition = {
        userId: deserializeVariable(actionData.definition.user_id),
        payload: actionData.definition.payload
      };
      break;

    case ActionType.DEVICE_SHADOW:
      _actionData.actionDefinition = {
        deviceId: deserializeVariable(actionData.definition.device_id),
        payload: actionData.definition.payload,
        shadow_definition_id: actionData.definition.meta?.shadow_definition_id
      };
      break;

    case ActionType.HTTP:
      _actionData.actionDefinition = {
        url: actionData.definition.url,
        method: actionData.definition.method.toLowerCase(),
        body: actionData.definition.body,
        headers: Object.keys(actionData.definition.headers ?? {}).map(
          (key) => ({
            key,
            value: deserializeVariable(actionData.definition.headers[key])
          })
        )
      };
      break;

    case ActionType.FCMNotification:
      _actionData.actionDefinition = {
        credentials: deserializeVariable(actionData.definition.credentials),
        environments: actionData.definition.environments.map((env: string) =>
          deserializeVariable(env)
        ),
        fcm_message: {
          body: deserializeVariable(actionData.definition.fcm_message.body),
          title: deserializeVariable(actionData.definition.fcm_message.title),
          token: deserializeVariable(actionData.definition.fcm_message.token),
          topic: deserializeVariable(actionData.definition.fcm_message.topic),
          data: Object.keys(actionData.definition.headers ?? {}).map(
            (key) => ({
              key,
              value: deserializeVariable(actionData.definition.headers[key])
            })
          )
        }
      };
      break;
    case ActionType.INSERT_DATA:
      _actionData.actionDefinition = {
        contextValues: actionData.definition.meta.contextValues,
        context_definition_id: actionData.definition.meta.context_definition_id
      };
      break;

    default:
      _actionData.actionDefinition = null;
  }

  return _actionData;
};

export { serializeActionJSON, deserializeActionData };
