import { NodeType } from "@app/rule-engine/node-types.helper";
import { getEdgeId, getId } from "@app/rule-engine/rule-engine.helper";
import { getActions } from "@app/shared/hooks/get/actions";
import { toast } from "react-toastify";
import { Edge, Node } from "reactflow";

export const evalConditionResponse = (curNode, condition, resType, ind) => {
  const newId = getId();

  const _newResponseData = {
    status_code: condition[resType].status_code,
    body: condition[resType].body
  };

  const _newNode: Node = {
    id: newId,
    type: NodeType.httpResponseNode,
    data: {},
    position: {
      x: curNode?.position.x + 1000,
      y: curNode?.position.y + 250 * ind
    }
  };

  const _newEdgeId = getEdgeId();
  const _newEdge: Edge = {
    id: _newEdgeId,
    sourceHandle: `conditions-${ind}-${
      resType === "true_response" ? "true" : "false"
    }`,
    targetHandle: `http-response-${newId}`,
    source: curNode.id,
    target: newId,
    style: {
      stroke: resType === "true_response" ? "green" : "red"
    },
    type: "buttonEdge"
  };

  return {
    node: _newNode,
    edge: _newEdge,
    response: _newResponseData
  };
};

export const evalConditionSequence = (
  curNode,
  condition,
  seqType,
  ind,
  orgId,
  projId,
  setEditAction,
  onGetAction
) => {
  const newId = getId();

  const _newSeqData = {};

  const _newNode: Node = {
    id: newId,
    type: NodeType.actionSequenceNode,
    data: {
      setEditAction
    },
    position: {
      x: curNode?.position.x + 1500,
      y: curNode?.position.y + 350 * ind
    }
  };

  condition[seqType].forEach(async (action) => {
    const actions = await getActions(projId, orgId, {
      action_id: action.action_id
    });

    if (!actions?.length) {
      toast.error("Could not find action with ID: " + action.action_id);
      return;
    }

    const actionData = actions[0];
    onGetAction(_newNode, actionData, action);
  });

  const _newEdgeId = getEdgeId();
  const _newEdge: Edge = {
    id: _newEdgeId,
    sourceHandle: `conditions-${ind}-${
      seqType === "true_sequence" ? "true" : "false"
    }`,
    targetHandle: `action-sequence-${newId}`,
    source: curNode.id,
    target: newId,
    style: {
      stroke: seqType === "true_sequence" ? "green" : "red"
    },
    type: "buttonEdge"
  };

  return {
    node: _newNode,
    edge: _newEdge,
    response: _newSeqData
  };
};
