import React, { useId } from "react";
import {
  IQuery,
  TWhere,
  OPERATOR_OPTIONS,
  Operator
} from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import QueryComponent from "../query.component";
import ReactSelect from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { VariableInput } from "@/app/rule-engine/variable-input.component";
import { isIQuery } from "../query-builder.helper";

interface WhereClauseProps {
  where: TWhere;
  selectedDPDStructure: any;
  userContextProps?: string[];
  onDelete?: () => void;
  onChangeHandler: (where: TWhere) => void;
}

const WhereClause: React.FC<WhereClauseProps> = ({
  where,
  selectedDPDStructure,
  userContextProps,
  onDelete,
  onChangeHandler
}) => {
  const randomId = useId();

  const handleAddAnd = () => {
    onChangeHandler([
      ...where,
      [
        {
          param: "",
          condition: [
            {
              operator: Operator.eq,
              value: null
            }
          ]
        }
      ]
    ]);
  };

  const handleAddConfition = (ind: number, orPartInd: number) => {
    const newWhere = [...where];

    const newWhereOrParts = [...newWhere[ind]];
    const newConditions = [
      ...newWhere[ind][orPartInd].condition,
      {
        operator: Operator.eq,
        value: null
      }
    ];

    newWhereOrParts[orPartInd] = {
      ...newWhere[ind][orPartInd],
      condition: newConditions
    };
    newWhere[ind] = newWhereOrParts;

    onChangeHandler(newWhere);
  };

  const handleAddOrClause = (ind: number) => {
    const newWhere = [...where];

    const newWhereOrParts = [
      ...newWhere[ind],
      {
        param: "",
        condition: [
          {
            operator: Operator.eq,
            value: null
          }
        ]
      }
    ];

    newWhere[ind] = newWhereOrParts;

    onChangeHandler(newWhere);
  };

  const handleAddQueryAsValue = (
    andPartInd: number,
    orPartInd: number,
    condInd: number
  ) => {
    const newWhere = [...where];
    const newConditions = [...newWhere[andPartInd][orPartInd].condition];

    const newWhereQuery: IQuery = {
      dataPointId: "",
      contextDefinitionId: "",
      tableName: "",
      alias: "",
      view: "",
      group: [],
      order: [],
      limit: 10,
      offset: 0,
      createView: false,
      select: []
    };

    newConditions[condInd] = {
      ...newConditions[condInd],
      value: newWhereQuery
    };

    const newWhereOrParts = [...newWhere[andPartInd]];

    newWhereOrParts[orPartInd] = {
      ...newWhere[andPartInd][orPartInd],
      condition: newConditions
    };
    newWhere[andPartInd] = newWhereOrParts;

    onChangeHandler(newWhere);
  };

  return (
    <div className="border bg-background-layer1 border-background-layer3 p-4 mb-4 ml-12">
      {/* Delete Button */}

      <div className="flex gap-1">
        {where.length > 1 ? (
          <div className="border-l border-contentColor relative">
            <span className="bg-background-layer1 absolute top-0 bottom-0 my-auto flex-shrink h-min -left-4">
              AND
            </span>
          </div>
        ) : null}
        <div className="ml-6">
          {/* <hr className="mt-4 border-contentColor" /> */}

          {where.map((andPart, ind) => (
            <>
              <div className="flex gap-1 ml-2">
                {andPart.length > 1 ? (
                  <div className="border-l border-contentColor relative">
                    <span className="bg-background-layer1 absolute top-0 bottom-0 my-auto flex-shrink h-min -left-3.5">
                      OR
                    </span>
                  </div>
                ) : null}
                <div>
                  {andPart.map((orPart, orPartInd) => (
                    <>
                      <div className="flex flex-col gap-2 mt-0 ml-2">
                        <div className="flex justify-between">
                          <div className="flex gap-2 items-center">
                            <label>
                              <input
                                type="text"
                                value={orPart.param}
                                name={`${randomId}-where-${ind}-${orPartInd}}`}
                                list={`${randomId}-where-${ind}-${orPartInd}}`}
                                placeholder="Parameter"
                                onChange={(e) => {
                                  const newWhere = [...where];
                                  const newWhereOrParts = [...newWhere[ind]];
                                  newWhereOrParts[orPartInd] = {
                                    ...andPart[orPartInd],
                                    param: e.target.value
                                  };
                                  newWhere[ind] = newWhereOrParts;
                                  onChangeHandler(newWhere);
                                }}
                                className="ml-2 text-sm border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
                              />
                              {selectedDPDStructure ? (
                                <datalist
                                  id={`${randomId}-where-${ind}-${orPartInd}}`}
                                >
                                  {[
                                    ...Object.keys(selectedDPDStructure),
                                    ...["timestamp", "device_id", "fleet_id"]
                                  ].map((param) => (
                                    <option key={param}>{param}</option>
                                  ))}
                                </datalist>
                              ) : null}
                              {userContextProps ? (
                                <datalist
                                  id={`${randomId}-where-${ind}-${orPartInd}}`}
                                >
                                  {[
                                    ...userContextProps,
                                    ...["timestamp", "context_id"]
                                  ].map((param) => (
                                    <option key={param}>{param}</option>
                                  ))}
                                </datalist>
                              ) : null}
                            </label>
                            <Button
                              type="button"
                              variant="secondary"
                              size="xs"
                              icon={PlusIcon}
                              onClick={() =>
                                handleAddConfition(ind, orPartInd)
                              }
                              className="py-1 px-2"
                            >
                              Condition
                            </Button>
                          </div>
                          <Button
                            icon={TrashIcon}
                            variant="light"
                            color="red"
                            className="ml-auto"
                            tooltip="Delete AND clause"
                            onClick={() => {
                              const newWhere = [...where];
                              const newWhereOrParts = [...newWhere[ind]];

                              newWhereOrParts.splice(orPartInd, 1);
                              newWhere[ind] = newWhereOrParts;
                              if (newWhereOrParts.length === 0) {
                                newWhere.splice(ind, 1);
                              }

                              onChangeHandler(newWhere);
                            }}
                          />
                        </div>
                        <div className="flex gap-1 ml-4">
                          {andPart[orPartInd].condition.length > 1 ? (
                            <div className="border-l border-contentColor relative">
                              <span className="bg-background-layer1 text-xs absolute top-0 bottom-0 my-auto flex-shrink h-min -left-2">
                                OR
                              </span>
                            </div>
                          ) : null}

                          <div className="ml-4 flex flex-col justify-start gap-2">
                            {andPart[orPartInd].condition.map(
                              (cond, condInd) => (
                                <div className="flex gap-2 items-start ml-4">
                                  <label className="flex items-center">
                                    <ReactSelect
                                      options={Object.values(OPERATOR_OPTIONS)}
                                      value={OPERATOR_OPTIONS[cond.operator]}
                                      placeholder="Operator"
                                      onChange={(e: IOption) => {
                                        const newWhere = [...where];
                                        const newConditions = [
                                          ...newWhere[ind][orPartInd].condition
                                        ];
                                        newConditions[condInd] = {
                                          ...newConditions[condInd],
                                          operator: Operator[e.value]
                                        };

                                        const newWhereOrParts = [
                                          ...newWhere[ind]
                                        ];

                                        newWhereOrParts[orPartInd] = {
                                          ...andPart[orPartInd],
                                          condition: newConditions
                                        };
                                        newWhere[ind] = newWhereOrParts;
                                        onChangeHandler(newWhere);
                                      }}
                                      classNames={reactSelectClassNames}
                                      className="ml-2 min-w-[200px] !text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
                                    />
                                  </label>

                                  {cond.value !== null ? (
                                    !isIQuery(cond.value) ? (
                                      <label className="flex">
                                        <VariableInput
                                          type="text"
                                          value={
                                            cond.valueError
                                              ? cond.value
                                              : JSON.stringify(cond.value)
                                          }
                                          placeholder="Value"
                                          onChange={(val) => {
                                            const newWhere = [...where];
                                            const newConditions = [
                                              ...newWhere[ind][orPartInd]
                                                .condition
                                            ];

                                            try {
                                              const newVal = JSON.parse(val);
                                              newConditions[condInd] = {
                                                ...newConditions[condInd],
                                                value: newVal,
                                                valueError: false
                                              };
                                            } catch {
                                              newConditions[condInd] = {
                                                ...newConditions[condInd],
                                                value: val,
                                                valueError: true
                                              };
                                            }

                                            const newWhereOrParts = [
                                              ...newWhere[ind]
                                            ];

                                            newWhereOrParts[orPartInd] = {
                                              ...andPart[orPartInd],
                                              condition: newConditions
                                            };
                                            newWhere[ind] = newWhereOrParts;
                                            onChangeHandler(newWhere);
                                          }}
                                        />
                                        <div className="p-2 bg-background-layer2 border border-l-0 border-background-layer3 rounded-r-md font-mono text-sm">
                                          {cond.valueError
                                            ? "string"
                                            : Array.isArray(cond.value)
                                            ? "array"
                                            : typeof cond.value}
                                        </div>
                                      </label>
                                    ) : (
                                      <QueryComponent
                                        isSub
                                        query={cond.value}
                                        setQuery={(query) => {
                                          const newWhere = [...where];
                                          const newConditions = [
                                            ...newWhere[ind][orPartInd]
                                              .condition
                                          ];

                                          newConditions[condInd] = {
                                            ...newConditions[condInd],
                                            value: query
                                          };

                                          const newWhereOrParts = [
                                            ...newWhere[ind]
                                          ];

                                          newWhereOrParts[orPartInd] = {
                                            ...andPart[orPartInd],
                                            condition: newConditions
                                          };
                                          newWhere[ind] = newWhereOrParts;

                                          onChangeHandler(newWhere);
                                        }}
                                      />
                                    )
                                  ) : (
                                    <div className="flex gap-1 ml-4">
                                      <Button
                                        type="button"
                                        variant="secondary"
                                        size="xs"
                                        onClick={() => {
                                          const newWhere = [...where];
                                          const newConditions = [
                                            ...newWhere[ind][orPartInd]
                                              .condition
                                          ];
                                          newConditions[condInd] = {
                                            ...newConditions[condInd],
                                            value: ""
                                          };

                                          const newWhereOrParts = [
                                            ...newWhere[ind]
                                          ];

                                          newWhereOrParts[orPartInd] = {
                                            ...andPart[orPartInd],
                                            condition: newConditions
                                          };
                                          newWhere[ind] = newWhereOrParts;

                                          onChangeHandler(newWhere);
                                        }}
                                      >
                                        Value
                                      </Button>
                                      <Button
                                        type="button"
                                        variant="secondary"
                                        size="xs"
                                        onClick={() => {
                                          handleAddQueryAsValue(
                                            ind,
                                            orPartInd,
                                            condInd
                                          );
                                        }}
                                      >
                                        Query
                                      </Button>
                                    </div>
                                  )}
                                  <Button
                                    icon={TrashIcon}
                                    disabled={
                                      andPart[orPartInd].condition.length === 1
                                    }
                                    variant="light"
                                    color="red"
                                    className="ml-auto"
                                    tooltip="Delete OR clause"
                                    onClick={() => {
                                      const newWhere = [...where];
                                      const newConditions = [
                                        ...newWhere[ind][orPartInd].condition
                                      ];
                                      newConditions.splice(condInd, 1);

                                      const newWhereOrParts = [
                                        ...newWhere[ind]
                                      ];

                                      newWhereOrParts[orPartInd] = {
                                        ...andPart[orPartInd],
                                        condition: newConditions
                                      };
                                      newWhere[ind] = newWhereOrParts;

                                      onChangeHandler(newWhere);
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>

                      {orPartInd !== andPart.length - 1 ? (
                        <hr className="mt-4 border-background-layer3 ml-4 mb-4" />
                      ) : null}
                    </>
                  ))}
                </div>
                <Button
                  type="button"
                  variant="secondary"
                  size="xs"
                  icon={PlusIcon}
                  onClick={() => handleAddOrClause(ind)}
                  className="ml-4 self-center mt-4"
                >
                  OR Clause
                </Button>
              </div>
              {where.length > 1 && ind !== where.length - 1 ? (
                <hr className="mt-4 border-contentColorLight ml-4 my-2 mb-4" />
              ) : null}
            </>
          ))}
        </div>
      </div>
      <Button
        type="button"
        variant="secondary"
        size="xs"
        icon={PlusIcon}
        onClick={handleAddAnd}
        className="mt-4"
      >
        AND Clause
      </Button>
    </div>
  );
};

export default WhereClause;
