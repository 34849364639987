import { IDashboardPanel } from "@/interfaces";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import dateService from "@/services/date.service";

export function formatNumber(
  num: number,
  options: Intl.NumberFormatOptions = null
) {
  return Intl.NumberFormat(
    "en-US",
    options ?? { maximumFractionDigits: 2, notation: "compact" }
  )
    .format(num)
    .toString();
}

export function formatDuration(duration) {
  let _duration: any;
  if (duration.includes("s")) _duration = "seconds";
  else if (duration.includes("m")) _duration = "minutes";
  else _duration = "hours";

  return _duration;
}

export function getPanelDataParams(
  panel: IDashboardPanel,
  activeFilter: any,
  boundingBox: number[]
) {
  let start = activeFilter.span
    ? dateService
        .getCurrentUTCDate()
        .subtract(
          parseInt(activeFilter.span),
          formatDuration(activeFilter.span)
        )
        .format()
    : activeFilter.start;

  let stop = activeFilter.span
    ? dateService.getCurrentUTCDate().format()
    : activeFilter.stop;

  let params = { start, stop };

  if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
    params["bounding_box"] = boundingBox.join(",");
  }

  return params;
}

export function getHeatmapTransformedData(
  panel: IDashboardPanel,
  panelData: any[]
) {
  if ("options" in panel.definition) {
    return {
      [panel.definition.options.param?.value]: panelData.map((d) => {
        if ("options" in panel.definition) {
          return {
            ...d,
            value: {
              [panel.definition.options.aggregationMode]:
                // Note: due to some problems with migration, "value" was not set as an alias
                // this covers new heat-maps plus old ones with the wrong config
                d.value || d[panel.definition.options.param?.value]
            },
            time: dateService
              .convertUTCDateToUserTimezone(d.bucket || d.time)
              .toISOString()
          };
        }

        return [];
      })
    };
  }
}
