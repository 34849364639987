import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  IDashboardPanel,
  IPanelsResponse
} from "@interfaces/dashboard.interface";

export const getDashboardPanels = async (
  dashboardId: string,
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IPanelsResponse>(
    `projects/${projectId}/dashboards/${dashboardId}/panels`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dashboardPanels;
  } else return null;
};

export const useGetDashboardPanels = (
  dashboardId: string,
  params: any = {},
  successCb: ((data: IDashboardPanel[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getDashboardPanels",
      selectedProject.id,
      user.selectedOrg.id,
      params,
      dashboardId
    ],
    async () => {
      const dashboardPanels = await getDashboardPanels(
        dashboardId,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );
      successCb && successCb(dashboardPanels);

      return dashboardPanels;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!dashboardId
    }
  );

  return res;
};
