import { VariableInput } from "@/app/rule-engine/variable-input.component";
import ShowLoading from "@/app/shared/components/loading.component";
import Modal from "@/app/shared/components/modal.component";
import NoData from "@/app/shared/components/no-data.component";
import { useGetDeviceMetaData } from "@/app/shared/hooks/get/device-meta-data";
import { useSetDeviceMeta } from "@/app/shared/hooks/post/set-device-metadata";
import { IDevice } from "@/interfaces";
import { loggerService } from "@/services";
import {
  CheckIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { DiffEditor, Editor } from "@monaco-editor/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Button, Callout } from "@tremor/react";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

interface IDeviceMetadataProps {
  device: IDevice;
}

interface IMetadataRow {
  value: {
    value: any;
    error: boolean;
  };
  key: string;
}

const DeviceMetadata: React.FC<IDeviceMetadataProps> = ({ device }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [metadata, setMetadata] = useState("{}");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isNestedEditModalOpen, setNestedEditModalOpen] = useState(false);
  const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);

  const [rows, setRows] = useState<IMetadataRow[]>([]);

  const { data: originalMeta, isFetching } = useGetDeviceMetaData(
    device.fleet_id,
    device.id,
    {},
    (meta) => {
      if (meta) {
        setMetadata(JSON.stringify(meta, undefined, 4));
        setRows(
          Object.entries(meta).map(([key, val]) => ({
            key,
            value: {
              error: false,
              value: val
            }
          }))
        );
      }
    }
  );

  const [currentNestedKey, setCurrentNestedKey] = useState(null);
  const [currentNestedValue, setCurrentNestedValue] = useState("");

  const updateDeviceMetaMutation = useSetDeviceMeta(
    device.fleet_id,
    device.id
  );

  const nestedEditObjectClick = useCallback((key: string, value: any) => {
    setCurrentNestedKey(key);
    setCurrentNestedValue(JSON.stringify(value, undefined, 4));

    setNestedEditModalOpen(true);
  }, []);

  const onAddClick = () => {
    setRows([...rows, { key: "", value: { error: false, value: "" } }]);

    setTimeout(() =>
      containerRef.current.scrollTo({ top: containerRef.current.scrollHeight })
    );
  };

  const editFullJSONClick = () => {
    const json = {};

    rows.forEach((row) => {
      json[row.key] = row.value.value;
    });

    setMetadata(JSON.stringify(json, undefined, 4));
    setEditModalOpen(true);
  };

  const onFullJSONSave = () => {
    try {
      const json = JSON.parse(metadata);

      const newRows = Object.entries(json).map(([key, val]) => ({
        key,
        value: {
          error: false,
          value: val
        }
      }));
      setRows(newRows);
    } catch (error) {
      toast.error("Invalid JSON");
    }

    setEditModalOpen(false);
  };

  const onNestedJSONSave = () => {
    try {
      const json = {};

      rows.forEach((row) => {
        json[row.key] = row.value.value;
      });

      const nestedJson = JSON.parse(currentNestedValue);

      json[currentNestedKey] = nestedJson;

      const newRows = Object.entries(json).map(([key, val]) => ({
        key,
        value: {
          error: false,
          value: val
        }
      }));
      setRows(newRows);
    } catch (error) {
      toast.error("Invalid JSON");
    }

    setNestedEditModalOpen(false);
  };

  const onUpdateClick = () => {
    const json = {};

    rows.forEach((row) => {
      json[row.key] = row.value.value;
    });

    setMetadata(JSON.stringify(json, undefined, 4));
    setIsCommitModalOpen(true);
  };

  const onDiscardChanges = () => {
    setRows(
      originalMeta
        ? Object.entries(originalMeta).map(([key, val]) => ({
            key,
            value: {
              error: false,
              value: val
            }
          }))
        : []
    );
    setMetadata(
      originalMeta ? JSON.stringify(originalMeta, undefined, 4) : "{}"
    );
  };

  const onCommit = () => {
    try {
      updateDeviceMetaMutation.mutate(JSON.parse(metadata), {
        onError: () => {
          toast.error("Failed to update device metadata");
        },
        onSuccess: () => {
          toast.success("Updated device metadata");
        },
        onSettled: () => {
          setIsCommitModalOpen(false);
        }
      });
    } catch (err) {
      loggerService.error(err);
      toast.error("Invalid JSON for metadata");
    }
  };

  const columns = React.useMemo<ColumnDef<IMetadataRow>[]>(
    () => [
      {
        id: "key",
        header: "Key",
        accessorKey: "key",
        cell: ({ getValue, row }) => (
          <input
            value={getValue() as string}
            onChange={(e) => {
              setRows((rows) => {
                const newRows = [...rows];
                newRows[row.index] = {
                  ...newRows[row.index],
                  key: e.target.value
                };

                return newRows;
              });
            }}
            className="block w-[350px] mx-auto p-3 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
          />
        )
      },
      {
        id: "value",
        header: "Value",
        accessorKey: "value",
        cell: ({ getValue, row }) => {
          if (
            row.original.value.value !== null &&
            !Array.isArray(row.original.value.value) &&
            typeof row.original.value.value === "object"
          ) {
            return (
              <Button
                variant="secondary"
                onClick={() =>
                  nestedEditObjectClick(
                    row.original.key,
                    row.original.value.value
                  )
                }
              >
                Edit Object
              </Button>
            );
          } else {
            return (
              <div className="flex w-full justify-center">
                <VariableInput
                  type="text"
                  // don't want to highlight any text
                  // eslint-disable-next-line no-empty-character-class
                  keyRegex={/[]/}
                  className="rounded-l-lg"
                  value={
                    row.original.value.error
                      ? row.original.value.value
                      : JSON.stringify(row.original.value.value)
                  }
                  placeholder="Value"
                  onChange={(val) => {
                    let err = false;
                    let newVal: any;
                    try {
                      newVal = JSON.parse(val);
                    } catch {
                      newVal = val;
                      err = true;
                    }

                    setRows((rows) => {
                      const newRows = [...rows];
                      newRows[row.index] = {
                        ...newRows[row.index],
                        value: { value: newVal, error: err }
                      };
                      return newRows;
                    });
                  }}
                />
                <div className="p-2  bg-background-layer2 border border-l-0 border-background-layer3 rounded-r-md font-mono text-sm">
                  {row.original.value.error
                    ? "string"
                    : Array.isArray(row.original.value.value)
                    ? "array"
                    : row.original.value.value === null
                    ? "null"
                    : typeof row.original.value.value}
                </div>
              </div>
            );
          }
        }
      },
      {
        id: "delete",
        header: "Delete",
        cell: ({ row }) => (
          <Button
            variant="light"
            icon={TrashIcon}
            color="red"
            onClick={() => {
              setRows((rows) => {
                const newRows = [...rows];
                newRows.splice(row.index, 1);
                return newRows;
              });
            }}
          />
        )
      }
    ],
    [nestedEditObjectClick]
  );

  const tableInstance = useReactTable({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel()
  });

  if (isFetching) {
    return <ShowLoading />;
  }

  return (
    <div className="p-4">
      <Button className="ml-auto block" onClick={editFullJSONClick}>
        Edit Full JSON
      </Button>
      <div ref={containerRef} className="h-[50vh] overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-50">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.column.getSize()
                    }}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div className="select-none flex items-center justify-center gap-2">
                          {flexRender(
                            <div className="flex items-center gap-2">
                              {header.column.columnDef.header as ReactNode}
                            </div>,
                            header.getContext()
                          )}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!rows?.length ? (
              <tr>
                <td colSpan={columns.length}>
                  <NoData
                    msg={
                      <div className="flex gap-2 flex-col text-center">
                        <span>Click "Add Row" to add a key value pair</span>
                        <Button
                          onClick={onAddClick}
                          variant="secondary"
                          icon={PlusIcon}
                          className="mx-auto opacity-100"
                        >
                          Add Row
                        </Button>
                      </div>
                    }
                  />
                </td>
              </tr>
            ) : (
              tableInstance.getRowModel().rows.map((row, ind) => {
                return (
                  <tr
                    key={row.id}
                    className={`bg-background ${
                      ind === 0
                        ? "!rounded-t-md"
                        : ind === tableInstance.getTotalSize() - 1
                        ? "!rounded-b-md"
                        : ""
                    }`}
                  >
                    {row.getVisibleCells().map((cell, cellInd) => {
                      return (
                        <td
                          key={cell.id}
                          className={`mx-2 justify-center items-center whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                            ind === 0 && cellInd === 0
                              ? "rounded-tl-md"
                              : ind === tableInstance.getTotalSize() - 1 &&
                                cellInd === 0
                              ? "rounded-bl-md"
                              : ind === 0 && cellInd === columns.length - 1
                              ? "rounded-tr-md"
                              : ind === tableInstance.getTotalSize() - 1 &&
                                cellInd === columns.length - 1
                              ? "rounded-br-md"
                              : ""
                          }`}
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-6 justify-end items-end">
        <Button onClick={onAddClick} variant="secondary" icon={PlusIcon}>
          Add Row
        </Button>

        <div className="flex gap-2">
          <Button
            variant="secondary"
            icon={XMarkIcon}
            onClick={onDiscardChanges}
          >
            Discard Changes
          </Button>
          <Button variant="primary" icon={CheckIcon} onClick={onUpdateClick}>
            Update
          </Button>
        </div>
      </div>

      {/* Full JSON Edit Modal */}
      <Modal
        open={isEditModalOpen}
        setOpen={setEditModalOpen}
        className="w-full max-w-2xl"
      >
        <div className="flex flex-col gap-4 p-6 bg-background text-contentColor">
          <h1 className="text-lg font-bold">Edit Device Metadata</h1>
          <Editor
            height="400px"
            theme="vs-dark"
            language="json"
            value={metadata}
            onChange={setMetadata}
            options={{
              readOnly: false,
              lineNumbers: "off",
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() => {
                setEditModalOpen(false);
              }}
              size="xs"
              className="text-gray-500 border-gray-400 rounded text-xs lg:text-sm"
            >
              Cancel
            </Button>
            <Button
              onClick={onFullJSONSave}
              size="xs"
              className="text-white rounded text-xs lg:text-sm"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* Nested JSON Edit Modal */}
      <Modal open={isNestedEditModalOpen} setOpen={setNestedEditModalOpen}>
        <div className="flex flex-col gap-4 p-6 bg-background text-contentColor">
          <h1 className="text-lg font-bold">Edit Device Metadata</h1>
          <Callout title="Note">
            Editing field: <b>{currentNestedKey}</b>
          </Callout>
          <Editor
            height="400px"
            width="500px"
            theme="vs-dark"
            language="json"
            value={currentNestedValue}
            onChange={setCurrentNestedValue}
            options={{
              readOnly: false,
              lineNumbers: "off",
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() => {
                setNestedEditModalOpen(false);
              }}
              size="xs"
              className="text-gray-500 border-gray-400 rounded text-xs lg:text-sm"
            >
              Cancel
            </Button>
            <Button
              onClick={onNestedJSONSave}
              size="xs"
              className="text-white rounded text-xs lg:text-sm"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* Commit Changes Modal */}
      <Modal open={isCommitModalOpen} setOpen={setIsCommitModalOpen}>
        <div className="flex flex-col gap-4 p-6 bg-background text-contentColor">
          <h1 className="text-lg font-bold">Save Device Metadata Changes</h1>
          <DiffEditor
            height="400px"
            width="500px"
            theme="vs-dark"
            language="json"
            original={JSON.stringify(originalMeta ?? {}, undefined, 4)}
            modified={metadata}
            options={{
              readOnly: true,
              lineNumbers: "off",
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() => {
                setIsCommitModalOpen(false);
              }}
              size="xs"
              className="text-gray-500 border-gray-400 rounded text-xs lg:text-sm"
            >
              Cancel
            </Button>
            <Button
              loading={updateDeviceMetaMutation.isLoading}
              onClick={onCommit}
              size="xs"
              className="text-white rounded text-xs lg:text-sm"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeviceMetadata;
