export const GRID_COLS = {
  xxl: 5,
  xl: 4,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 1,
  xxs: 1
};

export const PANEL_TIME_FILTER_TOOLTIP_ID = "panelTimeFilterSelector";

export const TIMESTAMP_FORMAT = "DD/MM hh:mm:ss A";

// Pune Bounds
export const GEO_MAP_INITIAL_BOUNDING_BOX = [
  73.7115888365174, 18.68774689473389, 73.97315825630326, 18.44895304892448
];
