import React from "react";
import { CreateProject, CreateFleet } from ".";
import { useDashboardStore, useOverlayLoaderStore } from "../../store";
import useFleetAndDevicesStore from "../../store/fleet-and-devices/fleet-and-devices.store";
import RenderDevices from "./device/fad-render-devices.component";
import AllDashboards from "../dashboard/dashboard-all-dashbaords.component";
import { useGetFleetBlueprints } from "../shared/hooks/get/fleet-blueprints";
import FleetTabs, { TFleetTabs } from "./fleet/fad-fleet-tabs.component";
import Details from "../dashboard/dash-panel-details.component";
import FleetSettings from "./fleet/fad-fleet-settings.component";
import { useSearchParams } from "react-router-dom";
import FleetPolicies from "./fleet/fad-fleet-policies.component";

const Projects: React.FC = () => {
  const [loader] = useOverlayLoaderStore((state) => [state.loader]);
  const [projects, fleets, selectedFleet] = useFleetAndDevicesStore(
    (state) => [state.projects, state.fleets, state.selectedFleet]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = (searchParams.get("tab") ?? "devices") as TFleetTabs;

  const { data: fleetDashboards } = useGetFleetBlueprints(selectedFleet?.id, {
    kind: "DASHBOARD"
  });

  const activeDashboard = useDashboardStore((state) => state.activeDashboard);
  if (loader) return null;

  return (
    <>
      {projects && projects.length <= 0 ? (
        <CreateProject />
      ) : fleets && fleets.length <= 0 ? (
        <CreateFleet />
      ) : (
        <main className="flex-1 mx-4 my-8 mt-5 border-2 border-background bg-background rounded-md lg:mx-8 sm:mx-6">
          <FleetTabs
            activeTab={tab}
            setActiveTab={(tab) => setSearchParams({ tab })}
          />

          {tab === "devices" ? (
            <RenderDevices />
          ) : tab === "dashboards" ? (
            <>
              <AllDashboards
                renderAs="FLEET_DASHBOARD"
                dashboardBlueprints={fleetDashboards}
                customDashboardHeader={"  "}
                configs={{
                  hideCreateBlueprints: true,
                  hideCreateDashboard: true
                }}
              />
              <Details
                fleetId={selectedFleet?.id}
                dashBlueprint={activeDashboard?.dashBlueprint}
                dashboardConfigs={{
                  hideAddPanel: true,
                  hideDelete: true,
                  hideEditLayout: true
                }}
              />
            </>
          ) : tab === "details" ? (
            <FleetSettings />
          ) : tab === "policies" ? (
            <FleetPolicies />
          ) : null}
        </main>
      )}
    </>
  );
};

export default Projects;
