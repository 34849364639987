import { createContext } from "react";
import { TQueryType } from "./query-builder.helper";

interface IContextValue {
  queryType: TQueryType;
}

export const QueryContext = createContext<IContextValue>({
  queryType: "device-data"
});
