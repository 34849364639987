import { FunctionComponent } from "react";
import ApplyBlueprint from "../components/apply-blueprint.component";
import { useLocation } from "react-router-dom";
import NoData from "@/app/shared/components/no-data.component";
import { IBlueprint } from "@/interfaces/blueprint.interface";

interface ApplyMultipleBlueprintsProps {}

const ApplyMultipleBlueprints: FunctionComponent<
  ApplyMultipleBlueprintsProps
> = () => {
  const { state } = useLocation();
  const blueprints: IBlueprint[] = state?.blueprints ?? [];

  return (
    <div className="flex gap-8 mt-4 h-full flex-grow overflow-y-auto">
      {blueprints.length ? (
        <>
          <div className="w-[60%] px-4">
            <h2 className="border-b border-background-layer3 pb-1">
              Applying Blueprints:{" "}
            </h2>
            <div className="flex flex-col gap-2 mt-4 max-w-lg">
              {blueprints.map((bp) => (
                <div
                  key={bp.id}
                  className="p-2 rounded-md border-background-layer3 border bg-background-layer1"
                >
                  {bp.name}
                </div>
              ))}
            </div>
          </div>
          <div className="border-4 border-background-layer3 rounded mx-3"></div>
          <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
            <ApplyBlueprint blueprintIds={blueprints.map((bp) => bp.id)} />
          </div>
        </>
      ) : (
        <NoData msg="No Blueprints Selected!" />
      )}
    </div>
  );
};

export default ApplyMultipleBlueprints;
