import { ITrackConnectionEventsBody } from "@/interfaces/props.interface";
import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { UseMutationResult } from "react-query";
import { toast } from "react-toastify";
import ShowLoading from "./loading.component";
import { NumberInput, Switch } from "@tremor/react";

interface IEnableDeviceConnectionEventsProps {
  connectionEventMutation: UseMutationResult<number, unknown, any, unknown>;
  propsLoading: boolean;
  properties: any;
}

const MIN_TIMEOUT = 30;

const EnableDeviceConnectionEvents: React.FC<
  IEnableDeviceConnectionEventsProps & HTMLAttributes<HTMLDivElement>
> = ({ connectionEventMutation, propsLoading, properties, ...props }) => {
  const timerRef = useRef<any>();

  const [enableDisconnectionEvents, setEnableDisconnectionEvents] =
    useState(false);
  const [eventsTimeoutValue, setEventsTimeoutValue] = useState(1);

  useEffect(() => {
    if (properties) {
      if (properties.track_connection_events) {
        if (properties.track_connection_events.timeout_seconds === 0) {
          setEnableDisconnectionEvents(false);
          setEventsTimeoutValue(0);
        } else {
          setEnableDisconnectionEvents(true);
          setEventsTimeoutValue(
            properties.track_connection_events.timeout_seconds
          );
        }
      }
    }
  }, [properties]);

  const onEnableEventsChange = useCallback(
    (val: number) => {
      if (val < 0) {
        return;
      }

      setEventsTimeoutValue(val);

      const connEventBody: ITrackConnectionEventsBody = {
        timeout_seconds: val < MIN_TIMEOUT ? MIN_TIMEOUT : Math.round(val)
      };

      clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        if (val < MIN_TIMEOUT) {
          setEventsTimeoutValue(MIN_TIMEOUT);
        } else {
          setEventsTimeoutValue(Math.round(val));
        }

        connectionEventMutation.mutate(connEventBody, {
          onSuccess: (ok) => {
            if (ok) {
              toast.success(
                `${val ? "Enabled" : "Disabled"} device connection events.`
              );
            }
          }
        });
        timerRef.current = null;
      }, 1500);
    },
    [connectionEventMutation]
  );

  return (
    <div {...props}>
      <label className="text-sm flex gap-2 font-medium text-contentColor">
        Enable Device Connection Events
        <Switch
          checked={enableDisconnectionEvents}
          onChange={(val) => {
            setEnableDisconnectionEvents(val);
            val ? onEnableEventsChange(MIN_TIMEOUT) : onEnableEventsChange(0);
          }}
        />
      </label>

      <div className="mt-2">
        {propsLoading ? (
          <ShowLoading />
        ) : (
          <div className="flex flex-col gap-2">
            <span className="text-xs whitespace-nowrap">
              Trigger events after (seconds):
            </span>
            <NumberInput
              disabled={!enableDisconnectionEvents}
              min={MIN_TIMEOUT}
              step={1}
              value={eventsTimeoutValue}
              onValueChange={(val) => onEnableEventsChange(val)}
              className="max-w-[20px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EnableDeviceConnectionEvents;
