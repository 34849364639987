import React, { useMemo, useState } from "react";
import { IJoin, TWhere, Operator } from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import {
  ArrowsRightLeftIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import ReactSelect from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import WhereClause from "./where.component";

interface OrderByClauseProps {
  joins: IJoin[];
  selectedDPDStructure: any;
  userContextProps: string[];
  onChangeHandler: (joins: IJoin[]) => void;
}

const joinOptions = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
  { label: "Inner", value: "inner" },
  { label: "Outer", value: "outer" }
];

const JoinClause: React.FC<OrderByClauseProps> = ({
  joins,
  userContextProps,
  selectedDPDStructure,
  onChangeHandler
}) => {
  const [hoveredOrder, setHoveredOrder] = useState(null);

  const { data: dataPointDefinitions } = useGetDataPointDefinitions();

  const onHover = (ind) => {
    setHoveredOrder(ind);
  };

  const onMouseLeave = () => {
    setHoveredOrder(null);
  };

  const dpdOptions = useMemo(
    () =>
      dataPointDefinitions?.map((ddp) => ({
        label: ddp.name,
        value: ddp.id
      })),
    [dataPointDefinitions]
  );

  return (
    <div className="flex flex-col gap-4 border bg-background-layer1 border-background-layer3 p-4 mb-4 ml-12">
      {joins.map((join, ind) => (
        <div
          key={"join-" + ind}
          onMouseEnter={() => onHover(ind)}
          onMouseLeave={() => onMouseLeave()}
          className="flex gap-1 flex-col relative pr-8"
        >
          {hoveredOrder === ind ? (
            <Button
              type="button"
              icon={TrashIcon}
              color="red"
              variant="light"
              className="absolute right-1 top-3"
              onClick={() => {
                const newJoins = [...joins];
                newJoins.splice(ind, 1);

                onChangeHandler(newJoins);
              }}
            />
          ) : null}
          <label className="m-0 flex gap-2 items-center ">
            Alias:
            <input
              type="text"
              placeholder="Alias"
              value={join.alias}
              onChange={(e) => {
                const newJoins = [...joins];
                newJoins[ind] = {
                  ...join,
                  alias: e.target.value
                };

                onChangeHandler(newJoins);
              }}
              className="ml-2 text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
            />
          </label>
          <label className="m-0 flex gap-2 items-center ">
            Data Point Definition ID:
            <ReactSelect
              value={dpdOptions?.find((op) => op.value === join.dataPointId)}
              onChange={(val: IOption) => {
                const newJoins = [...joins];
                newJoins[ind] = {
                  ...join,
                  dataPointId: val.value
                };

                onChangeHandler(newJoins);
              }}
              options={dpdOptions}
              classNames={reactSelectClassNames}
              className="min-w-[150px]"
            />
          </label>
          <label className="m-0 flex gap-2 items-center ">
            Type:
            <ReactSelect
              value={joinOptions?.find((op) => op.value === join.type)}
              onChange={(val: IOption) => {
                const newJoins = [...joins];
                newJoins[ind] = {
                  ...join,
                  type: val.value
                };

                onChangeHandler(newJoins);
              }}
              options={joinOptions}
              classNames={reactSelectClassNames}
            />
          </label>
          <h4>On:</h4>
          {join.on.map((on, onInd) => (
            <div className="flex items-center gap-1">
              <input
                type="text"
                placeholder="abc.xyz"
                value={on.a}
                onChange={(e) => {
                  const newJoins = [...joins];
                  const newOns = [...join.on];
                  newOns[onInd] = {
                    ...on,
                    a: e.target.value.trim()
                  };

                  newJoins[ind] = {
                    ...join,
                    on: newOns
                  };

                  onChangeHandler(newJoins);
                }}
                className="text-xs bg-background border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
              />
              <ArrowsRightLeftIcon width={20} />
              <input
                type="text"
                placeholder="abc.xyz"
                value={on.b}
                onChange={(e) => {
                  const newJoins = [...joins];
                  const newOns = [...join.on];
                  newOns[onInd] = {
                    ...on,
                    b: e.target.value.trim()
                  };

                  newJoins[ind] = {
                    ...join,
                    on: newOns
                  };

                  onChangeHandler(newJoins);
                }}
                className="text-xs bg-background border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
              />
              <Button
                type="button"
                icon={TrashIcon}
                disabled={join.on.length === 1}
                color="red"
                variant="light"
                onClick={() => {
                  const newJoins = [...joins];
                  const newOns = [...join.on];
                  newOns.splice(onInd, 1);

                  newJoins[ind] = {
                    ...join,
                    on: newOns
                  };

                  onChangeHandler(newJoins);
                }}
              />
            </div>
          ))}

          <Button
            icon={PlusIcon}
            size="xs"
            variant="secondary"
            className="h-min self-end my-1"
            onClick={() => {
              const newJoins = [...joins];
              const newOns = [
                ...join.on,
                {
                  a: "",
                  b: ""
                }
              ];

              newJoins[ind] = {
                ...join,
                on: newOns
              };

              onChangeHandler(newJoins);
            }}
          >
            On
          </Button>

          {join.where ? (
            <div className="w-full">
              <h4>Where:</h4>

              <WhereClause
                selectedDPDStructure={selectedDPDStructure}
                userContextProps={userContextProps}
                where={join.where}
                onChangeHandler={(where) => {
                  const newJoins = [...joins];

                  newJoins[ind] = {
                    ...join,
                    where
                  };

                  onChangeHandler(newJoins);
                }}
              />
            </div>
          ) : null}

          {!join.where ? (
            <Button
              icon={PlusIcon}
              size="xs"
              variant="secondary"
              className="h-min self-start my-1"
              onClick={() => {
                const newJoins = [...joins];
                const newWhere: TWhere = [
                  [
                    {
                      param: "",
                      condition: [
                        {
                          operator: Operator.eq,
                          value: null
                        }
                      ]
                    }
                  ]
                ];

                newJoins[ind] = {
                  ...join,
                  where: newWhere
                };

                onChangeHandler(newJoins);
              }}
            >
              Where
            </Button>
          ) : null}

          <hr className="border-background-layer3 mt-2" />
        </div>
      ))}

      <Button
        icon={PlusIcon}
        size="xs"
        className="h-min self-center mb-1"
        onClick={() => {
          onChangeHandler([
            ...joins,
            {
              type: "right",
              dataPointId: "",
              on: [
                {
                  a: "",
                  b: ""
                }
              ]
            }
          ]);
        }}
      >
        Join
      </Button>
    </div>
  );
};

export default JoinClause;
