import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDeviceDataPolicy } from "@/interfaces/data-policies.interface";

// Define the payload type for creating a device data policy
export interface ICreateDeviceDataPolicyPayload {
  policy_name: string;
  policy: IDeviceDataPolicy;
}

interface IUpdateDataPolicyResponse {
  ok: number;
}

export const updateDeviceDataPolicy = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  policyId: string,
  payload: ICreateDeviceDataPolicyPayload
) => {
  const { ok } = await networkService.post<IUpdateDataPolicyResponse>(
    `projects/${projectId}/fleets/${fleetId}/device_data_policies/${policyId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateDeviceDataPolicy = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      policyId,
      data,
      fleetId
    }: {
      policyId: string;
      fleetId: string;
      data: ICreateDeviceDataPolicyPayload;
    }) =>
      await updateDeviceDataPolicy(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        policyId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeviceDataPolicies");
      }
    }
  );

  return res;
};
