import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useOverlayLoaderStore } from "../store";
import axios from "./axios.service";
import * as ZitadelSetting from "../app/auth/zitadel-setting.helper";
import { loggerService } from "./logger";

interface IConfigOptions {
  noLoader?: boolean;
  blockErrorToast?: boolean;
  isErrorLog?: boolean;
}

class NetworkService {
  private static _instance: NetworkService;

  public static getInstance(): NetworkService {
    if (!NetworkService._instance) {
      NetworkService._instance = new NetworkService();
    }

    return NetworkService._instance;
  }

  get<T>(
    url: string,
    params: any = null,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "GET";
    if (params !== null) {
      axiosConfig.params = params;
    }
    return this._getResponse<T>(axiosConfig);
  }

  post<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "POST";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  put<T>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "PUT";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  delete<T>(
    url: string,
    params: any = null,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "DELETE";
    if (params !== null) {
      axiosConfig.params = params;
    }
    return this._getResponse<T>(axiosConfig);
  }

  patch<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "PATCH";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  private _prepareRequest(
    url: string,
    config: AxiosRequestConfig & IConfigOptions
  ): AxiosRequestConfig {
    if ("noLoader" in config && config.noLoader) {
      useOverlayLoaderStore.getState().setLoader(false);
    } else {
      // useOverlayLoaderStore.getState().setLoader(true);
    }
    const axiosConfig: AxiosRequestConfig = Object.assign(
      {
        url: url
      } as Partial<AxiosRequestConfig>,
      config
    );

    return axiosConfig;
  }

  private async _getResponse<T>(
    config: AxiosRequestConfig & IConfigOptions
  ): Promise<T> {
    let response;
    try {
      response = await axios(config);
      useOverlayLoaderStore.getState().setLoader(false);
      return response.data;
    } catch (error) {
      if ("blockErrorToast" in config && config.blockErrorToast) {
        // do nothing
      } else {
        if (
          error.message?.toLowerCase().includes("invalid or expired token") ||
          error.message?.toLowerCase().includes("unauthorized") ||
          error.message
            ?.toLowerCase()
            .includes("authorization header is missing!")
        ) {
          toast.error(
            <div>
              <h3 className="text-base">
                {"Authorization header missing or invalid!"}
              </h3>
              <p className="text-sm">
                {"Redirecting to authentication page."}
              </p>
            </div>,
            {
              pauseOnHover: false,
              autoClose: 3000
            }
          );

          setTimeout(() => {
            ZitadelSetting.ZitadelSDK.authorize();
          }, 3000);
        } else {
          toast.error(
            <div>
              <h3 className="text-base">{error.message}</h3>
              {error.detail ? <p className="text-sm">{error.detail}</p> : null}
            </div>,
            { pauseOnFocusLoss: false }
          );
        }
      }
      useOverlayLoaderStore.getState().setLoader(false);
      if (!config.isErrorLog) {
        loggerService.error(error);
      }
      throw error;
    }
  }
}

const networkService = NetworkService.getInstance();
export default networkService;
