import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { EFleetProp } from "@/interfaces";
import {
  IDataPolicyBody,
  ITrackConnectionEventsBody
} from "@/interfaces/props.interface";

interface IFleetProps {
  [EFleetProp.TRACK_CONNECTION_EVENTS]?: ITrackConnectionEventsBody;
  [EFleetProp.DATA_POLICY]?: IDataPolicyBody;
}

interface IFleetPropsResponse {
  ok: number;
  data: IFleetProps;
}

export const getFleetProps = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  params
) => {
  const { data, ok } = await networkService.get<IFleetPropsResponse>(
    `projects/${projectId}/fleets/${fleetId}/properties`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetFleetProps = (
  fleetId: string,
  params: any = {},
  successCb: ((data: IFleetProps) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getFleetProps",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      params
    ],
    async () => {
      const fleetProps = await getFleetProps(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        params
      );

      successCb && successCb(fleetProps);

      return fleetProps;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!fleetId
    }
  );

  return res;
};
