import {
  Cog8ToothIcon,
  KeyIcon,
  Squares2X2Icon
} from "@heroicons/react/24/outline";

export type TFleetTabs = "devices" | "dashboards" | "details" | "policies";

interface IDeviceDetailsTabsProps {
  activeTab: TFleetTabs;
  setActiveTab: (s: TFleetTabs) => void;
}

function FleetTabs(props: IDeviceDetailsTabsProps) {
  const { activeTab, setActiveTab } = props;

  return (
    <div className="bg-background border-background-layer3 rounded-t-md border-b">
      <div className="ml-20 flex gap-8 flex-wrap">
        <button
          onClick={() => setActiveTab("devices")}
          className={`${
            activeTab === "devices"
              ? "text-primaryLight border-b-2 border-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div
              className={`flex flex-row justify-between items-center ${
                activeTab === "devices"
                  ? "stroke-primaryLight"
                  : "stroke-contentColor"
              }`}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`${
                  activeTab === "devices" && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Devices
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab("dashboards")}
          className={`${
            activeTab === "dashboards"
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Squares2X2Icon width={18} />

              <span
                className={`${
                  activeTab === "dashboards" && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Fleet Dashboard
              </span>
            </div>
          }
        </button>
        <button
          onClick={() => setActiveTab("details")}
          className={`${
            activeTab === "details"
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Cog8ToothIcon width={18} />

              <span
                className={`${
                  activeTab === "details" && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Details
              </span>
            </div>
          }
        </button>
        <button
          onClick={() => setActiveTab("policies")}
          className={`${
            activeTab === "policies"
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <KeyIcon width={18} />

              <span
                className={`${
                  activeTab === "policies" && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Policies
              </span>
            </div>
          }
        </button>
      </div>
    </div>
  );
}

export default FleetTabs;
