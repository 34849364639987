import React, { useCallback, useEffect, useRef } from "react";
import useRuleEngineStore, {
  IRule
} from "@/store/rule-engine/rule-engine.store";
import { EditControl } from "react-leaflet-draw";
import * as Yup from "yup";

import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { useGetTriggers } from "@/app/shared/hooks/get/triggers";
import LoadingSpin from "@/app/shared/components/loading-spin.component";
import {
  ILocationTriggerDefinition,
  ILocationTriggerFormState,
  TTriggerType
} from "@/interfaces/triggers.interface";
import { Button } from "@tremor/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  ICreateTriggerPayload,
  useCreateTrigger
} from "@/app/shared/hooks/post/create-trigger";
import { useUpdateTrigger } from "@/app/shared/hooks/patch/update-triggers";
import {
  deserializeLocationTrigger,
  fenceEventOptions,
  getMapCenter,
  serializeLocationTrigger
} from "./geofence-trigger.helper";
import { toast } from "react-toastify";
import { FieldError } from "@/app/shared/components";
import { GeoJSON } from "leaflet";
import ReactSelect from "react-select";
import { IOption } from "@/interfaces";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";

interface ILocationTriggerProps {
  rule: IRule;
}

const initialFormValues: ILocationTriggerFormState = {
  name: "",
  fence: [],
  fenceEvent: fenceEventOptions[0]
};

const LocationTriggerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  fence: Yup.array().min(1, "Create a polygon for the GeoFence"),
  fenceEvent: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .nullable()
    .required("Select a fence event")
});

const GeoFenceTrigger: React.FC<ILocationTriggerProps> = ({ rule }) => {
  const [drafts, setDrafts] = React.useState([]);
  const fgRef = useRef<any>(null);

  const [triggerFormState, setTriggerData, setRules] = useRuleEngineStore(
    (state) => [
      state.triggerData?.[rule.name],
      state.setTriggerData,
      state.setRules
    ]
  );

  const triggersQuery = useGetTriggers({
    trigger_type: "LOCATION" as TTriggerType
  });

  const createTriggerMutation = useCreateTrigger();
  const updateTriggerMutation = useUpdateTrigger();

  useEffect(() => {
    if (triggersQuery.isLoading) {
      return;
    }

    const selectedTrigger = triggersQuery.data?.find(
      (t) => t.rule_id === rule.id
    );

    if (!selectedTrigger) {
      return;
    }

    const formState = deserializeLocationTrigger(
      selectedTrigger.name,
      selectedTrigger.definition as ILocationTriggerDefinition
    );

    // add fence layers
    let leafletGeoJSON = new GeoJSON(formState.fence);

    const newDrafts = [];

    if (fgRef.current) {
      fgRef.current.clearLayers();

      leafletGeoJSON.eachLayer((layer) => {
        newDrafts.push({
          id: layer["_leaflet_id"],
          layer: layer["feature"]
        });
        fgRef.current.addLayer(layer);
      });
    }

    setDrafts(newDrafts);

    setRules((rules) => {
      return {
        ...rules,
        [rule.name]: {
          ...rules[rule.name],
          triggerId: selectedTrigger.id
        }
      };
    });

    setTriggerData(rule.name, formState);
  }, [
    fgRef.current,
    rule.id,
    rule.name,
    setRules,
    setTriggerData,
    triggersQuery.data,
    triggersQuery.isLoading
  ]);

  const onEdited = useCallback((e, setFieldValue) => {
    const ids = Object.keys(e.layers._layers).map((id) => Number(id));
    const newDrafts = [];
    ids.forEach((id) =>
      newDrafts.push({
        id: e.layers._layers[id]._leaflet_id,
        layer: e.layers._layers[id].toGeoJSON()
      })
    );
    setFieldValue("fence", newDrafts);
    setDrafts(newDrafts);
  }, []);

  const onCreated = useCallback((e, setFieldValue) => {
    if (e.layerType === "circle") {
      // TODO: use circle-to-polygon
      // console.log(
      //   "polygon",
      //   e.layer.getRadius(),
      //   circleToPolygon(
      //     [e.layer._latlng.lng, e.layer._latlng.lat],
      //     e.layer.getRadius()
      //   )
      // );
    }

    setDrafts((drafts) => {
      const newDrafts = [
        ...drafts,
        { id: e.layer._leaflet_id, layer: e.layer.toGeoJSON() }
      ];
      setFieldValue("fence", newDrafts);
      return newDrafts;
    });
  }, []);

  const onDeleted = useCallback((e, setFieldValue) => {
    const ids = Object.keys(e.layers._layers).map((id) => Number(id));
    setDrafts((drafts) => {
      drafts = drafts.filter((draft) => {
        return !ids.includes(draft.id);
      });

      setFieldValue("fence", drafts);

      return drafts;
    });
  }, []);

  const onSubmit = (values: ILocationTriggerFormState) => {
    const triggerDefinition = serializeLocationTrigger(values, drafts);
    const payload: ICreateTriggerPayload = {
      trigger_name: values.name,
      trigger_description: "",
      trigger_type: "LOCATION",
      definition: triggerDefinition,
      active: true,
      rule_id: rule.id
    };

    if (rule.triggerId) {
      updateTriggerMutation.mutate(
        { data: payload, id: rule.triggerId },
        {
          onSuccess: (trigger) => {
            setRules((rules) => ({
              ...rules,
              [rule.name]: {
                ...rules[rule.name],
                triggerId: trigger.id
              }
            }));
            toast.success("Updated trigger successfully!");
          },
          onError: console.error
        }
      );
    } else {
      createTriggerMutation.mutate(payload, {
        onSuccess: ({ trigger }) => {
          setRules((rules) => ({
            ...rules,
            [rule.name]: {
              ...rules[rule.name],
              triggerId: trigger.id
            }
          }));

          toast.success("Created trigger successfully!");
        },
        onError: console.error
      });
    }
  };

  if (triggersQuery.isLoading) {
    return (
      <div className="flex items-center justify-center my-10 ">
        <LoadingSpin />
      </div>
    );
  }

  return (
    <div className="max-w-2xl nodrag mx-auto p-2 rounded-lg">
      <Formik
        validationSchema={LocationTriggerSchema}
        initialValues={
          (triggerFormState as ILocationTriggerFormState) ?? initialFormValues
        }
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="mb-4">
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-contentColor"
              >
                Name*
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                className="mt-1 block w-full bg-background border-background-layer3 text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                placeholder="Provide a name that uniquely identifies this trigger."
              />
              <ErrorMessage name="name">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mb-4">
              <label
                htmlFor="authMode"
                className="block text-base font-medium text-contentColor"
              >
                GeoFence Event*
                <Field
                  id="fenceEvent"
                  name="fenceEvent"
                  component={({ field, form, ...props }) => (
                    <ReactSelect
                      {...field}
                      {...form}
                      id={"fenceEvent"}
                      name={"fenceEvent"}
                      value={values.fenceEvent}
                      onChange={(val: IOption) => {
                        form.setFieldValue(`fenceEvent`, val);
                      }}
                      placeholder="Pick a mode to authenticate users."
                      options={fenceEventOptions}
                      className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      classNames={reactSelectClassNames}
                    />
                  )}
                />
                <ErrorMessage name="fenceEvent">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </label>
            </div>

            <MapContainer
              id="adw"
              center={getMapCenter(
                triggerFormState as ILocationTriggerFormState
              )}
              zoom={13}
              zoomControl={false}
              style={{
                width: "500px",
                height: "500px",
                marginTop: "6px",
                borderRadius: "6px",
                zIndex: "0"
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <FeatureGroup ref={fgRef}>
                <EditControl
                  key={drafts.length}
                  position="topright"
                  onCreated={(e) => onCreated(e, setFieldValue)}
                  onDeleted={(e) => onDeleted(e, setFieldValue)}
                  onEdited={(e) => onEdited(e, setFieldValue)}
                  draw={{
                    rectangle: false,
                    circlemarker: false,
                    marker: false,
                    polygon:
                      drafts.length === 0
                        ? {
                            allowIntersection: false
                          }
                        : false,
                    polyline: false,
                    // TODO: Use circle-to-polygon to add circular GeoFence
                    circle: false
                  }}
                />
              </FeatureGroup>
            </MapContainer>
            <Field
              type="text"
              id="fence"
              name="fence"
              autoComplete="off"
              className="hidden"
              value={drafts}
            />
            <ErrorMessage name="fence">
              {(msg) => <FieldError message={msg} />}
            </ErrorMessage>
            <div className="flex w-full mt-4 justify-end">
              <Button
                loading={triggersQuery.isLoading}
                type="submit"
                className="self-end !text-white"
              >
                {rule.triggerId ? "Update" : "Create"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeoFenceTrigger;
