import {
  ChartBarIcon,
  Square2StackIcon,
  Squares2X2Icon
} from "@heroicons/react/24/outline";

interface IDeviceDetailsTabsProps {
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

function DeviceDetailsTabs(props: IDeviceDetailsTabsProps) {
  const { activeTab, setActiveTab } = props;

  return (
    <div className="bg-background border-background-layer3 mb-4 border-b">
      <div className=" md:grid md:gap-4 md:grid-cols-5 md:place-items-start">
        <button
          onClick={() => setActiveTab(0)}
          className={`${
            activeTab === 0
              ? "text-primaryLight border-b-2 border-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div
              className={`flex flex-row justify-between items-center ${
                activeTab === 0 ? "stroke-primaryLight" : "stroke-contentColor"
              }`}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`${
                  activeTab === 0 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Device Details
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(1)}
          className={`${
            activeTab === 1
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Square2StackIcon width={18} />

              <span
                className={`${
                  activeTab === 1 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Device Shadow
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(2)}
          className={`${
            activeTab === 2
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <ChartBarIcon width={18} />

              <span
                className={`${
                  activeTab === 2 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Data-points
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(3)}
          className={`${
            activeTab === 3
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Squares2X2Icon width={18} />

              <span
                className={`${
                  activeTab === 3 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Dashboard
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(4)}
          className={`${
            activeTab === 4
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <span className="font-mono font-extralight">{"{}"}</span>

              <span
                className={`${
                  activeTab === 4 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Metadata
              </span>
            </div>
          }
        </button>
      </div>
    </div>
  );
}

export default DeviceDetailsTabs;
