import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDashboardStore } from "../../store";
import DashboardUtilsHeader from "./components/dash-filter-header.component";
import PanelWrapper from "./dash-wrapper-panel.component";
import CarouselWrapper from "./dash-carousel-warpper.component";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { CoreProps, Responsive, WidthProvider } from "react-grid-layout";
import { useGetDashboardPanels } from "@app/shared/hooks/get/dashboard-panels";
import ShowLoading from "@app/shared/components/loading.component";
import ShowError from "@app/shared/components/error.component";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { PlusIcon } from "@heroicons/react/24/outline";
import Modal from "../shared/components/modal.component";
import { useGetBlueprints } from "../shared/hooks/get/blueprints";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { GRID_COLS } from "./dash-constants";
import DashPanelErrorToast from "./components/dash-panel-error-toast.component";
import NoData from "../shared/components/no-data.component";
import { DashTableBadgeTooltip } from "./components/table/dash-table.component";
import DashPanelFilterTooltip from "./components/dash-panel-filter-tooltip.component";
import { IDashboardPanel } from "@/interfaces";
import {
  convertBlueprintPanelsToDashboardPanels,
  getInitialLayoutState,
  sortCarouselPanels
} from "./dash.helper";

import "react-grid-layout/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDashboardDetails {
  deviceId?: string;
  fleetId?: string;
  dashBlueprint?: IBlueprint;
  onAddPanel?: () => void;
  dashboardConfigs?: {
    hideAddPanel?: boolean;
    hideDelete?: boolean;
    hideEditLayout?: boolean;
  };
}

const Details: React.FC<IDashboardDetails> = ({
  deviceId,
  fleetId,
  dashBlueprint,
  onAddPanel,
  dashboardConfigs
}) => {
  const navigate = useNavigate();

  const wrapperRef = useRef(null);
  const panelErrors = useRef<
    Record<string, { error: string; panelTitle: string }>
  >({});
  const errorToastTimer = useRef(null);
  const timeFilterTooltipRef = useRef(null);
  const [layoutDone, setLayoutDone] = useState(false);

  const [
    panels,
    dashboards,
    carousels,
    activeDashboard,
    editDashboard,
    savedLayouts,
    expandedItemId,
    zoomLevel
  ] = useDashboardStore((state) => [
    state.panels,
    state.dashboards,
    state.carousels,
    state.activeDashboard,
    state.editingLayout,
    state.layouts,
    state.expandedItemId,
    state.zoomLevel
  ]);

  const [setPanels, setCarousels, clearCreatePanelState, setExpandedItemId] =
    useDashboardStore((state) => [
      state.setPanels,
      state.setCarousels,
      state.clearCreatePanelState,
      state.setExpandedItemId
    ]);

  const [layoutState, setLayoutState] = useState(
    getInitialLayoutState(panels, savedLayouts)
  );

  const { data: fleets } = useGetFleets();

  useEffect(() => {
    // reset the layout state when the active dashboard changes
    setLayoutDone(false);

    return () => {
      setLayoutDone(false);
    };
  }, [activeDashboard?.id]);

  // if rendered in project level dashboard, fetch panels from that dashboard
  const {
    data: projectDashPanels,
    isFetching: isDashPanelsLoading,
    error
  } = useGetDashboardPanels(
    activeDashboard.dashBlueprint || dashBlueprint || fleetId || deviceId
      ? ""
      : activeDashboard?.id,
    {}
  );

  useEffect(() => {
    if (deviceId || fleetId || dashBlueprint || isDashPanelsLoading) return;

    if (projectDashPanels?.length) {
      let carouselObj = {};
      let panelsArr = [];
      projectDashPanels.forEach((panel) => {
        if (panel.definition.arrangement) {
          if (panel.definition.arrangement.carousel in carouselObj) {
            carouselObj[panel.definition.arrangement.carousel].push(panel);
          } else {
            carouselObj[panel.definition.arrangement.carousel] = [panel];
          }
        } else {
          panelsArr.push(panel);
        }
      });
      if (!isEmpty(carouselObj)) {
        setCarousels(sortCarouselPanels(carouselObj));
      }
      if (panelsArr.length) {
        const newState = getInitialLayoutState(panelsArr, savedLayouts);
        setLayoutState(newState);
        setLayoutDone(true);
        setPanels(panelsArr);
      }
    } else {
      setPanels([]);
      setCarousels({});
    }
  }, [
    fleetId,
    deviceId,
    dashBlueprint,
    projectDashPanels,
    savedLayouts,
    isDashPanelsLoading,
    setPanels,
    setCarousels
  ]);

  // TODO: Render panels applied to devices separately
  // const { isLoading: isDevicePanelsLoading } = useGetDeviceBlueprints(
  //   selectedFleet.id,
  //   deviceId,
  //   {},
  //   (blueprintPanels) => {
  //     if (!blueprintPanels) setPanels([]);
  //     const panelsArr: IDashboardPanel[] =
  //       convertBlueprintPanelsToDashboardPanels(blueprintPanels);

  //     setPanels(panelsArr);
  //   }
  // );

  const blueprintIds = useMemo(() => {
    if (!dashBlueprint?.definition) return "";

    if ("blueprint_ids" in dashBlueprint.definition) {
      return dashBlueprint.definition.blueprint_ids.join(",");
    }

    return "";
  }, [dashBlueprint]);

  const { data: dashBlueprintPanels, isFetching: isBlueprintPanelsLoading } =
    useGetBlueprints({
      blueprint_id: blueprintIds
    });

  useEffect(() => {
    if (!dashBlueprintPanels?.length) {
      setPanels([]);
      return;
    }

    const panelsArr: IDashboardPanel[] =
      convertBlueprintPanelsToDashboardPanels(dashBlueprintPanels ?? []);

    const newState = getInitialLayoutState(panelsArr, savedLayouts);

    setLayoutDone(true);
    setLayoutState(newState);
    setPanels(panelsArr);
  }, [dashBlueprintPanels, setPanels, savedLayouts]);

  useEffect(() => {
    // clear the create panel state if the user navigates to a different dashboard
    if (activeDashboard.id) {
      clearCreatePanelState();
    }
  }, [activeDashboard.id, clearCreatePanelState]);

  useEffect(() => {
    // zoom in/out the dashboard
    if (!wrapperRef.current) return;
    wrapperRef.current.style.zoom = `${zoomLevel}%`;
  }, [zoomLevel]);

  useEffect(() => {
    // clear the error toast timer on unmount
    let timer = errorToastTimer.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderCarouselPanels = useCallback(() => {
    const carouselKeys = Object.keys(carousels);
    return carouselKeys.map((carousel) => (
      <CarouselWrapper title={carousel} panels={carousels[carousel]} />
    ));
  }, [carousels]);

  const handleAddPanel = () => {
    if (deviceId) {
      onAddPanel && onAddPanel();
      return;
    }

    if (fleets.length > 0) {
      navigate("/dashboard/new-panel-type");
    } else {
      toast.error(
        "Please add at least one Fleet before creating a new panel!"
      );
    }
  };

  const handlePanelError = (
    panelId: string,
    panelTitle: string,
    error: string
  ) => {
    panelErrors.current[panelId] = { error, panelTitle };

    // batch the errors into one toast to avoid spamming the user with toasts
    if (Object.keys(panelErrors.current).length === 1) {
      errorToastTimer.current = setTimeout(() => {
        if (Object.keys(panelErrors.current).length)
          toast.error(<DashPanelErrorToast panelErrors={panelErrors} />);
        panelErrors.current = {};
        clearTimeout(errorToastTimer.current);
      }, 500);
    }
  };

  const onBreakpointChange: (Breakpoint) => void = (breakpoint) => {
    setLayoutState((curState) => ({
      ...curState,
      currentBreakpoint: breakpoint
    }));
  };

  const memoizedPanels = useMemo(() => {
    if (!layoutDone) return;
    if (!layoutState.layouts[layoutState.currentBreakpoint]?.length) return;
    return panels.map((panel: IDashboardPanel) => {
      return (
        <div
          key={panel.isBlueprint ? panel.blueprint_id : panel.id}
          data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
            (el) =>
              el.i === (panel.isBlueprint ? panel.blueprint_id : panel.id)
          )}
          className={`${editDashboard ? "select-none" : ""} ${
            !editDashboard && "hide-resize"
          }`}
        >
          <PanelWrapper
            panel={panel}
            deviceId={deviceId}
            fleetId={fleetId}
            key={panel.isBlueprint ? panel.blueprint_id : panel.id}
            handlePanelError={handlePanelError}
          />
        </div>
      );
    });
  }, [
    layoutDone,
    layoutState.layouts,
    layoutState.currentBreakpoint,
    panels,
    editDashboard,
    deviceId,
    fleetId
  ]);

  const expandedPanel = useMemo(
    () =>
      expandedItemId
        ? panels.find((p) =>
            p.isBlueprint
              ? p.blueprint_id === expandedItemId
              : p.id === expandedItemId
          )
        : null,
    [panels, expandedItemId]
  );

  if (isDashPanelsLoading || isBlueprintPanelsLoading) {
    return <ShowLoading />;
  }

  if (error) {
    return <ShowError />;
  }

  if (!deviceId && !dashboards.length && !dashBlueprint) {
    return <NoData msg="No dashboards found" />;
  }

  return (
    <main className="w-full h-auto py-4 bg-background-layer1 overflow-y-auto lg:px-6 sm:px-4">
      <DashboardUtilsHeader
        hideAddPanel={dashboardConfigs?.hideAddPanel}
        hideDelete={dashboardConfigs?.hideDelete}
        hideEditLayout={dashboardConfigs?.hideEditLayout}
        dashBlueprint={dashBlueprint}
        addPanel={handleAddPanel}
        layouts={layoutState.layouts}
        setLayouts={setLayoutState}
        panels={panels}
      />
      <div ref={wrapperRef} className="w-full py-4">
        {!isEmpty(carousels) && renderCarouselPanels()}
        <div className="flex flex-wrap gap-4 w-full h-full">
          {memoizedPanels?.length ? (
            <ResponsiveGridLayout
              className="layout w-full"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={GRID_COLS}
              rowHeight={260}
              width={1200}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              layouts={layoutState.layouts}
              onBreakpointChange={onBreakpointChange}
              measureBeforeMount={false}
              useCSSTransforms={layoutState.mounted}
              compactType={layoutState.compactType as CoreProps["compactType"]}
              preventCollision={!layoutState.compactType}
              onLayoutChange={(layout, layouts) => {
                if (!layout.length) return;

                // save the current layout to all the breakpoints, let the library handle the arrangement
                const newLayouts = Object.keys(GRID_COLS).reduce(
                  (acc, cur) => {
                    if (!layout.length) return acc;
                    acc[cur] = layout;
                    return acc;
                  },
                  {}
                );
                if (Object.keys(newLayouts).length) {
                  setLayoutState((curState) => ({
                    ...curState,
                    layouts: newLayouts
                  }));
                }
              }}
            >
              {memoizedPanels}
            </ResponsiveGridLayout>
          ) : !dashboardConfigs?.hideAddPanel ? (
            <div className="w-full h-[60vh] flex items-center justify-center">
              <div
                onClick={handleAddPanel}
                className="h-[300px] gap-2 cursor-pointer w-[300px] text-primary flex items-center justify-center border-dashed border-2 border-primary rounded-lg"
              >
                <PlusIcon width={20} /> Create a new panel!
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <Tooltip
        id="panel-error-tooltip"
        className="border border-red-500"
        style={{
          zIndex: 100000,
          backgroundColor: "#F87171",
          color: "#fff"
        }}
      />

      <DashTableBadgeTooltip />

      <DashPanelFilterTooltip
        tooltipRef={timeFilterTooltipRef}
        panels={panels}
        setPanels={setPanels}
      />

      <Modal
        open={!!expandedPanel}
        setOpen={(x: boolean) => setExpandedItemId(x ? expandedItemId : null)}
      >
        <div className="flex flex-col gap-4 p-6 w-[95vw] h-[95vh] bg-background text-contentColor">
          <h1 className="text-lg font-bold">{expandedPanel?.title}</h1>

          {expandedPanel ? (
            <PanelWrapper
              panel={expandedPanel}
              deviceId={deviceId}
              fleetId={fleetId}
              handlePanelError={handlePanelError}
            />
          ) : null}
        </div>
      </Modal>
    </main>
  );
};

export default Details;
