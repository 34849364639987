import {
  CircleStackIcon,
  ClockIcon,
  EyeIcon
} from "@heroicons/react/24/outline";
import React from "react";

export enum ContextDefinitionTabs {
  DETAILS = "details",
  PREVIEW = "preview",
  DATA = "data",
  // USAGE = "usage",
  RETENTION = "retention"
}

const iconsMap = {
  [ContextDefinitionTabs.DETAILS]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
        stroke={
          activeTab === ContextDefinitionTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
        stroke={
          activeTab === ContextDefinitionTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [ContextDefinitionTabs.DATA]: (activeTab) => (
    <CircleStackIcon
      className={`${
        activeTab === ContextDefinitionTabs.DATA
          ? "text-[#546CCC]"
          : "text-[#565759]"
      }`}
      width={16}
    />
  ),
  // [ContextDefinitionTabs.USAGE]: (activeTab) => (
  //   <svg
  //     width="19"
  //     height="18"
  //     viewBox="0 0 19 18"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M7.25 13.5H3.5V7.5H7.25V13.5ZM5.75 12V9H5V12H5.75ZM10.25 12V6H9.5V12H10.25ZM11.75 13.5H8V4.5H11.75V13.5ZM14.75 12V3H14V12H14.75ZM16.25 13.5H12.5V1.5H16.25V13.5ZM17 16.5H2.75V15H17V16.5Z"
  //       fill={
  //         activeTab === ContextDefinitionTabs.USAGE ? "#546CCC" : "#565759"
  //       }
  //     />
  //   </svg>
  // ),
  [ContextDefinitionTabs.RETENTION]: (activeTab) => (
    <ClockIcon
      className={`${
        activeTab === ContextDefinitionTabs.RETENTION
          ? "text-[#546CCC]"
          : "text-[#565759]"
      }`}
      width={16}
    />
  ),
  [ContextDefinitionTabs.PREVIEW]: (activeTab) => (
    <EyeIcon
      className={`${
        activeTab === ContextDefinitionTabs.PREVIEW
          ? "text-[#546CCC]"
          : "text-[#565759]"
      }`}
      width={16}
    />
  )
};

interface IUserContextDefinitionDetailsTabs {
  activeTab: ContextDefinitionTabs;
  setActiveTab: (tab: ContextDefinitionTabs) => void;
}

const UserContextDefinitionDetailsTabs: React.FC<
  IUserContextDefinitionDetailsTabs
> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="bg-background border-b border-background-layer3">
      <div className=" md:grid md:gap-4 md:grid-cols-5 ">
        {(
          Object.keys(ContextDefinitionTabs) as Array<
            keyof typeof ContextDefinitionTabs
          >
        ).map((key) => (
          <button
            onClick={() => setActiveTab(ContextDefinitionTabs[key])}
            className={`${
              activeTab === ContextDefinitionTabs[key]
                ? "text-primary border-b-2 border-primary"
                : ""
            } flex items-center px-2 py-5 justify-center -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`}
          >
            {
              <div className="flex flex-row justify-between items-center">
                {iconsMap[ContextDefinitionTabs[key]](activeTab)}

                <span
                  className={`${
                    activeTab === ContextDefinitionTabs[key] &&
                    "text-primborder-primary font-medium"
                  } text-sm ml-2`}
                >
                  {ContextDefinitionTabs[key].toUpperCase()}
                </span>
              </div>
            }
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserContextDefinitionDetailsTabs;
