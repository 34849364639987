import Editor from "@monaco-editor/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../store";
import useFleetAndDevicesStore from "../../../store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "../../../store/shadow/shadow.store";
import { FieldError } from "../../shared/components";
import {
  copyToClipboard,
  pasteFromClipboard,
  reactSelectClassNames
} from "../../shared/utils/helper.util";
import DeviceCreationSteps from "../components/device-creation-steps.component";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import { string, object } from "yup";
import { IDataPointDefinition, IOption } from "@interfaces/shared.interface";
import { useGetDeviceDataPolicies } from "@app/shared/hooks/get/device-data-policies";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useCreateDataPointDefinition } from "@app/shared/hooks/post/create-data-point-definition";
import { useLinkDataPoint } from "@app/shared/hooks/post/link-data-point";
import useThemeStore from "@store/theme.store";
import {
  FieldInfo,
  getUserInputForField,
  parseProtoFile,
  updateProtoFile,
  verifySemicolonEndingsInProto
} from "@/app/shared/utils/proto-helper.util";
import Modal from "@/app/shared/components/modal.component";
import ProtoLimitsForm from "../components/proto-limits-form.component";
import { Button } from "@tremor/react";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import {
  EDeviceDataInputCompression,
  EDeviceDataInputFormat
} from "@/interfaces/data-policies.interface";
import {
  TDeviceDataPoliciesState,
  dataCompressionOptions,
  defaultDatapoint,
  policyInputFormatOptions
} from "./utils/fad-apply-data-points-helper";
import DataPointPoliciesInfoModal from "./components/data-point-policy-info-modal.component";
import ConfigureDeviceDataPolicy from "./components/configure-device-data-policy.component";

interface IApplyDataPointsProps {
  hideSteps?: boolean;
  nextButtonLoading?: boolean;
  fleetId?: string;
  deviceId?: string;
  enableDefaultNextOperation?: boolean;
  onBackClick?: () => void;
  onNextClick?: (
    dataPointPolicies: TDeviceDataPoliciesState,
    selectedDps?: IDataPointDefinition[]
  ) => void;
}

const newDatapointDefinitionSchema = object().shape({
  datapointName: string().required("Please enter data-point name."),
  rootProtoMsgName: string().required("Please enter message name.")
});

const ApplyDataPoints: React.FC<IApplyDataPointsProps> = ({
  hideSteps,
  nextButtonLoading,
  fleetId,
  deviceId,
  enableDefaultNextOperation,
  onBackClick,
  onNextClick
}) => {
  /* Hooks And Hooks Variables */
  const navigate = useNavigate();

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [datapoint, setMaximizeDatapoint, setDatapoint] = useShadowStore(
    (state) => [
      state.datapoint,
      state.setMaximizeDatapoint,
      state.setDatapoint
    ]
  );
  const [selectedFleet, deviceCreation] = useFleetAndDevicesStore((state) => [
    state.selectedFleet,
    state.deviceCreation
  ]);
  const [theme] = useThemeStore((state) => [state.theme]);

  /* State Variables */
  const [createDatapoint, setCreateDatapoint] = useState(false);
  const [selectedDatapoint, setSelectedDatapoint] = useState<
    { value: string; label: string }[]
  >([]);
  const [dataPointPolicies, setDataPointPolicies] =
    useState<TDeviceDataPoliciesState>({});
  const [customDatapoint, setCustomDatapoint] = useState(defaultDatapoint);
  const [errorMsgs, setErrorMsgs] = useState({
    predefinedDatapoint: "",
    customDatapoint: ""
  });
  const [formValues, setFormValues] = useState<any>();
  const [fieldInfos, setFieldInfos] = useState<FieldInfo[]>([]);
  const [dppInfoModalOpen, setDppInfoModalOpen] = useState(false);
  const [modalStates, setModalStates] = useState({
    openOptionsPrompt: false,
    optionsModal: false
  });

  const { data: predefinedDatapoint } = useGetDataPointDefinitions();
  const { data: existingPolicies, isLoading: isExistingPoliciesLoading } =
    useGetDeviceDataPolicies(selectedFleet.id);

  // Mutations
  const dataPointDefinitionMutation = useCreateDataPointDefinition();
  const linkDPMutation = useLinkDataPoint(
    fleetId ?? selectedFleet.id,
    deviceId ?? deviceCreation.newly_created_device_id
  );

  useEffect(() => {
    updateAuthUser({ deviceCreationStatus: "2" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (datapoint) {
      setCustomDatapoint(datapoint);
    }
  }, [datapoint]);

  const handleMaximizeEditor = () => {
    setDatapoint(customDatapoint);
    setMaximizeDatapoint({ state: true, readOnly: false });
  };

  const submitCustomDatapoint = async (
    values: {
      datapointName: string;
      rootProtoMsgName: string;
    },
    { resetForm }
  ) => {
    if (!customDatapoint) {
      setErrorMsgs({
        ...errorMsgs,
        customDatapoint: "Please define data-point"
      });
    } else {
      setErrorMsgs({ ...errorMsgs, customDatapoint: "" });
    }

    if (predefinedDatapoint?.some((dp) => dp.name === values.datapointName)) {
      return toast.error(
        "Data Point Definition with this name already exists!"
      );
    }

    const validProto = verifySemicolonEndingsInProto(customDatapoint);

    if (!validProto) {
      toast.error(
        <div>
          <h3 className="text-base">{"Bad Proto Syntax"}</h3>

          <p className="text-sm">
            {
              "Ensure you have a single field on every line and don't have any trailing spaces."
            }
          </p>
        </div>
      );
      return;
    }

    let fieldInfos = parseProtoFile(customDatapoint);

    if (fieldInfos.length) {
      setFormValues({ values, resetForm });
      setFieldInfos(fieldInfos);
      setModalStates({ openOptionsPrompt: true, optionsModal: false });
    } else {
      createDataPointDefinition({ values, resetForm, customDatapoint });
    }
  };

  const handleDatapointEditor = (value: string) => {
    setCustomDatapoint(value);
  };

  const handleDatapointSelect = async (datapoint: IOption[]) => {
    if (errorMsgs.predefinedDatapoint) {
      setErrorMsgs({ ...errorMsgs, predefinedDatapoint: "" });
    }

    setSelectedDatapoint([...datapoint]);

    if (!datapoint?.length) {
      setDataPointPolicies({});
    }

    if (selectedDatapoint.length < datapoint.length) {
      const newlyAdded = [...datapoint].pop();
      setDataPointPolicies((prev) => ({
        ...prev,
        [newlyAdded.value]: {
          name: "",
          existingPolicy: existingPolicies?.[0]
            ? {
                label: existingPolicies[0].policy_name,
                value: existingPolicies[0].id
              }
            : null,
          showCreatePolicy: false,
          inputFormat: policyInputFormatOptions.find(
            (opt) => opt.value === EDeviceDataInputFormat.PROTOBUF
          ),
          compression: dataCompressionOptions.find(
            (opt) => opt.value === EDeviceDataInputCompression.NONE
          )
        }
      }));
    } else {
      const tempDP = selectedDatapoint.find(
        ({ value: id1 }) => !datapoint.some(({ value: id2 }) => id2 === id1)
      );
      if (tempDP) {
        const newDPP = { ...dataPointPolicies };
        delete newDPP[tempDP.value];
      } else {
        console.error(
          "Could not find removed data point in the existing data points list"
        );
      }
    }
  };

  const handleClickNext = () => {
    if (selectedDatapoint.length) {
      if (onNextClick && !enableDefaultNextOperation) {
        onNextClick(
          dataPointPolicies,
          selectedDatapoint.map((opt) =>
            predefinedDatapoint.find((dp) => dp.id === opt.value)
          )
        );

        return;
      }

      if (!onNextClick || (onNextClick && enableDefaultNextOperation)) {
        if (
          Object.values(dataPointPolicies).some(
            (policy) => !policy.existingPolicy?.value
          )
        ) {
          toast.error(
            "Select data point policies for all the data point links!"
          );
          return;
        }

        selectedDatapoint.forEach((dpOpt) => {
          const payload = {
            data_point_name: dpOpt.label,
            device_data_policy_id:
              dataPointPolicies[dpOpt.value].existingPolicy.value,
            data_point_definition_id: dpOpt.value,
            active: true
          };

          linkDPMutation.mutate(payload);
        });

        if (onNextClick) {
          onNextClick(
            dataPointPolicies,
            selectedDatapoint.map((opt) =>
              predefinedDatapoint.find((dp) => dp.id === opt.value)
            )
          );
        } else {
          navigate("/fleet-and-devices/projects/download-certificates/");
        }
      }
    }
  };

  const downloadFile = () => {
    const filename = document.getElementById("datapointName")["value"];

    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([customDatapoint], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      toast.warning("To download please enter data-point name.");
    }
  };

  const options = useMemo(() => {
    return (
      predefinedDatapoint?.map(({ name, id }) => {
        return { value: id, label: name };
      }) || []
    );
  }, [predefinedDatapoint]);

  function createDataPointDefinition({
    values: { datapointName, rootProtoMsgName },
    customDatapoint,
    resetForm
  }) {
    let dataPointWithNanoPBImport: string = customDatapoint;
    // const nanoPBImportLine = 'import "nanopb.proto";';

    // if (customDatapoint.indexOf(nanoPBImportLine) < 0) {
    //   const syntaxLine = 'syntax="proto3";';
    //   const protoSyntaxIndex = customDatapoint.indexOf(syntaxLine);

    //   if (protoSyntaxIndex >= 0) {
    //     dataPointWithNanoPBImport = dataPointWithNanoPBImport.slice(
    //       0,
    //       protoSyntaxIndex + syntaxLine.length
    //     );
    //     dataPointWithNanoPBImport += "\n" + nanoPBImportLine + "\n";
    //     dataPointWithNanoPBImport += customDatapoint.slice(
    //       protoSyntaxIndex + syntaxLine.length
    //     );
    //   }
    // }

    const file = new File([dataPointWithNanoPBImport], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", datapointName);
    formData.append("message_name", rootProtoMsgName);

    dataPointDefinitionMutation.mutate(formData, {
      onSuccess: async () => {
        toast.success("Created new data point definition: " + datapointName);
        resetForm();
        setCustomDatapoint(defaultDatapoint);
        setCreateDatapoint(false);
      }
    });

    setModalStates({ openOptionsPrompt: false, optionsModal: false });
  }

  const handleSetLimitsSubmit = (values: Record<string, number>) => {
    const _fieldInfos = fieldInfos.map((fieldInfo) => {
      fieldInfo.fieldLimit =
        values[`${fieldInfo.name}-${fieldInfo.type}-${fieldInfo.line}`];

      return getUserInputForField(fieldInfo);
    });

    const updatedDataPoint = updateProtoFile(customDatapoint, _fieldInfos);

    createDataPointDefinition({
      ...formValues,
      customDatapoint: updatedDataPoint
    });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12 pb-8">
          {!hideSteps ? (
            <div className="mt-7 mb-5 w-10/12">
              <DeviceCreationSteps />
            </div>
          ) : null}

          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Apply Data-points
            </h1>

            <Formik
              initialValues={{ datapointName: "", rootProtoMsgName: "" }}
              validationSchema={newDatapointDefinitionSchema}
              onSubmit={submitCustomDatapoint}
            >
              <Form>
                {/* Conditional Render For Predefined Data-points or data-point name */}
                {createDatapoint ? (
                  <>
                    <div className="form-group mb-5 w-10/12">
                      <label className="flex font-medium text-sm mb-2">
                        Data-point Name
                      </label>
                      <Field
                        type="text"
                        id="datapointName"
                        name="datapointName"
                        placeholder="Data-point Name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="datapointName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>

                    <div className="form-group mb-5 w-10/12">
                      <label className="flex font-medium text-sm mb-2">
                        Root proto message name
                      </label>
                      <Field
                        type="text"
                        id="rootProtoMsgName"
                        name="rootProtoMsgName"
                        placeholder="Root proto message name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="rootProtoMsgName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </>
                ) : (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Select Data-point
                    </label>
                    <Select
                      isDisabled={createDatapoint}
                      placeholder="Select"
                      isSearchable={false}
                      options={options}
                      value={selectedDatapoint}
                      onChange={handleDatapointSelect}
                      isClearable={true}
                      isMulti
                      classNames={reactSelectClassNames}
                    />
                    {errorMsgs.predefinedDatapoint ? (
                      <FieldError message={errorMsgs.predefinedDatapoint} />
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* Conditional Render for datapoint Def Editor */}
                {createDatapoint && (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm">
                      Data-point Definition
                    </label>
                    <p className="text-xs mb-2">
                      Define your Data Point Definition using{" "}
                      <a
                        href="https://protobuf.dev/programming-guides/proto3/"
                        target="_blank"
                        rel={"noreferrer noopener"}
                        className=" underline"
                      >
                        Protocol Buffers 3 syntax
                      </a>
                      .
                    </p>
                    <div className="p-2 bg-[#F7F6F3] rounded-lg border border-solid border-[#D4D4D4] w-min lg:w-full">
                      <div>
                        <div className="flex justify-between items-center">
                          <ul className="flex justify-center items-center">
                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  copyToClipboard(customDatapoint)
                                }
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Copy
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  pasteFromClipboard().then((res) =>
                                    setCustomDatapoint(res)
                                  )
                                }
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Paste
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Upload
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center mr-3.5">
                              <button
                                type="button"
                                onClick={downloadFile}
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Download
                              </button>
                            </li>
                          </ul>

                          <div className="flex justify-center items-center">
                            <button
                              onClick={handleMaximizeEditor}
                              type="button"
                              className="p-1 bg-white rounded"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                                  fill="#546CCC"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="my-2 border border-solid border-[#E8E8E8]"></div>
                      </div>
                      <Editor
                        height="30vh"
                        language="cpp"
                        value={customDatapoint}
                        onChange={handleDatapointEditor}
                        theme={
                          theme === "golain" || theme === "none"
                            ? "vs"
                            : "vs-dark"
                        }
                        options={{
                          lineNumbers: "off",
                          minimap: { enabled: false },
                          scrollbar: { vertical: "hidden" },
                          overviewRulerBorder: false,
                          overviewRulerLanes: 0,
                          folding: false,
                          matchBrackets: "never",
                          theme:
                            theme === "golain" || theme === "none"
                              ? "vs"
                              : "vs-dark"
                        }}
                      />
                    </div>
                    {errorMsgs.customDatapoint ? (
                      <FieldError message={errorMsgs.customDatapoint} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <h1 className="flex mb-5 text-sm text-gray-400">
                  {createDatapoint ? "Or Select from" : "Or"} &nbsp;
                  <span
                    className="font-medium text-primary cursor-pointer"
                    onClick={() => setCreateDatapoint(!createDatapoint)}
                  >
                    {createDatapoint
                      ? "Predefined Data-point"
                      : "Create New Data-point"}
                  </span>
                </h1>
                {createDatapoint && (
                  <div>
                    <button
                      type="submit"
                      className="px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                    >
                      Done
                    </button>
                  </div>
                )}
                {!createDatapoint ? (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          type="button"
                          className={`disabled disabled:cursor-not-allowed disabled:text-contentColorLight flex w-full justify-between rounded-lg bg-background-layer1 px-4 py-2 text-left text-sm font-medium hover:bg-background-layer0.5 focus:outline-none focus-visible:ring focus-visible:ring-primaryLight`}
                        >
                          <span className="flex gap-2">
                            Define Data Policies{" "}
                            <InformationCircleIcon
                              width={20}
                              className="text-primary cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDppInfoModalOpen(true);
                              }}
                            />
                          </span>
                          <ChevronUpIcon
                            className={`${
                              open ? " transform" : "rotate-180"
                            } h-5 w-5 text-primary`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="flex flex-col gap-4 px-4 pb-2 pt-4 text-sm max-h-[400px] overflow-y-auto">
                          <ConfigureDeviceDataPolicy
                            existingPolicies={existingPolicies}
                            isExistingPoliciesLoading={
                              isExistingPoliciesLoading
                            }
                            dataPointPolicies={dataPointPolicies}
                            selectedDatapoint={selectedDatapoint}
                            setDataPointPolicies={setDataPointPolicies}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ) : null}
              </Form>
            </Formik>
          </div>

          {onBackClick ? (
            <Button
              variant="light"
              onClick={() => onNextClick(null, [])}
              className="mt-12 mb-2"
            >
              Skip adding data points
            </Button>
          ) : null}
          <div
            className={`w-10/12 ${
              createDatapoint ? "mt-6 pb-6" : onBackClick ? "" : "mt-12"
            } flex items-center space-x-4`}
          >
            <Button
              variant="secondary"
              onClick={() => {
                onBackClick
                  ? onBackClick()
                  : navigate(
                      "/fleet-and-devices/projects/download-certificates/"
                    );
              }}
              className="w-1/2 px-8 py-3 space-x-3"
            >
              {onBackClick ? "Back" : "Skip"}
            </Button>

            <Button
              onClick={handleClickNext}
              loading={linkDPMutation.isLoading || nextButtonLoading}
              disabled={!selectedDatapoint.length}
              className={`w-1/2 ${
                !selectedDatapoint.length
                  ? "disabled:bg-gray-400"
                  : "hover:bg-opacity-80"
              } px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary`}
            >
              Next
            </Button>
          </div>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="datapoint" />
        </div>
      </div>

      <DataPointPoliciesInfoModal
        open={dppInfoModalOpen}
        setOpen={setDppInfoModalOpen}
      />

      {/* Open Options Modal Prompt */}
      <Modal
        open={modalStates.openOptionsPrompt}
        setOpen={(isOpen) =>
          setModalStates((prev) => ({ ...prev, openOptionsPrompt: isOpen }))
        }
        title="JSON Editor"
        className="w-full max-w-lg"
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fields Limits</h1>
          <p>
            Looks like you have{" "}
            <span className="font-mono text-orange-500">string</span> /{" "}
            <span className="font-mono text-orange-500">byte</span> /{" "}
            <span className="font-mono text-orange-500">repeated</span> fields
            in your Proto definition. Do you want to set max limits for these
            fields or deal with them yourself
            <a
              href="https://jpa.kapsi.fi/nanopb/docs/concepts.html#field-callbacks"
              target="_blank"
              rel={"noreferrer noopener"}
              className="mx-1 underline"
            >
              (like a pro)
            </a>
            in your device's code?
          </p>
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() =>
                setModalStates((prev) => ({
                  optionsModal: false,
                  openOptionsPrompt: false
                }))
              }
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                createDataPointDefinition({ ...formValues, customDatapoint })
              }
            >
              Skip
            </Button>
            <Button
              onClick={() =>
                setModalStates((prev) => ({
                  optionsModal: true,
                  openOptionsPrompt: false
                }))
              }
            >
              Set Limits
            </Button>
          </div>
        </div>
      </Modal>

      {/*  Options Modal  */}
      <Modal
        open={modalStates.optionsModal}
        setOpen={(isOpen) =>
          setModalStates((prev) => ({ ...prev, optionsModal: isOpen }))
        }
        title="JSON Editor"
        className="w-full max-w-2xl"
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fields Limits</h1>
          <ProtoLimitsForm
            onSubmit={handleSetLimitsSubmit}
            fieldInfos={fieldInfos}
          />
        </div>
      </Modal>
    </>
  );
};

export default ApplyDataPoints;
