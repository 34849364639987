import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "../../../store";
import { loggerService, networkService } from "../../../services";
import { isEmpty } from "lodash";
import dateService from "../../../services/date.service";
import { useConfirmDelete } from "../../shared/hooks/use-confirm-delete.hooks";
import {
  ArrowUturnLeftIcon,
  CheckIcon,
  InformationCircleIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { toast } from "react-toastify";
import Modal from "@app/shared/components/modal.component";
import { generateLayout } from "../dash.helper";
import { useDeleteDashboard } from "@app/shared/hooks/delete/delete-dashboard";
import {
  IUpdateDashboardPayload,
  useUpdateDashboard
} from "@/app/shared/hooks/patch/update-dashboard";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { useUpdateBlueprint } from "@/app/shared/hooks/patch/update-blueprint";
import { GRID_COLS } from "../dash-constants";
import { IDashboard, IDashboardPanel } from "@/interfaces";

const FILTERS = ["5s", "10s", "30s", "1m", "30m", "1h", "12h"];

interface IDashboardUtilsHeaderProps {
  addPanel: () => void;
  layouts: any;
  setLayouts: (x: any) => void;
  panels: IDashboardPanel[];
  dashBlueprint: IBlueprint;
  hideAddPanel: boolean;
  hideEditLayout: boolean;
  hideDelete: boolean;
}

const DashboardUtilsHeader: React.FC<IDashboardUtilsHeaderProps> = ({
  addPanel,
  layouts,
  setLayouts,
  panels,
  dashBlueprint,
  hideAddPanel = false,
  hideEditLayout = false,
  hideDelete = false
}) => {
  const [filter, setFilter] = useState("1h");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [resetLayoutModalOpen, setResetLayoutModalOpen] = useState(false);

  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const [
    zoomLevel,
    activeFilter,
    activeDashboard,
    editDashboard,
    setEditDashboard,
    setSavedLayouts,
    setActiveFilter,
    setZoomLevel,
    setActiveDashboard
  ] = useDashboardStore((state) => [
    state.zoomLevel,
    state.activeFilter,
    state.activeDashboard,
    state.editingLayout,
    state.setEditingLayout,
    state.setLayouts,
    state.setActiveFilter,
    state.setZoomLevel,
    state.setActiveDashboard
  ]);

  const [panelZoom, setPanelZoom] = useState(zoomLevel);
  const [hoveredFilter, setHoveredFilter] = useState(false);
  const openAllFiltersTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isEmpty(activeFilter)) {
      setToDefaultFilter();
    } else {
      const { type, span, rangeEnd, rangeStart } = activeFilter;
      if (type === "time" && span !== filter) {
        setFilter(span);
      } else if (
        type === "range" &&
        startDate !== rangeStart &&
        endDate !== rangeEnd
      ) {
        setFilter("");
        setStartDate(new Date(rangeStart));
        setEndDate(new Date(rangeEnd));
      }
    }
    const filterTimer = openAllFiltersTimer.current;
    return () => {
      if (filterTimer) {
        clearTimeout(filterTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearFilter = () => {
    const fltrBtns = document.getElementsByName("timeFilters");
    if (fltrBtns.length) {
      fltrBtns.forEach((option) => {
        option["checked"] = false;
      });
      setFilter("");
    }

    clearDateFilter();
  };

  const onChangeDateRange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end && filter) {
      const fltrBtns = document.getElementsByName("timeFilters");
      if (fltrBtns.length) {
        fltrBtns.forEach((option) => {
          option["checked"] = false;
        });
        setFilter("");
      }
    }

    if (start && end) {
      setActiveFilter({
        type: "range",
        rangeEnd: end,
        rangeStart: start,
        start: dateService.convertDateObjToUTCDateFormatted(start),
        stop: dateService.convertDateObjToUTCDateFormatted(end)
      });
    }
  };

  const currentActiveFilter = (event: any) => {
    const currentOption = event.target.value;
    setFilter(currentOption);

    if (startDate && endDate) {
      setStartDate(new Date());
      setEndDate(null);
    }

    const currentTime = dateService.getCurrentUTCDate().format();

    const filters = {
      "5s": dateService.getCurrentUTCDate().subtract(5, "seconds").format(),
      "10s": dateService.getCurrentUTCDate().subtract(10, "seconds").format(),
      "30s": dateService.getCurrentUTCDate().subtract(30, "seconds").format(),
      "1m": dateService.getCurrentUTCDate().subtract(1, "minutes").format(),
      "30m": dateService.getCurrentUTCDate().subtract(30, "minutes").format(),
      "1h": dateService.getCurrentUTCDate().subtract(1, "hours").format(),
      "12h": dateService.getCurrentUTCDate().subtract(12, "hours").format()
    };

    const start = filters[currentOption];
    const stop = currentTime;

    setActiveFilter({ type: "time", span: currentOption, start, stop });
  };

  const clearDateFilter = () => {
    setStartDate(new Date());
    setEndDate(null);

    setToDefaultFilter();
  };

  const setToDefaultFilter = () => {
    /* Default Filter */
    setFilter("1h");
    const start = dateService
      .getCurrentUTCDate()
      .subtract(1, "hours")
      .format();
    const stop = dateService.getCurrentUTCDate().format();
    setActiveFilter({ type: "time", span: "1h", start, stop });
  };

  const handleZoomLevel = (zoom: string) => {
    let currZoom = panelZoom;
    if (zoom === "IN" && currZoom > 5) {
      currZoom -= 5;
    } else if (zoom === "OUT" && currZoom < 100) {
      currZoom += 5;
    }
    if (currZoom !== panelZoom) {
      setPanelZoom(currZoom);
      setZoomLevel(currZoom);
    }
  };

  const deleteDashMutation = useDeleteDashboard();
  const updateDashboardMutation = useUpdateDashboard();
  const updateBlueprintMutation = useUpdateBlueprint();

  const deleteDashboard = async () => {
    deleteDashMutation.mutate(activeDashboard.id, {
      onSuccess: (ok) => {
        if (ok) {
          toast.success("Dashboard deleted successfully");
        }
      },
      onSettled: () => {
        setActiveDashboard({} as IDashboard);
      }
    });
  };

  const saveDashboardLayout = () => {
    if (dashBlueprint) {
      const payload = {
        ...dashBlueprint,
        meta_data: {
          ...dashBlueprint.meta_data,
          layouts
        }
      };

      updateBlueprintMutation.mutate(
        { data: payload, blueprintId: dashBlueprint.id },
        {
          onSuccess: (ok) => {
            if (ok) {
              toast.success("Dashboard Blueprint layout saved successfully");
              setSavedLayouts(layouts);
              setEditDashboard(false);
            }
          }
        }
      );
    } else {
      const { id } = activeDashboard;
      const payload: IUpdateDashboardPayload = {
        meta_data: { ...(activeDashboard?.meta_data ?? {}), layouts }
      };

      updateDashboardMutation.mutate(
        { data: payload, dashboardId: id },
        {
          onSuccess: (ok) => {
            if (ok) {
              toast.success("Dashboard layout saved successfully");
              setSavedLayouts(layouts);
              setEditDashboard(false);
            }
          }
        }
      );
    }
  };

  const resetDashboardLayout = () => {
    const { id } = activeDashboard;
    const payload = {
      meta_data: { layouts: null }
    };
    networkService
      .patch<{ ok: number }>(
        `projects/${selectedProject.id}/dashboards/${id}`,
        payload,
        {
          headers: {
            "ORG-ID": user.selectedOrg.id
          }
        }
      )
      .then((res) => {
        if (res.ok) {
          toast.success("Dashboard layout reset successfully");
          const newLayouts = Object.keys(GRID_COLS).reduce((acc, cur) => {
            acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
            return acc;
          }, {});
          setLayouts((prev) => ({
            ...prev,
            layouts: newLayouts
          }));
          setSavedLayouts(newLayouts);
          setEditDashboard(false);
        } else {
          toast.error("Error resetting dashboard layout");
        }
      })
      .catch((err) => {
        loggerService.error(err);
        toast.error("Error resetting dashboard layout");
      });
  };

  return (
    <>
      <section className="text-contentColor">
        <div className="flex justify-between">
          <div className="flex">
            <ul className="flex justify-center items-center gap-2">
              {/* dashboard settings, share */}
              <li className=" flex items-center cursor-pointer">
                <MagnifyingGlassMinusIcon
                  width={16}
                  className="mr-1"
                  onClick={() => handleZoomLevel("IN")}
                />
                <span className="text-xs lg:text-sm">{panelZoom}%</span>
                <MagnifyingGlassPlusIcon
                  width={16}
                  className="mr-1"
                  onClick={() => handleZoomLevel("OUT")}
                />
              </li>
              {!hideDelete ? (
                <li className="ml-3 flex items-center cursor-pointer">
                  <Button
                    onClick={() =>
                      openConfirmDeleteModal(
                        deleteDashboard,
                        "Are you sure you want to delete this dashboard? (All of the panels will also be deleted!)"
                      )
                    }
                    variant="light"
                    icon={TrashIcon}
                    color="slate"
                    tooltip="Delete Dashboard"
                  />
                </li>
              ) : null}
              {!hideEditLayout ? (
                <li className="ml-3 flex items-center cursor-pointer">
                  <Button
                    onClick={() => {
                      if (editDashboard) {
                        saveDashboardLayout();
                      }

                      setEditDashboard(!editDashboard);
                    }}
                    variant="light"
                    icon={editDashboard ? CheckIcon : PencilIcon}
                    color="slate"
                    tooltip={editDashboard ? "Save" : "Edit Dashboard"}
                  />
                </li>
              ) : null}
              {!hideEditLayout ? (
                <li className="ml-3 flex items-center cursor-pointer">
                  <Button
                    onClick={() => {
                      setResetLayoutModalOpen(true);
                    }}
                    variant="light"
                    icon={ArrowUturnLeftIcon}
                    color="slate"
                    tooltip="Reset Dashboard Layout"
                  />
                </li>
              ) : null}
              {!hideAddPanel && (
                <li>
                  <button
                    onClick={addPanel}
                    style={{ borderWidth: 1 }}
                    className="ml-4 border-gray-300 rounded-md px-2 py-1 whitespace-nowrap text-xs lg:text-sm flex justify-center items-center"
                  >
                    <PlusIcon width={16} className="mr-1" />
                    Add Panel
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="flex items-center">
            <ul className="flex justify-center items-center">
              <li className="mr-4 flex items-center">
                <h1 className="text-contentColorLight text-xs lg:text-sm">
                  Show data for last
                </h1>
              </li>
              <li className="mr-2 flex items-center cursor-pointer">
                <button
                  onClick={onClearFilter}
                  className="py-2 px-2.5 border border-dashed border-contentColorLight rounded text-xs text-contentColorLight"
                >
                  Clear
                </button>
              </li>

              <div
                className="flex flex-row gap-1 transform transition-all"
                style={{
                  width: hoveredFilter
                    ? "330px"
                    : filter === "30m"
                    ? "80px"
                    : "70px",
                  overflowX: "hidden"
                }}
                onMouseEnter={() => {
                  clearTimeout(openAllFiltersTimer.current);
                  setHoveredFilter(true);
                }}
                onMouseLeave={() => {
                  openAllFiltersTimer.current = setTimeout(() => {
                    setHoveredFilter(false);
                  }, 1500);
                }}
              >
                {FILTERS.filter((filterText) => {
                  if (hoveredFilter) return true;
                  return filterText === filter;
                }).map((filterText) => (
                  <li
                    key={"filter" + filterText}
                    className="relative mr-2 flex items-center cursor-pointer"
                  >
                    <input
                      className="cursor-pointer absolute w-full h-full bg-none bg-transparent border-0 rounded-none pointer checked:bg-none checked:bg-transparent checked:border-0 checked:focus:bg-none checked:focus:bg-transparent checked:focus:border-0 checked:hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                      type="radio"
                      id={filterText}
                      name="timeFilters"
                      value={filterText}
                      checked={filter === filterText}
                      onChange={currentActiveFilter}
                    />
                    {filter === filterText ? (
                      <button className="py-2 px-2.5 flex justify-center items-center bg-background-layer2 border border-solid border-primaryLight text-primaryLight rounded text-xs">
                        <CheckIcon width={14} />

                        <h1 className="ml-2">{filterText}</h1>
                      </button>
                    ) : (
                      <button className="py-2 px-2.5 border border-dashed border-[#B7B9C1] rounded text-xs text-contentColorLight">
                        {filterText}
                      </button>
                    )}
                  </li>
                ))}
              </div>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <div className="absolute">
                  <DatePicker
                    className="opacity-0 w-full h-full cursor-pointer bg-none bg-transparent border-0 rounded-none focus:outline-none focus:ring-0 focus:ring-offset-0"
                    onChange={onChangeDateRange}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  />
                </div>
                <button className="py-2 px-2.5 flex justify-center items-center border border-dashed border-[#B7B9C1] rounded text-xs text-contentColorLight">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66667 7.33342H6V8.66675H4.66667V7.33342ZM14 3.33341V12.6667C14 13.4067 13.4067 14.0001 12.6667 14.0001H3.33333C2.59333 14.0001 2 13.4001 2 12.6667V3.33341C2 2.60008 2.6 2.00008 3.33333 2.00008H4V0.666748H5.33333V2.00008H10.6667V0.666748H12V2.00008H12.6667C13.4067 2.00008 14 2.60008 14 3.33341ZM3.33333 4.66675H12.6667V3.33341H3.33333V4.66675ZM12.6667 12.6667V6.00008H3.33333V12.6667H12.6667ZM10 8.66675V7.33342H11.3333V8.66675H10ZM7.33333 8.66675V7.33342H8.66667V8.66675H7.33333ZM4.66667 10.0001H6V11.3334H4.66667V10.0001ZM10 11.3334V10.0001H11.3333V11.3334H10ZM7.33333 11.3334V10.0001H8.66667V11.3334H7.33333Z"
                      fill="#565759"
                    />
                  </svg>
                  <h1 className="ml-2">Custom</h1>
                </button>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        {startDate && endDate ? (
          <div className="mt-3.5 flex items-center">
            <div className="px-3 py-2 flex items-center bg-white border border-solid border-primary rounded text-contentColorLight text-xs lg:text-sm">
              <p>Showing data for: </p>
              <h1 className="font-bold">
                &nbsp;
                {`${dateService
                  .convertDateObjToMoment(startDate)
                  .format("DD/MM/YYYY")} - ${dateService
                  .convertDateObjToMoment(endDate)
                  .format("DD/MM/YYYY")}`}
              </h1>
              <button onClick={clearDateFilter} className="ml-2.5">
                <XMarkIcon width={14} />
              </button>
            </div>
          </div>
        ) : null}
        <div className="mt-3.5 border border-solid border-background-layer2"></div>
        {editDashboard ? (
          <div className="mt-3.5 flex items-center">
            <div className="px-3 py-2 flex items-center bg-background border border-solid border-primary rounded text-contentColorLight text-xs lg:text-sm">
              <p className="flex gap-1">
                <InformationCircleIcon className="text-primary" width={18} />{" "}
                Editing Dashboard Arrangement{" "}
              </p>

              <Button
                onClick={() => {
                  saveDashboardLayout();
                }}
                size="xs"
                className="ml-2.5 bg-primary text-white rounded text-xs lg:text-sm"
              >
                <span className="flex gap-1 items-center flex-row">
                  {" "}
                  <CheckIcon width={18} /> Save
                </span>
              </Button>
            </div>
          </div>
        ) : null}
      </section>
      <Modal open={resetLayoutModalOpen} setOpen={setResetLayoutModalOpen}>
        <div className="flex flex-col gap-4 p-6 max-w-md bg-background text-contentColor">
          <h1 className="text-lg font-bold">Reset Dashboard Layout</h1>
          <p>
            Are you sure you want to reset the dashboard layout? This will
            reset the dashboard to the default layout.
          </p>
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() => {
                setResetLayoutModalOpen(false);
              }}
              size="xs"
              className="text-gray-500 border-gray-400 rounded text-xs lg:text-sm"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                resetDashboardLayout();
                setResetLayoutModalOpen(false);
              }}
              size="xs"
              color="red"
              className="text-white rounded text-xs lg:text-sm"
            >
              Reset
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DashboardUtilsHeader;
