import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export interface IUpdateBlueprintPayload {
  name: string;
  kind: "PANEL" | "DASHBOARD";
  definition: any;
  meta_data?: any;
}

interface IUpdateBlueprintResponse {
  ok: number;
}

export const updateBlueprint = async (
  projectId: string,
  orgId: string,
  blueprintId: string,
  payload: any
) => {
  const { ok } = await networkService.patch<IUpdateBlueprintResponse>(
    `projects/${projectId}/blueprints/${blueprintId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateBlueprint = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      blueprintId
    }: {
      data: IUpdateBlueprintPayload;
      blueprintId: string;
    }) =>
      await updateBlueprint(
        selectedProject.id,
        user.selectedOrg.id,
        blueprintId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getBlueprints");
      }
    }
  );

  return res;
};
