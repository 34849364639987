import { TTriggerType } from "@/interfaces/triggers.interface";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const triggerTypes: { value: TTriggerType; label: string }[] = [
  { value: "HTTP", label: "HTTP" },
  { value: "MQTT", label: "MQTT" },
  { value: "PLATFORM", label: "Device Disconnection" },
  { value: "LOCATION", label: "Location (GeoFence)" }
];

const RuleTriggerTypeSelector = ({
  ruleTriggerType,
  setRuleTriggerType,
  buttonClassNames = "",
  menuClassNames = ""
}) => {
  return (
    <TransitionedMenu
      buttonComponent={
        <div
          className={`flex justify-between px-2 z-20 py-1 gap-2 rounded-md text-nowrap text-contentColor items-center bg-background-layer2 ${buttonClassNames}`}
        >
          <span>
            {triggerTypes.find((trig) => trig.value === ruleTriggerType).label}
          </span>
          <ChevronDownIcon width={16} />
        </div>
      }
    >
      {triggerTypes.map((trig) => (
        <Menu.Item key={trig.value}>
          {({ active }) => (
            <button
              className={`w-full flex gap-1 z-20 items-center uppercase ${
                active && "bg-background-layer2"
              } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
              onClick={() => setRuleTriggerType(trig.value)}
            >
              {trig.label}
            </button>
          )}
        </Menu.Item>
      ))}
    </TransitionedMenu>
  );
};

export default RuleTriggerTypeSelector;
