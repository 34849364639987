import React, { Dispatch, SetStateAction, useMemo } from "react";
import Select from "react-select";
import {
  TDeviceDataPoliciesState,
  dataCompressionOptions,
  policyInputFormatOptions
} from "../utils/fad-apply-data-points-helper";

import {
  EDeviceDataInputCompression,
  EDeviceDataInputFormat
} from "@/interfaces/data-policies.interface";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IDataPolicy, IOption } from "@/interfaces";
import { useFleetAndDevicesStore } from "@/store";
import { Button } from "@tremor/react";
import {
  ICreateDeviceDataPolicyPayload,
  useCreateDeviceDataPolicy
} from "@/app/shared/hooks/post/device-data-policy";
import { toast } from "react-toastify";

interface IConfigureDeviceDataPolicyProps {
  dataPointPolicies: TDeviceDataPoliciesState;
  setDataPointPolicies: Dispatch<SetStateAction<TDeviceDataPoliciesState>>;
  selectedDatapoint: IOption[];
  existingPolicies: IDataPolicy[];
  isExistingPoliciesLoading: boolean;
}

const ConfigureDeviceDataPolicy: React.FC<IConfigureDeviceDataPolicyProps> = ({
  dataPointPolicies,
  selectedDatapoint,
  existingPolicies,
  isExistingPoliciesLoading,
  setDataPointPolicies
}) => {
  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const createDeviceDataPolicyMutation = useCreateDeviceDataPolicy();

  const existingPoliciesOptions = useMemo(
    () =>
      existingPolicies?.map((policy) => ({
        label: policy.policy_name,
        value: policy.id
      })) || [],
    [existingPolicies]
  );

  const onPolicyCreate = (
    dataPointId: string,
    policyDetails: TDeviceDataPoliciesState[string]
  ) => {
    const createHandler = () => {
      const payload: ICreateDeviceDataPolicyPayload = {
        policy_name: policyDetails.name,
        policy: {
          "storage-format": "TSDB",
          input_compression: policyDetails.compression.value,
          input_format: policyDetails.inputFormat.value,
          v: 0
        }
      };
      createDeviceDataPolicyMutation.mutate(
        { fleetId: selectedFleet.id, data: payload },
        {
          onSuccess: (id) => {
            setDataPointPolicies((prev) => ({
              ...prev,
              [dataPointId]: {
                ...prev[dataPointId],
                showCreatePolicy: false,
                existingPolicy: {
                  label: policyDetails.name,
                  value: id
                }
              }
            }));
            toast.success("Created a new data point policy successfully.");
          }
        }
      );
    };

    return createHandler;
  };

  return (
    <>
      {Object.keys(dataPointPolicies).map((dpId) => (
        <div key={dpId} className="border-b pb-5 border-background-layer3">
          <h4 className="font-medium">
            {selectedDatapoint.find((opt) => opt.value === dpId).label}
          </h4>
          <div className="flex gap-2">
            {!dataPointPolicies[dpId]?.showCreatePolicy ? (
              <div>
                <label>
                  <span className="text-xs text-contentColorLight">
                    Select Policy
                  </span>
                  <Select
                    placeholder="Input Format"
                    isLoading={isExistingPoliciesLoading}
                    isSearchable={false}
                    options={existingPoliciesOptions}
                    value={dataPointPolicies[dpId]?.existingPolicy}
                    onChange={(opt: IOption<EDeviceDataInputFormat>) =>
                      setDataPointPolicies((prev) => ({
                        ...prev,
                        [dpId]: {
                          ...prev[dpId],
                          existingPolicy: opt
                        }
                      }))
                    }
                    classNames={reactSelectClassNames}
                    menuPortalTarget={document.body}
                    className="min-w-[300px]"
                  />
                </label>
                <Button
                  variant="light"
                  className="mt-2"
                  onClick={() =>
                    setDataPointPolicies((prev) => ({
                      ...prev,
                      [dpId]: {
                        ...prev[dpId],
                        showCreatePolicy: true
                      }
                    }))
                  }
                >
                  Or create a new one
                </Button>
              </div>
            ) : (
              <div>
                <label>
                  <span className="text-xs text-contentColorLight">
                    Policy Name
                  </span>
                  <input
                    placeholder="Policy Name"
                    value={dataPointPolicies[dpId]?.name}
                    onChange={(e) =>
                      setDataPointPolicies((prev) => ({
                        ...prev,
                        [dpId]: {
                          ...prev[dpId],
                          name: e.target.value
                        }
                      }))
                    }
                    className="block min-w-[300px] px-3 py-2 bg-background placeholder:text-contentColorLighter border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                  />
                </label>
                <div className="flex mt-2 gap-2 w-full">
                  <label>
                    <span className="text-xs text-contentColorLight">
                      Input Format
                    </span>
                    <Select
                      placeholder="Input Format"
                      isSearchable={false}
                      options={policyInputFormatOptions}
                      value={dataPointPolicies[dpId]?.inputFormat}
                      onChange={(opt: IOption<EDeviceDataInputFormat>) =>
                        setDataPointPolicies((prev) => ({
                          ...prev,
                          [dpId]: {
                            ...prev[dpId],
                            inputFormat: opt
                          }
                        }))
                      }
                      classNames={reactSelectClassNames}
                      menuPortalTarget={document.body}
                      className="min-w-[200px]"
                    />
                  </label>
                  <label>
                    <span className="text-xs text-contentColorLight">
                      Compression Format
                    </span>
                    <Select
                      placeholder="Compression"
                      isSearchable={false}
                      options={dataCompressionOptions}
                      value={dataPointPolicies[dpId]?.compression}
                      onChange={(opt: IOption<EDeviceDataInputCompression>) =>
                        setDataPointPolicies((prev) => ({
                          ...prev,
                          [dpId]: {
                            ...prev[dpId],
                            compression: opt
                          }
                        }))
                      }
                      classNames={reactSelectClassNames}
                      menuPortalTarget={document.body}
                      className="min-w-[200px]"
                    />
                  </label>
                  <Button
                    variant="secondary"
                    loading={createDeviceDataPolicyMutation.isLoading}
                    onClick={onPolicyCreate(dpId, dataPointPolicies[dpId])}
                    className="self-end ml-4"
                  >
                    Create
                  </Button>
                </div>

                <Button
                  variant="light"
                  className="mt-2"
                  onClick={() =>
                    setDataPointPolicies((prev) => ({
                      ...prev,
                      [dpId]: {
                        ...prev[dpId],
                        showCreatePolicy: false
                      }
                    }))
                  }
                >
                  Select Predefined
                </Button>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
export default ConfigureDeviceDataPolicy;
