import React, { useEffect, useId, useMemo, useRef } from "react";
import L, {
  LatLngExpression,
  Map,
  Popup as PopupType,
  icon,
  point
} from "leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents
} from "react-leaflet";
import {
  ICluster,
  IDevice,
  ILocationDevice,
  IPopupConfig
} from "@interfaces/index";

import "leaflet/dist/leaflet.css";
import LocationCluster from "./dash-map-cluster-component";
import { useDashboardStore } from "@/store";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Badge } from "@tremor/react";
import { decodeBase64toUTF8 } from "@/app/shared/utils/helper.util";

const defaultIconConfig = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};

L.Icon.Default.mergeOptions(defaultIconConfig);

export interface IMapViewProps {
  title: string;
  showExample?: boolean;
  definition?: {
    shadowDefIDs: string[];
    popupConfigs: Record<string, IPopupConfig>;
  };
  bounding_box: number[];
  devices: ILocationDevice[];
  clusters: ICluster[];
  geoMapDevicesWithShadows?: IDevice[];
  onChangeBoundingBox: (x: number[]) => void;
  zoom?: number;
}

const MapView: React.FC<IMapViewProps> = ({
  showExample,
  definition,
  devices,
  clusters,
  bounding_box,
  geoMapDevicesWithShadows,
  onChangeBoundingBox
}) => {
  const mapRef = useRef<Map>();
  const resizeObserverRef = React.useRef<ResizeObserver>(null);

  const createPanelAppearance = useDashboardStore(
    (state) => state.createPanelAppearance
  );

  const center = (bounding_box: number[]) => {
    return {
      lng: (bounding_box[0] + bounding_box[2]) / 2,
      lat: (bounding_box[1] + bounding_box[3]) / 2
    };
  };

  const mapCenter = center(bounding_box) as LatLngExpression;

  const Bounds = () => {
    const maphand = useMap();
    useMapEvents({
      zoomend: () => {
        onChangeBoundingBox(
          maphand
            .getBounds()
            .toBBoxString()
            .split(",")
            .map((ele) => +ele)
        );
      },
      moveend: () => {
        onChangeBoundingBox(
          maphand
            .getBounds()
            .toBBoxString()
            .split(",")
            .map((ele) => +ele)
        );
      }
    });
    return null;
  };

  const id = useId();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;
          mapRef.current = ref;

          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={mapCenter}
        zoom={10}
        zoomSnap={0.45}
        style={{
          width: "100%",
          height: showExample ? "300px" : "calc(100% - 0px)",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {devices.map((device: ILocationDevice) =>
          showExample ? (
            <Marker
              key={device.device_id}
              icon={
                createPanelAppearance?.popupConfigs?.[
                  createPanelAppearance?.configuringShadowDefId
                ]?.iconURL
                  ? icon({
                      iconUrl:
                        createPanelAppearance?.popupConfigs?.[
                          createPanelAppearance?.configuringShadowDefId
                        ]?.iconURL,
                      iconSize: point(34, 34)
                    })
                  : icon({
                      ...defaultIconConfig,
                      iconSize: point(24, 40),
                      iconAnchor: point(0, 0)
                    })
              }
              position={{ lat: device.latitude, lng: device.longitude }}
            >
              <CustomPopup
                map={mapRef.current}
                isOpen={!!createPanelAppearance.configuringShadowDefId}
                device={device}
                config={
                  createPanelAppearance?.popupConfigs?.[
                    createPanelAppearance?.configuringShadowDefId
                  ]
                }
                showExample
              />
            </Marker>
          ) : (
            <Marker
              key={device.device_id}
              icon={
                definition?.popupConfigs?.[device.shadow_definition_id]
                  ?.iconURL
                  ? icon({
                      iconUrl:
                        definition?.popupConfigs?.[device.shadow_definition_id]
                          ?.iconURL,
                      iconSize: point(34, 34)
                    })
                  : icon({
                      ...defaultIconConfig,
                      iconSize: point(24, 40),
                      iconAnchor: point(0, 0)
                    })
              }
              position={{ lat: device.latitude, lng: device.longitude }}
            >
              <CustomPopup
                map={mapRef.current}
                isOpen={false}
                device={device}
                config={
                  definition?.popupConfigs?.[device.shadow_definition_id]
                }
                devicesWithShadows={geoMapDevicesWithShadows}
              />
            </Marker>
          )
        )}
        {clusters.map((cluster: ICluster) => (
          <LocationCluster key={cluster.cluster_id} {...cluster} />
        ))}
        <Bounds />
      </MapContainer>
    </div>
  );
};

interface ICustomPopupProps {
  map: Map;
  isOpen: boolean;
  device: ILocationDevice;
  config?: IPopupConfig;
  devicesWithShadows?: IDevice[];
  showExample?: boolean;
}

const CustomPopup: React.FC<ICustomPopupProps> = ({
  map,
  isOpen,
  device,
  config,
  devicesWithShadows,
  showExample
}) => {
  const popupRef = useRef<PopupType>(null);
  const deviceWithShadow = devicesWithShadows?.find(
    (d) => d.id === device.device_id
  );

  const shadow = JSON.parse(
    decodeBase64toUTF8(deviceWithShadow?.current_shadow ?? "") || "{}"
  );

  const initialValues = useMemo(() => {
    const obj = {};
    if (config?.shadowPropsToShow?.length) {
      config?.shadowPropsToShow.map(
        (option) =>
          (obj[option.value.name] =
            option.value.cardinality === "repeated"
              ? []
              : option.value.structure === "number"
              ? 0
              : option.value.structure === "boolean"
              ? false
              : option.value.options
              ? option.value.options[0]
              : "")
      );
    }
    return { ...obj, ...shadow };
  }, [config?.shadowPropsToShow, shadow]);

  useEffect(() => {
    if (isOpen && map) {
      popupRef.current.openOn(map);
    } else {
      if (map) {
        map.closePopup();
      }
    }
  }, [isOpen, map]);

  return (
    <Popup ref={popupRef}>
      <div className="flex flex-col gap-1 ">
        <div className="text-base font-medium ">{device.device_name}</div>
        {config?.showLocation ? (
          <div className="flex gap-1 items-center">
            <MapPinIcon width={18} />
            <div>
              {device.latitude.toFixed(6)}, {device.longitude.toFixed(6)}
            </div>
          </div>
        ) : null}

        <div className="flex flex-col mt-2">
          {config?.shadowPropsToShow?.map((opt, ind) => (
            <div
              key={opt.value.name + ind}
              className="text-sm text-contentColor flex gap-1"
            >
              <span className="font-bold">{opt.value.name}:</span>
              {Array.isArray(initialValues?.[opt.value.name]) ? (
                initialValues?.[opt.value.name].join(", ")
              ) : opt.value.options?.length ? (
                <Badge
                  size={"xs"}
                  color={
                    opt.enumBadgeColors?.[initialValues?.[opt.value.name]] ??
                    "gray"
                  }
                >
                  {initialValues?.[opt.value.name]}
                </Badge>
              ) : (
                String(initialValues?.[opt.value.name])
              )}
            </div>
          ))}
        </div>

        {config?.showNavigateToDevice ? (
          <Link
            to={
              showExample
                ? ``
                : `/fleet-and-devices/projects/device-details/${device.device_id}`
            }
            className="!text-white mt-2 w-full px-2 py-1 rounded-md bg-blue-500 hover:bg-blue-600 text-center text-sm"
          >
            View Device
          </Link>
        ) : null}
      </div>
    </Popup>
  );
};

export default React.memo(MapView);
