import React, { useState } from "react";
import { IQuery, IWith } from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import QueryComponent from "../query.component";

interface WithClauseProps {
  withClause: IWith[];
  onChangeHandler: (_with: IWith[]) => void;
}

const WithClause: React.FC<WithClauseProps> = ({
  withClause,
  onChangeHandler
}) => {
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const onHover = (ind) => {
    setHoveredGroup(ind);
  };

  const onMouseLeave = () => {
    setHoveredGroup(null);
  };

  return (
    <div className="flex flex-col gap-4 border bg-background-layer1 border-background-layer3 p-4 mb-4 ml-12">
      {withClause.map((_with, ind) => (
        <div
          onMouseEnter={() => onHover(ind)}
          onMouseLeave={() => onMouseLeave()}
          className="flex relative flex-col"
        >
          {hoveredGroup === ind ? (
            <Button
              type="button"
              icon={TrashIcon}
              color="red"
              variant="light"
              className="absolute !text-sm right-1 top-0"
              onClick={() => {
                const newWithClause = [...withClause];
                newWithClause.splice(ind, 1);
                onChangeHandler(newWithClause);
              }}
            />
          ) : null}

          <label className="flex gap-2 items-center mb-4">
            Alias:
            <input
              type="text"
              value={withClause[ind].alias}
              placeholder="Alias"
              onChange={(e) => {
                const newWithClause = [...withClause];
                newWithClause[ind] = {
                  ...newWithClause[ind],
                  alias: e.target.value.trim()
                };
                onChangeHandler(newWithClause);
              }}
              className="text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
            />
          </label>

          <QueryComponent
            query={_with.query}
            isSub
            setQuery={(query) => {
              const newWithClause = [...withClause];
              newWithClause[ind] = {
                ...newWithClause[ind],
                query
              };
              onChangeHandler(newWithClause);
            }}
          />

          <hr className="w-full border-contentColorLight" />
        </div>
      ))}
      <Button
        icon={PlusIcon}
        // disabled={groupBy.some((group) => !group)}
        size="xs"
        className="h-min self-end mb-1"
        onClick={() => {
          const newQuery: IQuery = {
            dataPointId: "",
            tableName: "",
            contextDefinitionId: "",
            select: [],
            alias: "",
            view: "",
            group: [],
            order: [],
            limit: 0,
            offset: 0,
            createView: false
          };
          onChangeHandler([
            ...withClause,
            {
              alias: "",
              query: newQuery
            }
          ]);
        }}
      >
        With
      </Button>
    </div>
  );
};

export default WithClause;
