import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const unLinkDataPoint = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  dataPointId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/data_points/${dataPointId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUnlinkDataPoint = (fleetId, deviceId) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (dataPointId: string) =>
      await unLinkDataPoint(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        dataPointId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getDeviceDataPoints"]);
        queryClient.invalidateQueries(["getDataPointDefinitions"]);
        queryClient.invalidateQueries(["getDeviceDataPolicies"]);
      }
    }
  );

  return res;
};
