export enum EDeviceType {
  AQI = "AQI",
  HVAC = "HVAC",
  BULBS = "BULBS",
  ENERGY_METERS = "ENERGY_METERS"
}

export type TDeviceType = "AQI" | "HVAC" | "BULBS" | "ENERGY_METERS";

export const DeviceColorMapping: Record<EDeviceType, string> = {
  [EDeviceType.AQI]: "#fc0b03",
  [EDeviceType.BULBS]: "#034afc",
  [EDeviceType.HVAC]: "#fca903",
  [EDeviceType.ENERGY_METERS]: "#0bfc03"
};

export const DeviceLabelMapping: Record<EDeviceType, string> = {
  [EDeviceType.AQI]: "AQI",
  [EDeviceType.BULBS]: "Smart Bulbs",
  [EDeviceType.HVAC]: "AC / HVAC",
  [EDeviceType.ENERGY_METERS]: "Energy Meters"
};

export const DeviceDescriptionMapping: Record<EDeviceType, string> = {
  [EDeviceType.AQI]:
    "An AQI Sensor collecting concentrations of different pollutants.",
  [EDeviceType.BULBS]:
    "A Smart Bulb adjusting to environmental light conditions.",
  [EDeviceType.HVAC]: "Heating, ventilation, and air conditioning.",
  [EDeviceType.ENERGY_METERS]:
    "A device that measures the amount of electric energy consumed."
};

export const LEVEL_3_INITIAL_STATE = {
  AQI: [
    {
      id: "80783ebb-ac0f-5550-ab25-b69a5f640da2",
      name: "AQI-H-2",
      position: {
        levelIndex: 2,
        x: 13.823714794865381,
        z: -2.299076709055864,
        elevation: 2.487319080561802
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "10c70cbf-6a1a-5c00-b589-f971a1caf9ae",
      name: "AQI-M-1",
      position: {
        levelIndex: 2,
        x: 19.11861917869944,
        z: -16.452221852100482,
        elevation: 2.4085457783952364
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "803491ca-dd59-5d88-ba0b-c4724f9cf5b7",
      name: "AQI-T-3",
      position: {
        levelIndex: 2,
        x: 20.25811546996622,
        z: -8.949978568775496,
        elevation: 2.420649286913042
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "df4a7c38-cbcf-531b-8b71-27315773d467",
      name: "AQI-M-4",
      position: {
        levelIndex: 2,
        x: 20.232077740206055,
        z: -12.7365196845522,
        elevation: 2.5049295557678093
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "b20f193d-0e2b-5c3d-93bd-a4ac105ef57e",
      name: "AQI-O-2",
      position: {
        levelIndex: 2,
        x: 20.21085489677107,
        z: -5.230800119661486,
        elevation: 2.413536674596868
      },
      type: "AQI",
      color: "#f56542"
    }
  ],
  BULBS: [],
  ENERGY_METERS: [
    {
      id: "4dc56c2d-ac42-51e9-af30-0ecccc142cfe",
      name: "ENERGY_METERS-M-8",
      position: {
        levelIndex: 2,
        x: 19.07020849745776,
        z: -20.21031642663154,
        elevation: 2.28094171377432
      },
      type: "ENERGY_METERS",
      color: "#81fd66"
    }
  ],
  HVAC: [
    {
      id: "60d0fd4f-4178-53ce-9553-2e4199977312",
      name: "HVAC-N-9",
      position: {
        levelIndex: 2,
        x: 13.766807121622245,
        z: -18.557528677367504,
        elevation: 2.3487870655971648
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "40c194a8-3ec7-5b8d-8c9a-eaa6df47f587",
      name: "HVAC-V-8",
      position: {
        levelIndex: 2,
        x: 18.4021353169102,
        z: -8.403185660498263,
        elevation: 2.492261993170926
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "ca58b923-17e9-50da-aa46-9a9e816eac69",
      name: "HVAC-J-1",
      position: {
        levelIndex: 2,
        x: 18.430049735527625,
        z: -12.17262690076356,
        elevation: 2.534191823880203
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "ffc6c798-6162-5f6e-a0c0-c3fea1b2c5ba",
      name: "HVAC-R-6",
      position: {
        levelIndex: 2,
        x: 18.413090886764074,
        z: -15.87964219024739,
        elevation: 2.535947879800604
      },
      type: "HVAC",
      color: "#fddb61"
    }
  ]
};

export const LEVEL_2_INITIAL_STATE = {
  AQI: [
    {
      id: "80783ebb-ac0f-5550-ab25-b69a5f640da2-L2",
      name: "AQI-H-2",
      position: {
        levelIndex: 2,
        x: 13.823714794865381,
        z: -2.299076709055864,
        elevation: 2.487319080561802 - 0.7
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "10c70cbf-6a1a-5c00-b589-f971a1caf9ae-L2",
      name: "AQI-M-1",
      position: {
        levelIndex: 2,
        x: 19.11861917869944,
        z: -16.452221852100482,
        elevation: 2.4085457783952364 - 0.7
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "803491ca-dd59-5d88-ba0b-c4724f9cf5b7-L2",
      name: "AQI-T-3",
      position: {
        levelIndex: 2,
        x: 20.25811546996622,
        z: -8.949978568775496,
        elevation: 2.420649286913042 - 0.7
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "df4a7c38-cbcf-531b-8b71-27315773d467-L2",
      name: "AQI-M-4",
      position: {
        levelIndex: 2,
        x: 20.232077740206055,
        z: -12.7365196845522,
        elevation: 2.5049295557678093 - 0.7
      },
      type: "AQI",
      color: "#f56542"
    },
    {
      id: "b20f193d-0e2b-5c3d-93bd-a4ac105ef57e-L2",
      name: "AQI-O-2",
      position: {
        levelIndex: 2,
        x: 20.21085489677107,
        z: -5.230800119661486,
        elevation: 2.413536674596868 - 0.7
      },
      type: "AQI",
      color: "#f56542"
    }
  ],
  BULBS: [],
  ENERGY_METERS: [
    {
      id: "4dc56c2d-ac42-51e9-af30-0ecccc142cfe-L2",
      name: "ENERGY_METERS-M-8",
      position: {
        levelIndex: 2,
        x: 19.07020849745776,
        z: -20.21031642663154,
        elevation: 2.28094171377432 - 0.7
      },
      type: "ENERGY_METERS",
      color: "#81fd66"
    }
  ],
  HVAC: [
    {
      id: "60d0fd4f-4178-53ce-9553-2e4199977312-L2",
      name: "HVAC-N-9",
      position: {
        levelIndex: 2,
        x: 13.766807121622245,
        z: -18.557528677367504,
        elevation: 2.3487870655971648 - 0.7
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "40c194a8-3ec7-5b8d-8c9a-eaa6df47f587-L2",
      name: "HVAC-V-8",
      position: {
        levelIndex: 2,
        x: 18.4021353169102,
        z: -8.403185660498263,
        elevation: 2.492261993170926 - 0.7
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "ca58b923-17e9-50da-aa46-9a9e816eac69-L2",
      name: "HVAC-J-1",
      position: {
        levelIndex: 2,
        x: 18.430049735527625,
        z: -12.17262690076356,
        elevation: 2.534191823880203 - 0.7
      },
      type: "HVAC",
      color: "#fddb61"
    },
    {
      id: "ffc6c798-6162-5f6e-a0c0-c3fea1b2c5ba-L2",
      name: "HVAC-R-6",
      position: {
        levelIndex: 2,
        x: 18.413090886764074,
        z: -15.87964219024739,
        elevation: 2.535947879800604 - 0.7
      },
      type: "HVAC",
      color: "#fddb61"
    }
  ]
};

export const avgConsumptionHvac = [
  {
    date: "May 28, 01:10 AM",
    "Power Consumption": 3013
  },
  {
    date: "May 28, 03:14 AM",
    "Power Consumption": 3210
  },
  {
    date: "May 28, 04:33 AM",
    "Power Consumption": 3102
  },
  {
    date: "May 28, 12:10 PM",
    "Power Consumption": 3100
  },
  {
    date: "May 28, 02:31 AM",
    "Power Consumption": 3142
  },
  {
    date: "May 28, 03:30 AM",
    "Power Consumption": 3099
  }
];

export const powerCostLineData = [
  {
    date: "Jan 22",
    PowerUsage: 124, // kWh
    Cost: 124 * 0.12 * 82 // INR
  },
  {
    date: "Feb 22",
    PowerUsage: 130,
    Cost: 130 * 0.12 * 82
  },
  {
    date: "Mar 22",
    PowerUsage: 118,
    Cost: 118 * 0.12 * 82
  },
  {
    date: "Apr 22",
    PowerUsage: 125,
    Cost: 125 * 0.12 * 82
  },
  {
    date: "May 22",
    PowerUsage: 120,
    Cost: 120 * 0.12 * 82
  },
  {
    date: "Jun 22",
    PowerUsage: 128,
    Cost: 128 * 0.12 * 82
  },
  {
    date: "Jul 22",
    PowerUsage: 132,
    Cost: 132 * 0.12 * 82
  },
  {
    date: "Aug 22",
    PowerUsage: 121,
    Cost: 121 * 0.12 * 82
  },
  {
    date: "Sep 22",
    PowerUsage: 127,
    Cost: 127 * 0.12 * 82
  },
  {
    date: "Oct 22",
    PowerUsage: 119,
    Cost: 119 * 0.12 * 82
  },
  {
    date: "Nov 22",
    PowerUsage: 135,
    Cost: 135 * 0.12 * 82
  },
  {
    date: "Dec 22",
    PowerUsage: 130,
    Cost: 130 * 0.12 * 82
  }
];
