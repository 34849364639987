import {
  ArrowLeftIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ArrowsPointingInIcon,
  CheckIcon,
  CodeBracketSquareIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  Connection,
  Edge,
  Node,
  Background,
  BackgroundVariant,
  ControlButton,
  useNodesState,
  useEdgesState
} from "reactflow";
import "reactflow/dist/style.css";
import useUndoable from "use-undoable";

import "./styles.css";
import Sidebar from "./sidebar-component";
import { NodeType, nodeTypes } from "./node-types.helper";
import AdditionalDataSiddebar from "./additional-data-sidebar.component";
import {
  HTTPResponse,
  getEdgeId,
  getId,
  serializeRuleJSON
} from "./rule-engine.helper";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import { Button } from "@tremor/react";
import { toast } from "react-toastify";
import { useCreateRule } from "@app/shared/hooks/post/create-rule";
import { useUpdateRule } from "@app/shared/hooks/patch/update-rule";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import _ from "lodash";
import { Tooltip } from "react-tooltip";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import ButtonEdge from "./edges/delete-button-edge.component";
import HTTPTriggerFullPath from "./nodes/rule-trigger-type-node/http-trigger/http-trigger-full-path.component";
import { useUpdateTrigger } from "../shared/hooks/patch/update-triggers";
import { ICreateTriggerPayload } from "../shared/hooks/post/create-trigger";
import { useGetTriggers } from "../shared/hooks/get/triggers";
import {
  IHTTPTriggerFormState,
  ITrigger,
  TTriggerType
} from "@/interfaces/triggers.interface";

interface IPlaygroundProps {
  title?: string;
  ruleDetails?: {
    name: string;
    description: string;
    triggerType: TTriggerType;
    version?: number;
  };
  actionEditor?: boolean;
  actionData?: any;
  responseData?: Record<string, HTTPResponse>;
  fetchedRuleData?: any;
  setResponseData?: (responseData: Record<string, HTTPResponse>) => void;
  setEditAction?: (action: any) => void;
  setActionData?: (data: any) => void;
}

let initialNodes = [
  {
    id: getId(),
    type: NodeType.ruleEditorNode,
    data: {},
    position: { x: 250, y: 5 }
  }
];

let actionEditorInitialNodes = [
  {
    id: getId(),
    type: NodeType.actionEditorNode,
    data: {},
    position: { x: 250, y: 5 }
  }
];

const edgeTypes = {
  buttonEdge: ButtonEdge
};

const PlayGround: React.FC<IPlaygroundProps> = ({
  title = "",
  ruleDetails,
  actionData,
  actionEditor = false,
  responseData,
  fetchedRuleData,
  setResponseData,
  setEditAction,
  setActionData
}) => {
  const reactFlowWrapper = useRef(null);
  const lastRuleJSON = useRef(null);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>(null);
  const reactFlowInstanceRef = useRef<ReactFlowInstance>(null);
  const dataToSave = useRef<{
    title: string;
    flow: { nodes: any; edges: any };
    ruleData: any;
    actionData: any;
    responseData: any;
    ruleDetails: any;
    orgId: string;
    projId: string;
  }>();

  const navigate = useNavigate();
  const triggersQuery = useGetTriggers();

  const [ruleData, rules, triggerData, setRules] = useRuleEngineStore(
    (state) => [state.ruleData, state.rules, state.triggerData, state.setRules]
  );

  const [reactFlowData, setReactFlowData, clearLocalRuleData] =
    useRuleEngineStore((state) => [
      state.reactFlowData,
      state.setReactFlowData,

      state.clearLocalRuleData
    ]);

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const user = useAuthStore((state) => state.user);

  const [elements, setElements, { undo, canUndo, redo, canRedo }] =
    useUndoable<{ nodes: Node[]; edges: Edge[] }>(
      { nodes: [], edges: [] },
      {
        behavior: "destroyFuture",
        ignoreIdenticalMutations: true
      }
    );

  const consumeUndoRedo = useRef(false);

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const triggerUpdate = useCallback(
    (t, v, ignore = false) => {
      setElements(
        (e) => ({
          nodes: t === "nodes" ? v : e.nodes,
          edges: t === "edges" ? v : e.edges
        }),
        "destroyFuture",
        ignore
      );
    },
    [setElements]
  );

  const onNodesChange = useCallback(
    (changes) => {
      if (changes[0].type === "remove") {
        // handled in onNodesDelete
        return;
      }
      // don't save these changes in the history
      let ignore = ["select", "position", "dimensions"].includes(
        changes[0].type
      );

      setNodes((nodes) => applyNodeChanges(changes, nodes));

      triggerUpdate(
        "nodes",
        applyNodeChanges(changes, elements.nodes),
        ignore
      );
    },
    [setNodes, triggerUpdate, elements.nodes]
  );

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const deletedNode = deleted[0];

      switch (deletedNode.type) {
        case NodeType.actionSequenceNode:
          const _actionData = { ...actionData };
          delete _actionData[deletedNode.id];
          setActionData(_actionData);
          break;
        case (NodeType.ruleEditorNode, NodeType.actionEditorNode):
          return;
        default:
          break;
      }

      if (
        deletedNode.type === NodeType.ruleEditorNode ||
        deletedNode.type === NodeType.actionEditorNode
      ) {
        return;
      }

      const newNodes = elements.nodes.filter((n) => n.id !== deletedNode.id);

      const edges = elements.edges.filter((edge) => {
        return edge.target !== deletedNode.id;
      });

      setElements((s) => ({ edges: edges, nodes: newNodes }));

      setNodes(newNodes);
      setEdges(edges);
    },
    [
      actionData,
      elements.edges,
      elements.nodes,
      setActionData,
      setEdges,
      setElements,
      setNodes
    ]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      // don't save these changes in the history
      let ignore = ["select"].includes(changes[0].type);

      setElements(
        (e) => ({
          ...e,
          edges: applyEdgeChanges(changes, elements.edges)
        }),
        "destroyFuture",
        ignore
      );

      setEdges((edges) => applyEdgeChanges(changes, edges));
    },
    [setElements, setEdges, elements.edges]
  );

  useEffect(() => {
    let initialNodesArray = actionEditor
      ? actionEditorInitialNodes
      : initialNodes;

    let initialEdges = [];
    let initialRuleEditorEdges: Edge[] = [];

    if (!actionEditor && initialNodes.length === 1) {
      const newNodeId = getId();

      initialNodes.push({
        id: newNodeId,
        type: NodeType.ruleTriggerTypeNode,
        data: {
          name: ruleDetails.name
        },
        position: {
          x: ruleDetails.triggerType === "MQTT" ? -500 : -500,
          y: -50
        }
      });

      initialRuleEditorEdges.push({
        id: "trigger-type-node-edge",
        source: newNodeId,
        target: initialNodes[0].id,
        animated: true
      });
    }

    const foundLocalNodesData =
      reactFlowData !== null && reactFlowData[title] && !actionEditor;
    if (foundLocalNodesData) {
      const flow = reactFlowData[title];

      if (flow) {
        initialNodesArray = flow.nodes || initialNodesArray;
        initialEdges =
          flow.edges || (actionEditor ? [] : initialRuleEditorEdges);
      }
    }
    const newNodes = initialNodesArray.map((node) => {
      switch (node.type) {
        case NodeType.actionEditorNode:
          return {
            ...node,
            data: {
              ...node.data,
              setActionData,
              actionData
            }
          };

        case NodeType.ruleEditorNode:
          return {
            ...node,
            data: {
              ...node.data,
              fetchedRuleData,
              foundLocalNodesData,
              title,
              setElements,
              setNodes,
              setEdges,
              setEditAction
            }
          };

        case NodeType.actionSequenceNode:
          return {
            ...node,
            data: {
              ...node.data,
              setEditAction,
              setActionData
            }
          };

        case NodeType.httpResponseNode:
          return {
            ...node,
            data: {
              ...node.data,
              setResponseData
            }
          };

        default:
          return node;
      }
    });

    setElements(
      {
        nodes: newNodes,
        edges: !foundLocalNodesData ? initialRuleEditorEdges : initialEdges
      },
      "destroyFuture",
      true
    );

    setNodes(newNodes);
    setEdges(!foundLocalNodesData ? initialRuleEditorEdges : initialEdges);

    return () => {
      setElements(
        {
          nodes: [],
          edges: []
        },
        "destroyFuture",
        true
      );
      setNodes([]);
      setEdges([]);

      initialNodes = [
        {
          id: getId(),
          type: NodeType.ruleEditorNode,
          data: {},
          position: { x: 250, y: 5 }
        }
      ];
    };
  }, []);

  const onConnect = useCallback(
    (connection: Connection) => {
      const newEdge: Edge = {
        ...connection,
        id: getEdgeId(),
        type: "buttonEdge"
      };

      if (connection.sourceHandle.startsWith("conditions")) {
        if (connection.sourceHandle.endsWith("true")) {
          newEdge.style = { stroke: "green" };
        } else if (connection.sourceHandle.endsWith("false")) {
          newEdge.style = { stroke: "red" };
        }
      }
      setElements((e) => ({
        ...e,
        edges: addEdge(newEdge, e.edges)
      }));

      setEdges((edges) => addEdge(newEdge, edges));
    },
    [setEdges, setElements]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      const newNodeId = getId();
      const data: any = { label: `${type} node`, id: newNodeId };

      switch (type) {
        case NodeType.actionSequenceNode:
          data.setEditAction = setEditAction;
          data.actionData = {
            [newNodeId]: {}
          };
          data.setActionData = setActionData;
          break;

        case NodeType.httpResponseNode:
          data.responseData = responseData;
          data.setResponseData = setResponseData;
          break;

        default:
          break;
      }

      const newNode = {
        id: newNodeId,
        type,
        position,
        data
      };

      setElements((state) => ({
        ...state,
        nodes: [...state.nodes, newNode]
      }));

      setNodes((nodes) => [...nodes, newNode]);
    },
    [
      reactFlowInstance,
      responseData,
      setActionData,
      setEditAction,
      setElements,
      setNodes,
      setResponseData
    ]
  );

  const saveReactFlow = useCallback(
    (_instance = null) => {
      if (
        (reactFlowInstance || _instance) &&
        !actionEditor &&
        dataToSave.current?.title
      ) {
        const flow = _instance
          ? _instance.toObject()
          : reactFlowInstance.toObject();

        setReactFlowData(
          dataToSave.current.title,
          flow,
          dataToSave.current.ruleData,
          dataToSave.current.actionData,
          dataToSave.current.responseData,
          dataToSave.current.ruleDetails,
          dataToSave.current.orgId,
          dataToSave.current.projId
        );
      }
    },
    [actionEditor, reactFlowInstance, setReactFlowData]
  );

  useEffect(() => {
    return () => {
      // save when nagivating outside the page
      saveReactFlow(reactFlowInstanceRef.current);
    };
  }, [saveReactFlow]);

  useEffect(() => {
    if (!reactFlowInstanceRef.current) return;
    const flow = reactFlowInstanceRef.current.toObject();

    dataToSave.current = {
      title,
      flow,
      ruleData,
      actionData,
      responseData,
      ruleDetails,
      orgId: user.selectedOrg.id,
      projId: selectedProject.id
    };
  }, [
    nodes,
    edges,
    actionData,
    responseData,
    ruleData,
    ruleDetails,
    selectedProject.id,
    title,
    user.selectedOrg.id
  ]);

  const onCloseClick = () => {
    saveReactFlow();
    navigate("/rule-engine");
  };

  const createRuleMutation = useCreateRule();
  const updateRuleMutation = useUpdateRule();

  const updateTriggerMutation = useUpdateTrigger();

  const onCreateRuleCLick = useCallback(() => {
    const ruleDetails = rules[title];

    let gaveError = false;

    // check if actions that have not been run once exist:
    Object.keys(actionData).forEach((actionSeqNodeId) => {
      if (gaveError) {
        return;
      }
      Object.keys(actionData[actionSeqNodeId]).forEach((actionNodeId) => {
        if (
          !gaveError &&
          !("action_id" in actionData[actionSeqNodeId][actionNodeId])
        ) {
          // TODO: highlight Action Sequence Node which has errors
          toast.error(
            "Some of the action sequence node has actions that have not been run once. Please run them first!"
          );
          gaveError = true;
          return;
        }
      });
    });

    switch (ruleDetails.triggerType) {
      case "HTTP":
        // check if there's a connection for all condition false handles with http response node for HTTP Rule
        ruleData.conditions.forEach((cond, ind) => {
          if (gaveError) return;

          const edge = elements.edges.find(
            (e) =>
              e.sourceHandle === `conditions-${ind}-false` &&
              e.targetHandle.startsWith("http-response")
          );

          if (!edge) {
            toast.error(
              `Condition ${
                ind + 1
              } does not have a false reponse. Please add an HTTP Response Node and attach it to it's false output.`
            );
            gaveError = true;
            return;
          }

          const nodeResponseData = responseData[edge.target];

          if (
            !nodeResponseData ||
            Number.isNaN(nodeResponseData.status_code)
          ) {
            toast.error(
              `HTTP Response Node attached to Condition ${
                ind + 1
              } does not have a status code. Please add a valid HTTP status code.`
            );
            gaveError = true;
            return;
          }
        });

        if (!ruleDetails.triggerId) {
          toast.error(
            "Please create a new trigger for this rule or select an existing one!"
          );
          gaveError = true;
        }
        break;

      case "LOCATION":
        if (!ruleDetails.triggerId) {
          toast.error("Please create a new trigger for this rule");
          gaveError = true;
        }
        break;

      default:
        break;
    }

    if (gaveError) {
      return;
    }

    // // save react flow for newly created rules which haven't been initialized in localRulesData / reactFlowData (nodes, edges)
    // saveReactFlow(reactFlowInstanceRef.current);

    // Clear local rule data so that fetched data from the server is set when the rule is run.
    // As some params are set from the server side when a rule is created.
    clearLocalRuleData(ruleDetails.name);

    const ruleJSON = serializeRuleJSON(
      ruleDetails,
      ruleData,
      actionData,
      responseData,
      elements.edges
    );

    if (!lastRuleJSON.current) {
      // pass.. set it when create/update rule is a success
    } else {
      if (_.isEqual(ruleJSON, lastRuleJSON.current)) {
        toast.success("Updated Rule Successfully!");
        return;
      }
    }

    const selectedTrigger = triggersQuery.data?.find(
      (t) => t.id === ruleDetails.triggerId
    );

    const updateTriggerPayload: ICreateTriggerPayload = {
      ...(selectedTrigger || ({} as ITrigger)),
      rule_id: ruleDetails.id,
      definition: selectedTrigger?.definition,
      trigger_name: selectedTrigger?.name,
      trigger_description: selectedTrigger?.description
    };

    if (!ruleDetails?.id) {
      createRuleMutation.mutate(ruleJSON, {
        onSuccess: (id) => {
          if (
            ruleDetails.triggerType === "HTTP" ||
            ruleDetails.triggerType === "LOCATION"
          ) {
            updateTriggerMutation.mutate({
              id: ruleDetails.triggerId,
              data: { ...updateTriggerPayload, rule_id: id }
            });
          }
          setRules((rules) => {
            const newRules = { ...rules };
            newRules[title] = {
              ...newRules[title],
              id
            };
            return newRules;
          });

          lastRuleJSON.current = ruleJSON;

          toast.success(
            <>
              Created Rule Successfully!
              {ruleDetails.triggerType === "MQTT" ? (
                <>
                  <br /> Note: MQTT Rules take about 2-3 mins to create. <br />
                  Hang tight.
                </>
              ) : null}
              `
            </>
          );
          navigate(`/rule-engine/${id}`, { replace: true });
        }
      });
    } else {
      updateRuleMutation.mutate(
        { data: ruleJSON, ruleId: ruleDetails.id },
        {
          onSuccess: (ok) => {
            if (ok) {
              lastRuleJSON.current = ruleJSON;
              toast.success("Updated Rule Successfully!");
            }
          }
        }
      );
    }
  }, [
    actionData,
    clearLocalRuleData,
    createRuleMutation,
    elements.edges,
    navigate,
    responseData,
    ruleData,
    rules,
    setRules,
    title,
    triggersQuery.data,
    updateRuleMutation,
    updateTriggerMutation
  ]);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => {
        if (n.type === NodeType.ruleEditorNode) {
          return {
            ...n,
            data: {
              ...n.data,
              fetchedRuleData
            }
          };
        }

        return n;
      })
    );
  }, [fetchedRuleData, setNodes]);

  useBeforeUnload(
    useCallback(() => {
      saveReactFlow();
    }, [saveReactFlow])
  );

  const onUndoClick = () => {
    undo();
    consumeUndoRedo.current = true;
  };

  const onRedoClick = () => {
    redo();
    consumeUndoRedo.current = true;
  };

  useEffect(() => {
    if (consumeUndoRedo.current) {
      setNodes(elements.nodes);
      setEdges(elements.edges);
      consumeUndoRedo.current = false;
    }
  }, [elements.nodes, elements.edges, setNodes, setEdges]);

  return (
    <div className="w-screen h-screen fixed z-50 top-0 left-0 bottom-0 right-0 bg-background text-contentColor">
      <div className="max-w-full flex flex-row py-2 px-4 lg:px-6 bg-background-layer1 items-center rounded-md h-20">
        {actionEditor ? (
          <ArrowLeftIcon
            width={20}
            className="text-contentColor cursor-pointer mr-4"
            onClick={() =>
              setEditAction({
                name: "",
                description: ""
              })
            }
          />
        ) : (
          <CodeBracketSquareIcon width={28} className="mr-2  !min-w-[28px]" />
        )}

        <span className="text-xl flex-none whitespace-nowrap font-semibold mr-2">
          {actionEditor ? "Action Editor:" : "Rule Editor:"}
        </span>
        <span
          className={`text-xl flex-none ${
            actionEditor ? "text-green-500" : "text-yellow-500"
          }`}
        >
          {title}
        </span>

        {!actionEditor ? (
          <>
            <div className="flex-none ml-2 h-8 py-1 border-l-2 border-background-layer3" />

            <div className="ml-4 flex-none">
              <Button
                color="green"
                loading={
                  createRuleMutation.isLoading ||
                  updateRuleMutation.isLoading ||
                  triggersQuery.isLoading
                }
                size="xs"
                icon={rules[title]?.id ? CheckIcon : PlusIcon}
                onClick={onCreateRuleCLick}
              >
                {rules[title]?.id ? "Update Rule" : "Create Rule"}
              </Button>
            </div>

            {ruleDetails.triggerType === "HTTP" ? (
              <>
                <div className="flex-none ml-4 h-8 pl-4 py-1 border-l-2 border-background-layer3" />
                <HTTPTriggerFullPath
                  path={
                    (triggerData?.[title] as IHTTPTriggerFormState)
                      ?.pathPattern ?? ""
                  }
                  triggerName={
                    (triggerData?.[title] as IHTTPTriggerFormState)?.name
                  }
                />
              </>
            ) : null}

            <XMarkIcon
              width={20}
              className="ml-auto text-contentColor !min-w-[20px]  cursor-pointer"
              onClick={onCloseClick}
            />
          </>
        ) : null}
      </div>
      <div className="dndflow w-full h-full">
        <ReactFlowProvider>
          {!actionEditor ? (
            <Sidebar triggerType={ruleDetails.triggerType} />
          ) : null}
          <div
            id={"reactflow-wrapper-" + title}
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              // zoomOnScroll={false}
              // zoomActivationKeyCode={"Meta"}
              edgeTypes={edgeTypes}
              edges={edges}
              onNodesChange={onNodesChange}
              onNodesDelete={onNodesDelete}
              onEdgesChange={onEdgesChange}
              deleteKeyCode={""}
              onConnect={onConnect}
              onInit={(instance) => {
                reactFlowInstanceRef.current = instance;
                setReactFlowInstance(instance);
              }}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
            >
              <Background
                className="text-contentColorLight"
                variant={BackgroundVariant.Dots}
              />
              <Controls
                position="bottom-center"
                showZoom={false}
                showFitView={false}
                showInteractive={false}
                className=" flex flex-row mb-28 bg-background border border-background-layer3 !text-contentColor"
              >
                <ControlButton
                  onClick={() => reactFlowInstance.zoomIn()}
                  className="text-contentColor border-background-layer2 hover:bg-background-layer1 disabled:opacity-50 "
                >
                  <PlusIcon width={14} />
                </ControlButton>

                <ControlButton
                  onClick={() => reactFlowInstance.zoomOut()}
                  className="text-contentColor border-background-layer2 hover:bg-background-layer1 disabled:opacity-50 "
                >
                  <MinusIcon width={14} />
                </ControlButton>

                <ControlButton
                  onClick={() => reactFlowInstance.fitView()}
                  className="text-contentColor border-background-layer2 hover:bg-background-layer1 disabled:opacity-50 "
                >
                  <ArrowsPointingInIcon width={14} />
                </ControlButton>

                <ControlButton
                  disabled={!canUndo}
                  onClick={onUndoClick}
                  className="text-contentColor border-background-layer2 hover:bg-background-layer1 disabled:opacity-50 "
                >
                  <ArrowUturnLeftIcon width={14} />
                </ControlButton>

                <ControlButton
                  disabled={!canRedo}
                  onClick={onRedoClick}
                  className="text-contentColor border-background-layer2 hover:bg-background-layer1 disabled:opacity-50 "
                >
                  <ArrowUturnRightIcon width={14} />
                </ControlButton>
              </Controls>
            </ReactFlow>
          </div>

          {!actionEditor ? (
            <AdditionalDataSiddebar actionData={ruleData} />
          ) : (
            <AdditionalDataSiddebar actionData={actionData} />
          )}
        </ReactFlowProvider>
      </div>
      <Tooltip id="missing-nodes-tooltip">
        Cannot see your existing nodes? Try refreshing the page!
      </Tooltip>
    </div>
  );
};

export default PlayGround;
