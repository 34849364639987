import React, { useEffect, useState } from "react";
import BlueprintDetailsTabs, {
  BlueprintTabs
} from "./panel-blueprint-tabs.component";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import BlueprintDetails from "./tabs/panel-blueprint-details.component";
import { useGetBlueprints } from "@/app/shared/hooks/get/blueprints";
import ShowLoading from "@/app/shared/components/loading.component";
import BlueprintUsage from "./tabs/panel-blueprint-usage.component";
import { TBlueprintKind, IBlueprint } from "@/interfaces/blueprint.interface";
import DashBlueprintPreview from "../dashboards/tabs/dash-blueprint-preview.component";
import { useDashboardStore } from "@/store";

interface IBlueprintTabsRendererProps {
  blueprintKind: TBlueprintKind;
}

const BlueprintTabsRenderer: React.FC<IBlueprintTabsRendererProps> = ({
  blueprintKind
}) => {
  const location = useLocation();
  const [blueprint, setBlueprint] = useState<IBlueprint>(null);
  const [blueprintId] = useState(location.pathname.split("/")[3]);

  const setPanels = useDashboardStore((state) => state.setPanels);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") as BlueprintTabs;

  const { isLoading } = useGetBlueprints(
    {
      blueprint_id: blueprintId
    },
    (blueprints) => {
      if (!blueprints?.length) {
        toast.error(`Blueprint with ID ${blueprintId} not found.`);

        return;
      }

      const _blueprint = blueprints[0];
      setBlueprint(_blueprint);
    }
  );

  const [activeTab, setActiveTab] = useState<BlueprintTabs>(
    tab ?? BlueprintTabs.DETAILS
  );

  useEffect(() => {
    setSearchParams(
      { tab: activeTab ?? BlueprintTabs.DETAILS },
      { replace: true }
    );
  }, [activeTab, setSearchParams]);

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <BlueprintDetailsTabs
        blueprintKind={blueprintKind}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === BlueprintTabs.DETAILS && blueprint ? (
        <BlueprintDetails blueprint={blueprint} />
      ) : activeTab === BlueprintTabs.PREVIEW &&
        blueprint &&
        blueprintKind === "DASHBOARD" ? (
        <DashBlueprintPreview blueprint={blueprint} />
      ) : activeTab === BlueprintTabs.USAGE && blueprint ? (
        <BlueprintUsage blueprint={blueprint} />
      ) : null}
    </>
  );
};

export default BlueprintTabsRenderer;
