import Modal from "@/app/shared/components/modal.component";
import { Button, Callout } from "@tremor/react";
import React from "react";
import StyledField from "@/app/shared/components/styled-field.component";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type IHTTPTriggerApiKeyModalProps = {
  apiKey: string;
  open: boolean;
  setOpen: (val: boolean) => void;
};

const HTTPTriggerApiKeyModal: React.FC<IHTTPTriggerApiKeyModalProps> = ({
  apiKey,
  open,
  setOpen
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      disableClickOutside
      className="w-full max-w-lg"
    >
      <div className="flex flex-col gap-4 p-6  text-contentColor">
        <h1 className="text-lg font-bold">Rule API Key</h1>
      </div>
      <hr />

      <div className="p-4">
        <Callout
          title={"Warning"}
          icon={ExclamationTriangleIcon}
          color={"red"}
        >
          This is the only time you will be able to access this API Key. Make
          sure to copy and save it at a secure location.
        </Callout>

        <div className="mt-4 flex items-center">
          <StyledField disabled showCopyButton name="apiKey" value={apiKey} />
        </div>
      </div>

      <div className="flex gap-4 justify-end m-4 mt-0">
        <Button type="button" disabled={false} onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default HTTPTriggerApiKeyModal;
