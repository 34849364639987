import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IUpdateProjPropsPayload {
  project_id: string;
  connection_name: string;
  consumer_enable_signups?: boolean | null;
  consumer_metadata_key_perms: Array<{ key: string; perm: number }>;
  org_id: string;
}

interface IUpdateProjectPropsResponse {
  ok: number;
}

export const updateConsumerSettings = async (
  projectId: string,
  orgId: string,
  payload: any
) => {
  const { ok } = await networkService.patch<IUpdateProjectPropsResponse>(
    `projects/${projectId}/consumers/settings`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateConsumerSettings = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IUpdateProjPropsPayload) =>
      await updateConsumerSettings(
        selectedProject.id,
        user.selectedOrg.id,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjectProps");
      }
    }
  );

  return res;
};
