import { useGetProjectProps } from "@/app/shared/hooks/get/project-props";
import { EProjectPropType } from "@/interfaces/project.interface";

import { useUpdateProjectProps } from "@/app/shared/hooks/patch/update-project-props";
import EnableDeviceConnectionEvents from "@/app/shared/components/enable-device-connection-events.component";

function ProjectPolicies() {
  const { isLoading: projPropsLoading, data: projectProps } =
    useGetProjectProps();

  const connectionEventMutation = useUpdateProjectProps(
    EProjectPropType.TRACK_CONNECTION_EVENTS
  );

  return (
    <div className="mt-3 flex-1 flex-col flex flex-shrink max-w-sm">
      <EnableDeviceConnectionEvents
        className="mb-5 mt-1"
        connectionEventMutation={connectionEventMutation}
        properties={projectProps}
        propsLoading={projPropsLoading}
      />
    </div>
  );
}

export default ProjectPolicies;
