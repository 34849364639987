import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import GraphHeader from "./components/graphs/dash-graph-header.component";

import Gauge from "./components/gauge/gauge";
import { PieChart, LineChart, BarChart } from "./components/graphs";
import MapView from "./components/map/dash-map-component";
import TempMeter from "./components/gauge/temp-meter";
import NumericInfoGraph from "./components/graphs/dash-numeric-graph.component";
import HeatMap from "./components/graphs/heatmap.component";
import TableComponent from "./components/table/dash-table.component";
import dateService from "@/services/date.service";
import { TIMESTAMP_FORMAT } from "./dash-constants";
import { getHeatmapTransformedData } from "../shared/utils/dashboard.utils";
import { IDashboardPanel, IDevice, IMapDataResponse } from "@/interfaces";
import { SpaceViewer } from "./components/bms/bms-3d-space.component";
import { AreaChart } from "@tremor/react";
import { powerCostLineData } from "./components/bms/bms-helper";

interface IRenderPanelProps {
  panel: IDashboardPanel;
  panelData: any[] | IMapDataResponse;
  renderNoDataPanel: () => JSX.Element;
  onChangeBoundingBox: (boundingBox: any) => void;
  inCarousel?: boolean;
  boundingBox: any;
  geoMapDevicesWithShadows: IDevice[];
}

const bypassNoDataCheck = ["BMS3D"];

const RenderPanel: React.FC<IRenderPanelProps> = ({
  panel,
  panelData,
  renderNoDataPanel,
  onChangeBoundingBox,
  inCarousel,
  boundingBox,
  geoMapDevicesWithShadows
}) => {
  if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
    panelData = panelData as IMapDataResponse;

    return (
      <InnerPanelWrapper panel={panel}>
        <MapView
          devices={panelData?.devices || []}
          geoMapDevicesWithShadows={geoMapDevicesWithShadows}
          definition={panel.definition}
          clusters={panelData?.clusters || []}
          bounding_box={boundingBox}
          title={panel.title}
          key={panel.id}
          onChangeBoundingBox={onChangeBoundingBox}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  // Check for empty data (Geo Map response is an object)
  if (!panelData || (Array.isArray(panelData) && !panelData.length)) {
    if (bypassNoDataCheck.includes(panel.definition.panel_type)) {
      // pass
    } else {
      return renderNoDataPanel();
    }
  }

  panelData = panelData as any[];

  switch (panel.panel_type) {
    case DASH_PANEL_TYPE.PIE_CHART:
      let pieIndex = "name";

      return (
        <InnerPanelWrapper panel={panel}>
          <PieChart
            data={panelData}
            category={"count"}
            index={pieIndex}
            title={
              Object.hasOwn(panel.data_config, "source")
                ? pieIndex
                : panel.data_config["group"][0]
            }
            variant="donut"
            key={panel.id}
            colors={panel.definition.options.label_colors}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.GAUGE:
      if (panelData[0]?.current_value === null) {
        return renderNoDataPanel();
      }
      if (panel.definition.options["gauge-type"] === "v") {
        return (
          <InnerPanelWrapper panel={panel}>
            <TempMeter
              max={panel.definition.options.max_display}
              min={panel.definition.options.min_display}
              title={panel.title}
              minValue={panelData[0]?.current_value}
              maxValue={panelData[0]?.max_value}
              units={panel.definition.options.unit}
              key={panel.id}
              zoom={panel.definition.zoom}
            />
          </InnerPanelWrapper>
        );
      } else {
        return (
          <InnerPanelWrapper panel={panel}>
            <Gauge
              max={panel.definition.options.max_display}
              min={panel.definition.options.min_display}
              title={panel.title}
              minValue={panelData[0]?.current_value}
              maxValue={panelData[0]?.max_value}
              units={panel.definition.options.unit}
              key={panel.id}
              zoom={panel.definition.zoom}
            />
          </InnerPanelWrapper>
        );
      }

    case DASH_PANEL_TYPE.LINE_CHART:
      let lineCategories: string[], chartData: any, index: "value" | "time";

      let colors = panel.definition.options.label_colors;

      lineCategories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);
      chartData = panelData?.map((data) => ({
        ...data,
        time: dateService
          .convertUTCDateToUserTimezone(data.time)
          .format(TIMESTAMP_FORMAT)
      }));
      index = "time";

      return (
        <InnerPanelWrapper panel={panel}>
          <LineChart
            chartdata={chartData}
            categories={lineCategories}
            colors={colors}
            index={index}
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.BAR_CHART:
      let barCategories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);
      chartData = panelData?.map((data) => ({
        ...data,
        time: dateService
          .convertUTCDateToUserTimezone(data.time)
          .format(TIMESTAMP_FORMAT)
      }));

      return (
        <InnerPanelWrapper panel={panel}>
          <BarChart
            orientation={panel.definition.options.orientation}
            chartdata={chartData}
            categories={barCategories}
            colors={panel.definition.options.bar_colors}
            index={"time"}
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.NUMERIC:
      const resultantValue =
        panelData[0].value * (panel.definition.factor || 1);

      let minVal: number | null, maxVal: number | null;

      if (panelData[0].min) {
        minVal = panelData[0].min * (panel.definition.factor || 1);
      }

      if (panelData[0].max) {
        maxVal = panelData[0].max * (panel.definition.factor || 1);
      }

      return (
        <InnerPanelWrapper panel={panel}>
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={minVal ?? null}
            maxValue={maxVal ?? null}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.HEATMAP:
      const transformedData = getHeatmapTransformedData(panel, panelData);

      return (
        <InnerPanelWrapper panel={panel}>
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={transformedData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.TABLE:
      return (
        <InnerPanelWrapper panel={panel}>
          <TableComponent
            title={panel.title}
            data={panelData || []}
            panelColumnDefs={panel.definition.columnState}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );

    case DASH_PANEL_TYPE.GENERIC:
      switch (panel.definition.panel_type) {
        case "TABLE":
          return (
            <InnerPanelWrapper panel={panel}>
              <TableComponent
                title={panel.title}
                data={panelData || []}
                panelColumnDefs={panel.definition.columnState}
                zoom={panel.definition.zoom}
              />
            </InnerPanelWrapper>
          );

        case "BMS3D":
          return (
            <InnerPanelWrapper panel={panel}>
              <SpaceViewer />
            </InnerPanelWrapper>
          );
        case "COUNT":
          return (
            <InnerPanelWrapper panel={panel}>
              <NumericInfoGraph
                title={panel.title}
                info={(panel.definition as any).value ?? 0}
                unit={panel.definition.options?.display_unit}
                zoom={panel.definition.zoom}
                minValue={null}
                maxValue={null}
                hideArrow={true}
              />
            </InnerPanelWrapper>
          );

        case "POWER_COST":
          return (
            <InnerPanelWrapper panel={panel}>
              <div className="flex flex-col flex-grow h-full">
                <div className="px-4 pb-2 flex-grow h-full overflow-auto">
                  <AreaChart
                    className="h-80"
                    data={powerCostLineData}
                    index="date"
                    categories={["PowerUsage", "Cost"]}
                    colors={["green", "blue"]}
                    valueFormatter={(number) =>
                      `${Intl.NumberFormat("en-IN").format(number).toString()}`
                    }
                    yAxisWidth={80}
                    onValueChange={(v) => console.log(v)}
                  />
                </div>
              </div>
            </InnerPanelWrapper>
          );

        default:
          return renderNoDataPanel();
      }
    default:
      return renderNoDataPanel();
  }
};

const InnerPanelWrapper = ({ panel, children }) => {
  return (
    <div className={` w-full h-full bg-background rounded-md`}>
      <GraphHeader panel={panel} />
      <div
        className="h-[92%]"
        style={{
          zoom: panel.definition.zoom
            ? panel.definition.zoom.toString() + "%"
            : "100%"
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default RenderPanel;
