import { Edge } from "reactflow";
import { IRule } from "@store/rule-engine/rule-engine.store";
import { IServerQuery } from "../query-builder/query-builder.helper";
import {
  deserializeVariable,
  serializeVariable
} from "../shared/utils/helper.util";

export interface IRuleData {
  name?: string;
  description?: string;
  inputs?: IInput[];
  fetchedContexts?: IContext[];
  conditions?: { condition: string }[];
}

export interface IInput {
  key: string;
  type: string;
}

export type TContextType =
  | "device-shadow"
  | "device-metadata"
  | "device-data"
  | "user-metadata"
  | "user-context";
// | "device-location"
// | "device-health"
// | "fleet-shadow"
// | "fleet-data"

export interface IContext {
  key: string;
  type: TContextType;
  device_id: string;
  fleet_id: string;
  user_id: string;
  meta?: any;
  error?: boolean;
  shadow_definition_id?: string;
  filter?: IServerQuery;
}

export interface HTTPResponse {
  status_code: number;
  body: any;
}

interface Variable {
  kind: "device_id" | "fleet_id" | "user_id" | undefined;
  value: string;
}

export interface ICondition {
  statement: string;
  trueSequence?: string[];
  falseSequence?: string[];
  trueResponse?: string;
  falseResponse?: string;
}

export const contextFieldRequiredMap: Record<IContext["type"], string[]> = {
  "device-shadow": ["device_id", "key", "type"],
  "device-metadata": ["device_id", "key", "type"],
  "device-data": ["key", "type"],
  "user-metadata": ["key", "type"],
  "user-context": ["key", "type"]
  // "device-location": ["device_id", "key", "type"],
  // "device-health": ["device_id", "key", "type"],
  // "fleet-shadow": ["fleet_id", "key", "type"],
  // "fleet-data": ["fleet_id", "key", "type"],
};

export enum ActionType {
  HTTP = "HTTP",
  DEVICE_SHADOW = "DEVICE_SHADOW",
  USER_METADATA = "USER_METADATA",
  USER_ASSOC = "USER_ASSOC",
  USER_DIS_ASSOC = "USER_DIS_ASSOC",
  INSERT_DATA = "INSERT_DATA",
  FCMNotification = "FCMNotification"
}

export const getId = () => `dndnode_${Math.random()}`;
export const getEdgeId = () => `dndedge_${Math.random()}`;

export const extractVariableFromContext = (context: IContext) => {
  let variable: Variable = {
    kind: undefined,
    value: ""
  };

  const { device_id, fleet_id, user_id } = context;

  if (device_id) {
    variable.kind = "device_id";
    if (
      device_id.length > 4 &&
      device_id.startsWith("{{") &&
      device_id.endsWith("}}")
    ) {
      variable.value = device_id.substring(2, device_id.length - 2);
    }
  }

  if (user_id) {
    variable.kind = "user_id";
    if (
      user_id.length > 4 &&
      user_id.startsWith("{{") &&
      user_id.endsWith("}}")
    ) {
      variable.value = user_id.substring(2, user_id.length - 2);
    }
  }

  if (fleet_id) {
    variable.kind = "fleet_id";
    if (
      fleet_id.length > 4 &&
      fleet_id.startsWith("{{") &&
      fleet_id.endsWith("}}")
    ) {
      variable.value = fleet_id.substring(2, fleet_id.length - 2);
    }
  }

  return variable;
};

export function getDetailsFromHandleId(handleId: string) {
  const [_, conditionString, conditionType] = handleId.split("-");
  const conditionId = parseInt(conditionString);
  return { conditionId, conditionType };
}

export function deserializeFetchedContexts(fetchedContext: IContext[]) {
  return fetchedContext?.map((context) => ({
    device_id: deserializeVariable(context.device_id),
    user_id: deserializeVariable(context.user_id),
    fleet_id: deserializeVariable(context.fleet_id),
    shadow_definition_id: context.meta?.shadow_definition_id,
    filter: context.filter,
    key: context.key,
    type: context.type,
    error: false
  }));
}

export function deserializeRuleJSON(rule) {
  const ruleData = {
    inputs: Object.keys(rule.additional_params ?? {}).map((key) => ({
      key,
      type: rule.additional_params[key]
    })),
    fetchedContexts: deserializeFetchedContexts(rule.fetched_context ?? []),
    conditions: rule.definition.conditions
  };

  const _rule: IRule = {
    name: rule.name,
    description: rule.description,
    triggerType: rule.trigger_type,
    triggerId: rule.trigger_id,
    id: rule.id,
    version: rule.version,
    createdAt: rule.created_at,
    deletedAt: rule.deleted_at,
    active: rule.active
  };

  if (rule.trigger_type === "MQTT") {
    _rule["definition"] = { ...(rule.definition?.definition ?? {}) };
  }

  return { rule: _rule, ruleData };
}

export function serializeRuleJSON(
  ruleDetails: IRule,
  ruleData,
  actionData,
  responseData,
  edges: Edge[]
) {
  const ruleJSON = {
    name: ruleDetails.name,
    description: ruleDetails.description,
    trigger_type: ruleDetails.triggerType,

    additional_params: ruleData.inputs?.reduce((acc, cur) => {
      acc[cur.key] = cur.type;

      return acc;
    }, {}),

    fetched_context: ruleData.fetchedContexts?.map((context: IContext) => {
      const _context = {};

      contextFieldRequiredMap[context.type].forEach((key) => {
        if (key !== "key" && key !== "type") {
          _context[key] = serializeVariable(context[key]);
        } else {
          _context[key] = context[key];
        }
      });

      if (context.type === "user-metadata" && context.user_id.length) {
        _context["user_id"] = serializeVariable(context.user_id);
      }

      if (context.type === "device-shadow") {
        _context["meta"] = {};
        _context["meta"]["shadow_definition_id"] =
          context.shadow_definition_id;
      } else if (
        (context.type === "device-data" || context.type === "user-context") &&
        context.filter
      ) {
        _context["filter"] = context.filter;
      }

      return _context;
    }),

    conditions: ruleData.conditions?.map((condition, ind) => {
      const conditionObj = {
        condition_statement: condition.statement.replace(/{{(.*?)}}/g, "$1")
      };

      const false_responseEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-false` &&
          edge.targetHandle.startsWith("http-response-")
      );

      if (false_responseEdge) {
        const falseResData = responseData[false_responseEdge.target];

        conditionObj["false_response"] = falseResData;
      }

      const true_responseEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-true` &&
          edge.targetHandle.startsWith("http-response-")
      );

      if (true_responseEdge) {
        const trueResData = responseData[true_responseEdge.target];

        conditionObj["true_response"] = trueResData;
      }

      const false_sequenceEdge = edges.find(
        (edge) =>
          edge.targetHandle?.startsWith("action-sequence-") &&
          edge.sourceHandle === `conditions-${ind}-false`
      );

      if (false_sequenceEdge) {
        const _actionData = Object.keys(
          actionData[false_sequenceEdge.target]
        ).map((actionNodeId) => {
          const _actionData = {
            action_id:
              actionData[false_sequenceEdge.target][actionNodeId].action_id,
            additional_params: Object.keys(
              actionData[false_sequenceEdge.target][actionNodeId].inputValues
            ).reduce((acc, cur) => {
              acc[cur] = serializeVariable(
                actionData[false_sequenceEdge.target][actionNodeId]
                  .inputValues[cur]
              );
              return acc;
            }, {})
          };

          return _actionData;
        });

        conditionObj["false_sequence"] = _actionData;
      }

      const true_sequenceEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-true` &&
          edge.targetHandle.startsWith("action-sequence-")
      );

      if (true_sequenceEdge) {
        const _actionData = Object.keys(
          actionData[true_sequenceEdge.target]
        ).map((actionNodeId) => {
          const _actionData = {
            action_id:
              actionData[true_sequenceEdge.target][actionNodeId].action_id,
            additional_params: Object.keys(
              actionData[true_sequenceEdge.target][actionNodeId].inputValues ||
                {}
            ).reduce((acc, cur) => {
              acc[cur] = serializeVariable(
                actionData[true_sequenceEdge.target][actionNodeId].inputValues[
                  cur
                ]
              );
              return acc;
            }, {})
          };

          return _actionData;
        });

        conditionObj["true_sequence"] = _actionData;
      }

      return conditionObj;
    })
  };

  if (ruleDetails.triggerType === "MQTT") {
    ruleJSON["definition"] = {
      ...ruleDetails.definition
    };
  }

  return ruleJSON;
}

export function validateVariable(
  contexts: IContext[],
  inputs: IInput[],
  varToValidate: string,
  shadowDefs: any[] = []
) {
  let err = false,
    foundInContext = false;
  const varSplit = varToValidate.split(".");

  contexts.forEach((c) => {
    if (foundInContext) {
      return;
    }

    switch (c.type) {
      case "device-shadow":
        if (varSplit.length > 1) {
          if (varSplit[0] === c.key) {
            foundInContext = true;

            const selectedShadowDef = shadowDefs.find(
              (s) => s.id === c.shadow_definition_id
            );

            if (!selectedShadowDef) {
              console.error(
                `Shadow Def ID in context ${c.key} does not exist.`
              );
              // err = true;
              return;
            }

            const shadowProto = selectedShadowDef?.shadow_proto_structure;

            let currentLevel = shadowProto;

            for (let i = 1; i < varSplit.length; i++) {
              const currentVarPart = varSplit[i];

              if (currentLevel.hasOwnProperty(currentVarPart)) {
                // Move to the next level in the structure
                currentLevel = currentLevel[currentVarPart].structure;

                // If the current level is a leaf node (no more nested structure), exit the loop
                if (!currentLevel) {
                  break;
                }
              } else {
                // The current variable part is not found in the structure
                err = true;
              }
            }
          }
        } else {
          foundInContext = c.key === varToValidate;
        }
        break;

      default:
        if (varSplit.length > 1) {
          foundInContext = c.key === varSplit[0];
          return foundInContext;
        } else {
          foundInContext = c.key === varToValidate;
        }
    }
  });

  if (!foundInContext) {
    return inputs?.some((input) => {
      if (varSplit.length > 1 && input.type === "struct") {
        return input.key === varSplit[0];
      } else {
        return input.key === varToValidate;
      }
    });
  } else {
    return !err;
  }
}
