import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

// Define the payload type for linking a data point
interface ILinkDataPointPayload {
  data_point_name: string;
  device_data_policy_id: string;
  data_point_definition_id: string;
  active: boolean;
}

export const linkDataPoint = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  payload: ILinkDataPointPayload
) => {
  const { ok } = await networkService.post<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/data_points`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
        // Add any other necessary headers here
      }
    }
  );

  return ok;
};

export const useLinkDataPoint = (fleetId, deviceId) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ILinkDataPointPayload) =>
      await linkDataPoint(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getDeviceDataPoints"]);
        queryClient.invalidateQueries(["getDataPointDefinitions"]);
        queryClient.invalidateQueries(["getDeviceDataPolicies"]);
      }
    }
  );

  return res;
};
