import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import { IServerQuery } from "@/app/query-builder/query-builder.helper";

interface ICreatePanelBPDefinition {
  panel_id: string;
  title: string;
  description: string;
  type: DASH_PANEL_TYPE;
  panel_definition: any;
  data_config: IServerQuery;
}

interface ICreateDashBPDefinition {}

export interface ICreateBlueprintPayload {
  name: string;
  kind: "PANEL" | "DASHBOARD";
  // TODO: ADD TYPE
  definition: any;
  meta_data?: string;
}

interface ICreateBlueprintResponse {
  data: { blueprintId: string };
  ok: number;
}

export const createDashPanelBlueprint = async (
  projectId: string,
  orgId: string,
  payload: ICreateBlueprintPayload
) => {
  const { data, ok } = await networkService.post<ICreateBlueprintResponse>(
    `projects/${projectId}/blueprints/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.blueprintId;
  } else return null;
};

export const useCreateDashPanelBlueprint = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateBlueprintPayload) =>
      await createDashPanelBlueprint(
        selectedProject.id,
        user.selectedOrg.id,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getBlueprints");
      }
    }
  );

  return res;
};
