import StyledField from "@/app/shared/components/styled-field.component";
import dateService from "@services/date.service";
import { Form, Formik } from "formik";
import React from "react";
import ApplyBlueprint from "../../components/apply-blueprint.component";
import { IBlueprint } from "@/interfaces/blueprint.interface";

interface IBlueprintDetailsProps {
  blueprint: IBlueprint;
}

const BlueprintDetails: React.FC<IBlueprintDetailsProps> = ({ blueprint }) => {
  return (
    <div className="flex gap-8 mt-4 h-full flex-grow overflow-y-auto">
      <div className="w-[60%]">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="w-full ">
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Blueprint Name
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="blueprintName"
                    name="shadowId"
                    value={blueprint.name}
                    placeholder="Shadow ID"
                    showCopyButton
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Blueprint ID
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="blueprintId"
                    name="blueprintId"
                    value={blueprint.id}
                    placeholder="Shadow ID"
                    showCopyButton
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Created At
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="createdAt"
                    name="createdAt"
                    value={dateService.convertUTCToLocalDate(
                      blueprint.created_at
                    )}
                    placeholder="Created At"
                    showCopyButton
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      <div className="border-4 border-background-layer3 rounded mx-3"></div>
      <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
        <ApplyBlueprint blueprintIds={[blueprint.id]} />
      </div>
    </div>
  );
};

export default BlueprintDetails;
