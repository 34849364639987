import Modal from "@/app/shared/components/modal.component";
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Editor } from "@monaco-editor/react";
import { Button, Callout, Tab, TabGroup, TabList } from "@tremor/react";
import { inferSchema } from "@jsonhero/schema-infer";
import React, { useCallback, useEffect, useState } from "react";
import { Validator } from "jsonschema";

type IJSONSchemaModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  schema: string;
  setSchema: (val: string) => void;
};

const validator = new Validator();

const JSONSchemaModal: React.FC<IJSONSchemaModalProps> = ({
  open,
  setOpen,
  schema,
  setSchema
}) => {
  const [isInferringSchema, setIsInferringSchema] = useState(false);
  const [leftJSON, setLeftJSON] = useState(schema || "{}");
  const [rightJSON, setRightJSON] = useState("{}");

  const [callout, setCallout] = useState<
    | {
        title: string;
        msg: string | JSX.Element;
        color: string;
      }
    | undefined
  >();

  const [index, setIndex] = useState(isInferringSchema ? 0 : 1);

  useEffect(() => {
    if (open) {
      if (isInferringSchema) {
        setRightJSON(schema);
      } else {
        setLeftJSON(schema);
      }
    }
  }, [open]);

  const onInferClick = useCallback(() => {
    let json: any;
    try {
      json = JSON.parse(leftJSON);
      setCallout(undefined);

      const schemaObj = inferSchema(json).toJSONSchema();
      setRightJSON(JSON.stringify(schemaObj, undefined, 4));
    } catch (err) {
      setCallout({
        title: "Error",
        msg: "Invalid JSON, please make sure the JSON you provide to infer is valid!",
        color: "red"
      });
      console.error(err);
    }
  }, [leftJSON]);

  const onValidateClick = useCallback(async () => {
    try {
      const schemaJSON = JSON.parse(leftJSON);
      const toValidateJSON = JSON.parse(rightJSON);
      setCallout(undefined);

      const result = validator.validate(toValidateJSON, schemaJSON);

      if (result.valid) {
        setCallout({
          title: "Valid JSON",
          msg: "Provided JSON is valid and conforms to the given JSON Schema",
          color: "green"
        });
      } else {
        setCallout({
          title: "Invalid JSON",
          msg: (
            <>
              Provided JSON does not conform to the given JSON Schema.
              <br />
              <span className="font-bold"> Reason(s):</span>
              <ol>
                {result.errors.map((err, ind) => (
                  <li key={err.path.toString() + ind}>
                    <span>{ind + 1}. </span>
                    <span className="text-primary mr-1">
                      {err.property.replace("instance", "json")}
                    </span>
                    <span>{err.message}</span>
                  </li>
                ))}
              </ol>
            </>
          ),
          color: "red"
        });
      }
    } catch (err) {
      setCallout({
        title: "Error",
        msg: "Invalid JSON, please make sure both the JSON are valid!",
        color: "red"
      });
      console.error(err);
    }
  }, [leftJSON, rightJSON]);

  const onSaveClick = useCallback(() => {
    try {
      if (isInferringSchema) {
        JSON.parse(rightJSON);
        setSchema(rightJSON);
      } else {
        JSON.parse(leftJSON);
        setSchema(leftJSON);
      }
      setCallout(undefined);
      setOpen(false);
    } catch (err) {
      setCallout({
        title: "Error",
        msg: "Invalid JSON, please make sure the JSON schema is valid!",
        color: "red"
      });
      console.error(err);
    }
  }, [isInferringSchema, leftJSON, rightJSON, setOpen, setSchema]);

  return (
    <Modal open={open} setOpen={setOpen} className="w-full mx-12">
      <div className="flex flex-col gap-4 p-6  text-contentColor">
        <h1 className="text-lg font-bold">Edit JSON Schema</h1>
        <div className="flex justify-between">
          <h3 className="text-sm flex-grow">
            {isInferringSchema ? (
              <>
                Paste your JSON Body inside the left-hand side JSON Editor, and
                click the Infer button to auto generate JSON Schema. <br /> You
                can modify the the JSON Schema if needed, and hit save!
              </>
            ) : (
              <>
                Paste your JSON Schema inside the left-hand side JSON Editor.
                <br />
                Optionally, you can also paste any arbitrary JSON in the
                right-hand side JSON Editor to check if it conforms to the
                given schema.
              </>
            )}
          </h3>
          <TabGroup
            index={index}
            onIndexChange={(index) => {
              setIndex(index);
              setIsInferringSchema(index === 0 ? true : false);

              const _rightJSON = rightJSON;
              setRightJSON(leftJSON);
              setLeftJSON(_rightJSON);
            }}
            className="flex-shrink w-min"
          >
            <TabList defaultValue={"infer"} variant="solid">
              <Tab value={"infer"}>Infer JSON Schema</Tab>
              <Tab value={"edit"}>Edit JSON Schema</Tab>
            </TabList>
          </TabGroup>
        </div>
        {callout ? (
          <Callout title={callout.title} color={callout.color}>
            {callout.msg}
          </Callout>
        ) : null}
        <hr />
      </div>

      {/* <hr /> */}
      <div className="p-4 pt-0 flex gap-8 items-center justify-center overflow-auto">
        <div>
          <h3 className="text-base text-contentColor mb-2">
            {isInferringSchema
              ? "Paste your JSON Body here"
              : "Paste your JSON Schema here"}
          </h3>
          <Editor
            height="300px"
            width="500px"
            theme="vs-dark"
            language="json"
            value={leftJSON}
            onChange={setLeftJSON}
            options={{
              readOnly: false,
              lineNumbers: "off",
              minimap: {
                enabled: false
              }
            }}
          />
        </div>

        <Button
          onClick={isInferringSchema ? onInferClick : onValidateClick}
          icon={isInferringSchema ? ArrowRightIcon : CheckIcon}
          iconPosition="right"
        >
          <span className="text-xl uppercase">
            {isInferringSchema ? "Infer" : "Validate"}
          </span>
        </Button>

        <div>
          <h3 className="text-base text-contentColor mb-2">
            {isInferringSchema ? "Inferred JSON Schema" : "Validate any JSON"}
            <span className="text-sm text-contentColorLight ml-2">
              (You can make changes here before saving)
            </span>
          </h3>
          <Editor
            height="300px"
            width="500px"
            theme="vs-dark"
            language="json"
            value={rightJSON}
            onChange={(val) => {
              setRightJSON(val);
            }}
            options={{
              readOnly: false,
              lineNumbers: "off",
              minimap: {
                enabled: false
              }
            }}
          />
        </div>
      </div>

      <div className="flex gap-4 justify-center mb-4">
        <Button
          type="button"
          variant="secondary"
          // className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          disabled={false}
          // className="bg-green-500 text-contentColor disabled:text-gray-300 disabled:cursor-not-allowed px-2 py-1 rounded-sm"
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default JSONSchemaModal;
