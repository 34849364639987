export enum EDeviceDataInputFormat {
  PROTOBUF = "PROTOBUF",
  JSON = "JSON",
  CSV = "CSV"
  // CSV_HEADER = "CSV_HEADER", // CSV with header
  // XML = "XML"
}

export enum EDeviceDataInputCompression {
  NONE = "NONE",
  GZIP = "GZIP",
  ZLIB = "ZLIB",
  BZIP = "BZIP",
  LZ4 = "LZ4"
}

export interface IDeviceDataPolicy {
  v: number;
  "storage-format": string;
  input_format: EDeviceDataInputFormat;
  input_compression: EDeviceDataInputCompression;
}
