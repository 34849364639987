import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export interface IDeviceMetaDataResponse {
  ok: 1;
  data: {
    metaData: any;
  };
}

export const getDeviceMetaData = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  params
) => {
  const { data, ok } = await networkService.get<IDeviceMetaDataResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/meta`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.metaData;
  } else return null;
};

export const useGetDeviceMetaData = (
  fleetId: string,
  deviceId: string,
  params: any = {},
  successCb: ((data: any) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getDeviceMetaData",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      deviceId,
      params
    ],
    async () => {
      const deviceMetaData = await getDeviceMetaData(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        params
      );

      successCb && successCb(deviceMetaData);

      return deviceMetaData;
    },
    {
      enabled:
        !!selectedProject.id &&
        !!user.selectedOrg.id &&
        !!fleetId &&
        !!deviceId,
      onError: (err) => console.error(err)
    }
  );

  return res;
};
