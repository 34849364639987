import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IFleet } from "@interfaces/index";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
  RectangleGroupIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Device } from "@app/shared/icons";
import { useDeleteFleet } from "@app/shared/hooks/delete/delete-fleet";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { Badge } from "@tremor/react";
import dateService from "@/services/date.service";

function FleetHeader({ baseURL, showCreate, showRefresh, showDelete }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const params = useParams();
  const deviceId = params["device_id"];

  const [user] = useAuthStore((state) => [state.user]);
  const [
    fleets,
    selectedDevice,
    selectedFleet,
    selectedProject,
    setFleets,
    setSelectedFleet
  ] = useFleetAndDevicesStore((state) => [
    state.fleets,
    state.selectedDevice,
    state.selectedFleet,
    state.selectedProject,
    state.setFleets,
    state.setSelectedFleet
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  useGetFleets({}, (fleets) => {
    setFleets(fleets);
  });

  useEffect(() => {
    if (fleets?.length) {
      if (
        !selectedFleet.id ||
        selectedFleet.project_id !== selectedProject.id ||
        selectedFleet.org_id !== user.selectedOrg.id
      ) {
        if (
          fleets[0].project_id === selectedProject.id &&
          fleets[0].org_id === user.selectedOrg.id
        ) {
          // only change after new fleets have been fetched yet.
          setSelectedFleet(fleets[0]);
        }
      }
    } else {
      setSelectedFleet({} as IFleet);
    }
  }, [
    fleets,
    selectedFleet.id,
    selectedFleet.org_id,
    selectedFleet.project_id,
    selectedProject.id,
    setSelectedFleet,
    user.selectedOrg.id
  ]);

  const handleFleetSelection = (fleetId) => {
    const selectedFleet = fleets.find((f) => f.id === fleetId);
    setSelectedFleet(selectedFleet);

    queryClient.invalidateQueries({ queryKey: ["getDevices"] });
    navigate(baseURL);
  };

  const deleteFleetMutation = useDeleteFleet();

  const deleteFleet = async () => {
    deleteFleetMutation.mutate(selectedFleet.id, {
      onSuccess: () => {
        toast.success("Deleted fleet successfully!");
        setSelectedFleet(fleets.length ? fleets[0] : ({} as IFleet));
        navigate("/fleet-and-devices/projects");
      }
    });
  };

  return (
    <>
      <div className="bg-background text-contentColor border-b border-b-background-layer3 lg:px-6 sm:px-6 ">
        <div className="flex justify-between items-center py-3">
          <div className="flex flex-col">
            <div className="flex items-center mb-3">
              <TransitionedMenu
                buttonComponent={
                  <div className="flex gap-6 text-lg font-medium hover:bg-background-layer1 text-contentColor px-2 rounded-md focus:ring-1 focus:ring-offset-2 focus:ring-offset-background-layer2 focus:ring-primary">
                    {selectedFleet?.fleet_name || "Select Fleet"}
                    <ChevronDownIcon width={18} />
                  </div>
                }
              >
                {fleets &&
                  fleets.map((fleet) => (
                    <Menu.Item key={fleet.id}>
                      {({ active }) => (
                        <button
                          onClick={() => handleFleetSelection(fleet.id)}
                          className={`w-full flex gap-1 items-center ${
                            fleet.id === selectedFleet.id &&
                            "bg-background-layer2"
                          } min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                        >
                          {fleet.id === selectedFleet.id && (
                            <CheckIcon width={14} />
                          )}
                          {fleet.fleet_name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
              </TransitionedMenu>
            </div>
            <div className="ml-2">
              <p className="text-sm text-contentColorLight">
                {`Fleet & Devices  >`}&nbsp;
                <Link to="/fleet-and-devices/projects">
                  <span className="inline-flex items-center gap-1">
                    <span>Fleet</span>
                    <span>{selectedFleet?.fleet_name}</span>
                  </span>
                  &nbsp;
                </Link>
                {deviceId ? `> ${selectedDevice?.device_name}` : ""}
              </p>
            </div>
          </div>
          {selectedDevice && deviceId ? (
            selectedDevice.connected === null ? (
              <Badge data-tooltip-content="" size="lg" color="yellow">
                {"Never Connected"}
              </Badge>
            ) : selectedDevice.connected === false ? (
              <Badge
                data-tooltip-id="disconnected-tooltip"
                data-tooltip-place="bottom"
                data-tooltip-content={`Last seen:
                ${dateService.convertUTCToLocalDate(
                  selectedDevice.last_seen
                )}`}
                size="lg"
                color="red"
              >
                {"Disconnected"}
              </Badge>
            ) : (
              <Badge data-tooltip-content="" size="lg" color="green">
                {"Connected"}
              </Badge>
            )
          ) : null}
          <ul className="flex justify-center items-center">
            {showRefresh && (
              <li className="ml-10 mr-8 items-center cursor-pointer">
                <button
                  onClick={() => window.location.reload()}
                  className="ml-4 mx-1 text-sm flex justify-center items-center"
                >
                  <ArrowPathIcon width={14} />

                  <span className="pl-2 text-sm">Refresh</span>
                </button>
              </li>
            )}
            {showDelete && fleets.length > 0 && (
              <li className="mr-8 flex items-center cursor-pointer">
                <button
                  onClick={() =>
                    openConfirmDeleteModal(
                      deleteFleet,
                      "Are you sure you want to delete this fleet?"
                    )
                  }
                  className="text-sm flex justify-center items-center"
                >
                  <TrashIcon width={14} />
                  <span className="pl-2 text-sm">Delete</span>
                </button>
              </li>
            )}
            {showCreate && (
              <TransitionedMenu
                menuPosition="right"
                buttonComponent={
                  <div className="flex gap-4 text-base bg-primary text-white px-2 py-2 rounded-md">
                    <span className="flex gap-1">
                      <PlusIcon width={18} /> Create
                    </span>
                    <ChevronDownIcon width={18} />
                  </div>
                }
              >
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/fleet-and-devices/projects/about-device"
                      className={`w-full flex gap-2 items-center min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                    >
                      <Device width={14} /> New Device
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/fleet-and-devices/projects/about-fleet"
                      className={`w-full flex gap-2 items-center min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                    >
                      <RectangleGroupIcon width={16} /> New Fleet
                    </Link>
                  )}
                </Menu.Item>
              </TransitionedMenu>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default FleetHeader;
