import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Field, Form, Formik } from "formik";

import { toast } from "react-toastify";
import { FieldError } from "../../shared/components";
import { object, string } from "yup";
import {
  IUserContextDefinition,
  UserContextMessageType
} from "@interfaces/user-contexts.interface";
import ReactSelect from "react-select";
import CreateSelect from "react-select/creatable";
import { Button } from "@tremor/react";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  ICreateContextPayload,
  useCreateUserContextDefinition
} from "@app/shared/hooks/post/create-user-context";

interface ICreateUserContextProps {
  context?: IUserContextDefinition;
}

interface ICreateContextValuesField {
  name: string;
  type: UserContextMessageType;
  required: boolean;
  partitioningField: boolean;
  values?: string[]; // Enum Values
  format?: UserContextMessageType; // For Array type
}

interface ICraeteContextValues {
  name: string;
  fields: Array<ICreateContextValuesField>;
  index: Array<Array<string>>;
}

interface IFieldError {
  values: boolean;
  format: boolean;
  name: boolean;
}

interface IErrors {
  name: boolean;
  partition: boolean;
  fields: Array<IFieldError>;
  index: Array<{
    empty: boolean;
  }>;
}

const newUserContextDefinitionSchema = object().shape({
  name: string().required("Please enter context name.")
});

const CreateEditUserContext: React.FC<ICreateUserContextProps> = ({
  context
}) => {
  const navigate = useNavigate();
  const createUserContextMutation = useCreateUserContextDefinition();

  const [createContextValues, setCreateContextValues] =
    useState<ICraeteContextValues>({ fields: [], name: "", index: [] });

  const [errors, setErrors] = useState<IErrors>({
    name: false,
    partition: false,
    fields: [],
    index: []
  });

  useEffect(() => {
    if (context) {
      const contextFormState: ICraeteContextValues = {
        name: context.name,
        fields: [],
        index: context.definition.index ?? []
      };

      const formErrors: IErrors = {
        name: false,
        index:
          context.definition.index?.map((indexes) => ({
            empty: indexes.length === 0
          })) || [],
        fields: [],
        partition: !context.definition.partition
      };

      Object.keys(context.definition.properties).forEach((property) => {
        const field: ICreateContextValuesField = {
          name: property,
          type: context.definition.properties[property].type,
          partitioningField: context.definition.partition === property,
          required: context.definition.required?.includes(property) ?? false,
          format: context.definition.properties[property].format,
          values: context.definition.properties[property].values
        };

        const fieldError: IFieldError = {
          format: false,
          name: false,
          values: false
        };

        formErrors.fields.push(fieldError);

        contextFormState.fields.push(field);
      });

      setCreateContextValues(contextFormState);
      setErrors(formErrors);
    }
  }, [context]);

  const handleChange = (key: string, value: any) => {
    let newInputs = { ...createContextValues };
    newInputs[key] = value;

    if (key === "name" && value) {
      setErrors({ ...errors, name: false });
    }

    setCreateContextValues(newInputs);
  };

  const isError = () => {
    let error = false;
    const nameError = !createContextValues.name.length;
    const partitionError = createContextValues.fields.every(
      (field) => !field.partitioningField
    );

    if (nameError) {
      error = true;
    }

    if (partitionError) {
      error = true;
    }

    setErrors((prev) => ({
      ...prev,
      name: nameError,
      partition: partitionError,
      fields: createContextValues.fields.map((field) => {
        const valuesError =
          field.type.toLowerCase() === UserContextMessageType.Enum &&
          field.values.length === 0;
        const formatError =
          field.type.toLowerCase() === UserContextMessageType.Array &&
          !field.format;

        const nameError = field.name.trim().length === 0;

        if (valuesError || formatError || nameError) {
          error = true;
        }

        return {
          values: valuesError,
          format: formatError,
          name: nameError
        };
      }),
      index: createContextValues.index.map((index) => {
        const emptyError = index?.length === 0;

        if (emptyError) {
          error = true;
        }

        return {
          empty: emptyError
        };
      })
    }));

    if (errors.fields.some((ef) => ef.format || ef.name || ef.values)) {
      error = true;
    }

    if (errors.index.some((index) => index.empty)) {
      error = true;
    }

    return error;
  };

  const submitUserContext = () => {
    if (isError()) {
      return;
    }

    const payload: ICreateContextPayload = {
      name: createContextValues.name,
      message_structure: {
        partition: createContextValues.fields.find((f) => f.partitioningField)
          .name,
        type: UserContextMessageType.Object,
        index: createContextValues.index,
        required: createContextValues.fields
          .filter((f) => f.required)
          .map((f) => f.name),
        properties: createContextValues.fields.reduce((acc, cur) => {
          acc[cur.name] = {
            type: cur.type,
            values: cur.values,
            format: cur.format
          };

          return acc;
        }, {})
      }
    };

    createUserContextMutation.mutate(payload, {
      onSuccess: (id) => {
        navigate("/definitions/contexts");
        toast.success("Created new User Context Successfully!");
      }
    });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12  pb-8">
          <div>
            {context ? null : (
              <h1 className="text-lg text-left font-medium mb-2.5">
                Create User Context
              </h1>
            )}

            <Formik
              initialValues={{ name: "", fields: [] }}
              validationSchema={newUserContextDefinitionSchema}
              onSubmit={submitUserContext}
            >
              <Form>
                <div className="form-group mb-5 lg:w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Context Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Context Name"
                    value={createContextValues.name}
                    onChange={(e) =>
                      handleChange("name", e.target.value?.trim())
                    }
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 border rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  {errors.name ? (
                    <FieldError message={"Name is required!"} />
                  ) : null}
                </div>

                <div className="form-group mb-5 lg:w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Fields:
                  </label>
                  <div
                    className={`flex flex-col gap-4 mt-2 p-4 border border-background-layer3`}
                  >
                    <div className="flex min-h-[30vh] flex-col gap-4 max-h-[40vh] overflow-y-auto">
                      {createContextValues.fields.length ? (
                        createContextValues.fields.map((field, ind) => (
                          <>
                            <div
                              className={`p-2 m-1 border border-background-layer3 ${
                                field.partitioningField
                                  ? "outline outline-primary"
                                  : ""
                              }`}
                            >
                              <div className="flex  gap-2  ">
                                <div className=" min-w-[60%]">
                                  <Field
                                    type="text"
                                    id={"field-name" + ind}
                                    name={"field-name" + ind}
                                    value={field.name}
                                    onChange={(e) => {
                                      const newFields = [
                                        ...createContextValues.fields
                                      ];
                                      newFields[ind] = {
                                        ...newFields[ind],
                                        name: e.target.value?.trim()
                                      };

                                      const newErrorFields = [
                                        ...errors.fields
                                      ];
                                      newErrorFields[ind] = {
                                        ...newErrorFields[ind],
                                        name: false
                                      };

                                      setErrors((prev) => ({
                                        ...prev,
                                        fields: newErrorFields
                                      }));

                                      setCreateContextValues((prev) => ({
                                        ...prev,
                                        fields: newFields
                                      }));
                                    }}
                                    placeholder="Field Name"
                                    className="block w-full bg-background text-contentColor border-background-layer3 border rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                  />
                                  {errors.fields[ind].name ? (
                                    <FieldError
                                      message={"Field Name is required!"}
                                    />
                                  ) : null}
                                </div>
                                <Field
                                  component={ReactSelect}
                                  id={"field-type" + ind}
                                  name={"field-type" + ind}
                                  placeholder="Field Type"
                                  options={(
                                    Object.keys(
                                      UserContextMessageType
                                    ) as Array<
                                      keyof typeof UserContextMessageType
                                    >
                                  ).map((type) => ({
                                    label: type,
                                    value: type
                                  }))}
                                  value={{
                                    label: field.type,
                                    value: UserContextMessageType[field.type]
                                  }}
                                  onChange={(opt: IOption) => {
                                    const newFields = [
                                      ...createContextValues.fields
                                    ];
                                    newFields[ind] = {
                                      ...newFields[ind],
                                      type: UserContextMessageType[opt.value]
                                    };

                                    setCreateContextValues((prev) => ({
                                      ...prev,
                                      fields: newFields
                                    }));
                                  }}
                                  menuPortalTarget={document.body}
                                  className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                  classNames={reactSelectClassNames}
                                />
                                <Button
                                  type="button"
                                  icon={TrashIcon}
                                  color="red"
                                  variant="light"
                                  onClick={() => {
                                    const newFields = [
                                      ...createContextValues.fields
                                    ];
                                    newFields.splice(ind, 1);

                                    setCreateContextValues((prev) => ({
                                      ...prev,
                                      fields: newFields
                                    }));
                                  }}
                                />
                              </div>
                              {field.type.toLowerCase() ===
                              UserContextMessageType.Enum ? (
                                <div className="mt-4">
                                  <label className="flex font-medium items-center gap-2 text-sm">
                                    Enum Values:
                                    <Field
                                      component={CreateSelect}
                                      id={"field-enum-values" + ind}
                                      name={"field-enum-values" + ind}
                                      placeholder="Enum Values"
                                      isMulti
                                      noOptionsMessage={() =>
                                        "Start typing to add values"
                                      }
                                      value={
                                        field.values?.map((val) => ({
                                          label: val,
                                          value: val as UserContextMessageType
                                        })) ?? []
                                      }
                                      onChange={(opts: IOption[]) => {
                                        const newFields = [
                                          ...createContextValues.fields
                                        ];
                                        newFields[ind] = {
                                          ...newFields[ind],
                                          values: opts.map((opt) => opt.value)
                                        };

                                        const newErrorFields = [
                                          ...errors.fields
                                        ];
                                        newErrorFields[ind] = {
                                          ...newErrorFields[ind],
                                          values: false
                                        };

                                        setErrors((prev) => ({
                                          ...prev,
                                          fields: newErrorFields
                                        }));

                                        setCreateContextValues((prev) => ({
                                          ...prev,
                                          fields: newFields
                                        }));
                                      }}
                                      onCreateOption={(val: string) => {
                                        const newFields = [
                                          ...createContextValues.fields
                                        ];
                                        newFields[ind] = {
                                          ...newFields[ind],
                                          values: [
                                            ...(newFields[ind].values ?? []),
                                            val
                                          ]
                                        };

                                        setCreateContextValues((prev) => ({
                                          ...prev,
                                          fields: newFields
                                        }));
                                      }}
                                      menuPortalTarget={document.body}
                                      className="block w-[60%] bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                      classNames={reactSelectClassNames}
                                    />
                                  </label>
                                  {errors.fields[ind].values ? (
                                    <FieldError
                                      message={
                                        "At least one Enum value is required!"
                                      }
                                    />
                                  ) : null}
                                </div>
                              ) : field.type.toLowerCase() ===
                                UserContextMessageType.Array ? (
                                <div>
                                  <div className="mt-4">
                                    <label className="flex font-medium items-center gap-2 text-sm">
                                      Array Format:
                                      <Field
                                        component={CreateSelect}
                                        id={"array-format" + ind}
                                        name={"array-format" + ind}
                                        placeholder="Array Format"
                                        noOptionsMessage={() =>
                                          "Start typing to add values"
                                        }
                                        options={(
                                          Object.keys(
                                            UserContextMessageType
                                          ) as Array<
                                            keyof typeof UserContextMessageType
                                          >
                                        ).map((type) => ({
                                          label: type,
                                          value: type
                                        }))}
                                        value={
                                          field.format
                                            ? {
                                                label: field.format,
                                                value:
                                                  UserContextMessageType[
                                                    field.format
                                                  ]
                                              }
                                            : null
                                        }
                                        onChange={(opt: IOption) => {
                                          const newFields = [
                                            ...createContextValues.fields
                                          ];
                                          newFields[ind] = {
                                            ...newFields[ind],
                                            format:
                                              UserContextMessageType[opt.value]
                                          };

                                          const newErrorFields = [
                                            ...errors.fields
                                          ];
                                          newErrorFields[ind] = {
                                            ...newErrorFields[ind],
                                            format: false
                                          };

                                          setErrors((prev) => ({
                                            ...prev,
                                            fields: newErrorFields
                                          }));

                                          setCreateContextValues((prev) => ({
                                            ...prev,
                                            fields: newFields
                                          }));
                                        }}
                                        menuPortalTarget={document.body}
                                        className="block w-[60%] bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                        classNames={reactSelectClassNames}
                                      />
                                    </label>
                                    {errors.fields[ind].format ? (
                                      <FieldError
                                        message={"Array Format is required!"}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              <div className="flex mt-4 gap-8">
                                <label className="flex font-medium items-center gap-2 text-sm">
                                  Required:
                                  <Field
                                    type="checkbox"
                                    id={"field-required" + ind}
                                    name={"field-required" + ind}
                                    checked={field.required}
                                    onChange={(e) => {
                                      const newFields = [
                                        ...createContextValues.fields
                                      ];
                                      newFields[ind] = {
                                        ...newFields[ind],
                                        required: e.target.checked
                                      };

                                      setCreateContextValues((prev) => ({
                                        ...prev,
                                        fields: newFields
                                      }));
                                    }}
                                    placeholder="Field Name"
                                    className="block p-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                                  />
                                </label>

                                <label className="flex font-medium items-center gap-2 text-sm">
                                  Use for partitioning:
                                  <Field
                                    type="checkbox"
                                    id={"field-partition" + ind}
                                    name={"field-partition" + ind}
                                    checked={field.partitioningField}
                                    onChange={(e) => {
                                      const newFields =
                                        createContextValues.fields.map(
                                          (_field) => ({
                                            ..._field,
                                            partitioningField: e.target.checked
                                              ? false
                                              : _field.partitioningField
                                          })
                                        );

                                      setErrors((prev) => ({
                                        ...prev,
                                        partition: false
                                      }));

                                      newFields[ind] = {
                                        ...newFields[ind],
                                        partitioningField: e.target.checked
                                      };

                                      setCreateContextValues((prev) => ({
                                        ...prev,
                                        fields: newFields
                                      }));
                                    }}
                                    placeholder="Field Name"
                                    className="block p-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                                  />
                                </label>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="text-sm flex flex-grow justify-center items-center text-contentColorLight">
                          Add some fields to your context!
                        </div>
                      )}
                    </div>

                    {context ? null : (
                      <Button
                        type="button"
                        disabled={
                          createContextValues.fields.some((f) => !f.name)
                          // createContextValues.fields.every(
                          //   (f) => !f.partitioningField
                          // )
                        }
                        variant="secondary"
                        onClick={() => {
                          const newFields = [...createContextValues.fields];
                          newFields.push({
                            name: "",
                            type: UserContextMessageType.String,
                            partitioningField: false,
                            required: false
                          });

                          const newErrorFields = [...errors.fields];
                          newErrorFields.push({
                            format: false,
                            name: false,
                            values: false
                          });

                          setErrors((prev) => ({
                            ...prev,
                            fields: newErrorFields
                          }));

                          setCreateContextValues((prev) => ({
                            ...prev,
                            fields: newFields
                          }));
                        }}
                      >
                        Add Field
                      </Button>
                    )}
                  </div>
                </div>
                {context ? null : (
                  <button
                    type="button"
                    onClick={submitUserContext}
                    className="px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                  >
                    Done
                  </button>
                )}
              </Form>
            </Formik>
          </div>

          <div></div>
        </div>

        <div className="w-5/12">
          <Formik
            initialValues={{ name: "", fields: [] }}
            validationSchema={newUserContextDefinitionSchema}
            onSubmit={submitUserContext}
          >
            <Form>
              <div className="form-group mb-5 lg:w-10/12  mt-10">
                <label className="flex font-medium text-sm mb-2">
                  Partition By:
                </label>
                <Field
                  component={ReactSelect}
                  id="partition"
                  name="partition"
                  placeholder="Partition By"
                  value={
                    createContextValues.fields
                      .filter((f) => f.partitioningField)
                      ?.map((f) => ({ label: f.name, value: f.name }))[0] ||
                    null
                  }
                  options={createContextValues.fields
                    .filter((f) => f.name)
                    .map((field) => ({
                      label: field.name,
                      value: field.name
                    }))}
                  onChange={(opt: IOption) => {
                    const newFields = createContextValues.fields.map(
                      (_field) => ({
                        ..._field,
                        partitioningField: false
                      })
                    );
                    const ind = newFields.findIndex(
                      (f) => f.name === opt.value
                    );
                    newFields[ind] = {
                      ...newFields[ind],
                      partitioningField: true
                    };

                    setErrors((prev) => ({
                      ...prev,
                      partition: false
                    }));

                    setCreateContextValues((prev) => ({
                      ...prev,
                      fields: newFields
                    }));
                  }}
                  //   className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 border rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  classNames={reactSelectClassNames}
                />
                {errors.partition ? (
                  <FieldError message={"Partition By is required!"} />
                ) : null}
              </div>
              <div className="form-group mb-3 mt-6">
                <label className="flex font-medium text-sm mb-2">Index:</label>
              </div>
              <div
                className={`flex flex-col gap-4 mt-2 p-4 border border-background-layer3`}
              >
                <div className="flex flex-col gap-4 max-h-[40vh] overflow-y-auto overflow-visible">
                  {createContextValues.index.length ? (
                    createContextValues.index.map((index, ind) => (
                      <>
                        <div
                          className={`p-2 m-1 border border-background-layer3`}
                        >
                          <div className="flex items-center gap-2  ">
                            <Field
                              component={ReactSelect}
                              id={"field-type" + ind}
                              name={"field-type" + ind}
                              isMulti
                              placeholder="Field Type"
                              options={createContextValues.fields
                                .filter((f) => f.name)
                                .map((field) => ({
                                  label: field.name,
                                  value: field.name
                                }))}
                              value={createContextValues.index[ind].map(
                                (fieldName) => ({
                                  label: fieldName,
                                  value: fieldName
                                })
                              )}
                              onChange={(opts: IOption[]) => {
                                const newIndexes = [
                                  ...createContextValues.index
                                ];

                                newIndexes[ind] = opts.map((opt) => opt.value);

                                const newErrorIndex = [...errors.index];
                                newErrorIndex[ind] = {
                                  ...newErrorIndex[ind],
                                  empty: false
                                };

                                setErrors((prev) => ({
                                  ...prev,
                                  index: newErrorIndex
                                }));

                                setCreateContextValues((prev) => ({
                                  ...prev,
                                  index: newIndexes
                                }));
                              }}
                              menuPortalTarget={document.body}
                              className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                              classNames={reactSelectClassNames}
                            />
                            <Button
                              type="button"
                              icon={TrashIcon}
                              color="red"
                              variant="light"
                              onClick={() => {
                                const newIndex = [
                                  ...createContextValues.index
                                ];
                                newIndex.splice(ind, 1);

                                setCreateContextValues((prev) => ({
                                  ...prev,
                                  index: newIndex
                                }));
                              }}
                            />
                          </div>
                          {errors.index[ind].empty ? (
                            <FieldError
                              message={"At least one field is required!"}
                            />
                          ) : null}
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="min-h-[30vh] text-sm flex justify-center items-center text-contentColorLight">
                      Having an index in your contexts will make the lookup
                      faster!
                    </div>
                  )}
                </div>

                {context ? null : (
                  <Button
                    type="button"
                    variant="secondary"
                    tooltip={
                      createContextValues.fields.length === 0
                        ? "Add some fields to your context to create an Index!"
                        : ""
                    }
                    disabled={
                      !createContextValues.fields.filter((f) => f.name).length
                    }
                    onClick={() => {
                      const newIndex = [...createContextValues.index];
                      newIndex.push([]);

                      const newErrorIndex = [...errors.index];
                      newErrorIndex.push({
                        empty: false
                      });

                      setErrors((prev) => ({
                        ...prev,
                        index: newErrorIndex
                      }));

                      setCreateContextValues((prev) => ({
                        ...prev,
                        index: newIndex
                      }));
                    }}
                  >
                    Add Index
                  </Button>
                )}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateEditUserContext;
