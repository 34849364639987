import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DetailsTab from "./device-details/fad-details-tab.component";
import DeviceDetailsTabs from "../components/fad-device-details-tabs.component";
import DataPointsTab from "./device-details/fad-data-points.component";
import DeviceShadowTab from "./device-details/fad-device-shadow.component";
import DeviceDashboard from "./device-details/fad-device-dashboard.component";
import { useFleetAndDevicesStore } from "@store/index";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import ShowLoading from "@app/shared/components/loading.component";
import { toast } from "react-toastify";
import DeviceMetadata from "./device-details/fad-device-metadata.component";

const tab_map = {
  details: 0,
  "device-shadow": 1,
  "data-points": 2,
  dashboard: 3
};

const DeviceDetails = () => {
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const deviceId = pathParams["device_id"];
  const tab = searchParams.get("tab");

  const [setSelectedDevice] = useFleetAndDevicesStore((state) => [
    state.setSelectedDevice
  ]);

  /* State Variables */
  const [activeTab, setActiveTab] = useState(tab ? tab_map[tab] : 0);

  const {
    data: devicesResponse,
    isLoading,
    isFetching,
    isError
  } = useGetDevices({ device_id: deviceId });

  useEffect(() => {
    if (isError || (!isLoading && devicesResponse?.devices?.length === 0)) {
      toast.warning("Device not found!");
    } else if (devicesResponse?.devices?.[0]) {
      setSelectedDevice(devicesResponse?.devices[0]);
    }
  }, [devicesResponse, isError, isLoading, setSelectedDevice]);

  if (isLoading || isFetching) {
    return <ShowLoading />;
  }

  const tabs = {
    0: <DetailsTab device={devicesResponse?.devices?.[0]} />,
    1: <DeviceShadowTab device={devicesResponse?.devices?.[0]} />,
    2: <DataPointsTab device={devicesResponse?.devices?.[0]} />,
    3: <DeviceDashboard device={devicesResponse?.devices?.[0]} />,
    4: <DeviceMetadata device={devicesResponse?.devices?.[0]} />
  };

  return (
    <>
      <DeviceDetailsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <>{devicesResponse?.devices?.[0] ? tabs[activeTab] : null}</>
    </>
  );
};

export default DeviceDetails;

// fad device details
