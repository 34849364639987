import React, { memo, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import NodeTemplate from "../node-template.component";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import StackedList from "../stacked-list.component";
import { ActionNode } from "../action-node";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import { useReactFlow } from "reactflow";
import { NodeType } from "@app/rule-engine/node-types.helper";
import { getDetailsFromHandleId } from "@app/rule-engine/rule-engine.helper";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { IAction, useGetActions } from "@app/shared/hooks/get/actions";
import { deserializeActionData } from "../action-node/action-node.helper";

const ActionSequenceNode = ({ id, data, isConnectable }) => {
  const reactFlow = useReactFlow();

  const [ruleData, actionData, setActionData] = useRuleEngineStore((state) => [
    state.ruleData,
    state.actionData,
    state.setActionData
  ]);

  const [actions, setActions] = useState(
    actionData[id]
      ? Object.values(actionData[id]).map((el: any, ind) => ({
          ...el,
          id: Object.keys(actionData[id])[ind]
        }))
      : []
  );
  const paramInConnectHandle = (params) => {
    console.log(params);
  };

  useEffect(() => {
    setActions(
      actionData[id]
        ? Object.values(actionData[id]).map((el: any, ind) => ({
            ...el,
            id: Object.keys(actionData[id])[ind]
          }))
        : []
    );
  }, [actionData, id]);

  const { data: shadowDefs } = useGetShadowDefinitions({
    fields: "shadow_proto_structure"
  });

  const { data: existingActions } = useGetActions();

  const onExistingActionSelect = (action: IAction) => {
    let maxId = -1;

    const _actionData = deserializeActionData(action);
    const nodeActionData = actionData[id];

    if (nodeActionData) {
      Object.keys(nodeActionData).forEach((key) => {
        // key is of type "action-node-N" where N is an index
        const id = parseInt(key.split("-")[2]);
        if (id > maxId) maxId = id;
      });
    }

    const actionNodeData = {
      action_id: action.id,
      inputValues: Object.keys(action.additional_params).reduce((acc, cur) => {
        acc[cur] = "";
        return acc;
      }, {})
    };

    setActionData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [`action-node-${maxId + 1}`]: {
          ..._actionData,
          ...actionNodeData
        }
      }
    }));
  };

  return (
    <NodeTemplate>
      <Handle
        type="target"
        position={Position.Left}
        id={`action-sequence-${id}`}
        className="bg-blue-500"
        onConnect={paramInConnectHandle}
        isConnectable={isConnectable}
        isValidConnection={(connection) => {
          const node = reactFlow.getNode(connection.source);
          if (node.type !== NodeType.ruleEditorNode) return false;

          const ruleEditorNode = node;
          if (!ruleEditorNode) {
            return true;
          }

          let { conditionId, conditionType } = getDetailsFromHandleId(
            connection.sourceHandle
          );

          const responseType =
            conditionType === "true" ? "trueSequence" : "falseSequence";

          const condition = ruleData.conditions[conditionId];

          if (connection.targetHandle.startsWith("action-sequence")) {
            if (responseType in condition && condition[responseType]) {
              // setInvalidConnectionTooltipOpen(true);
              // if (invalidConnTimer.current) {
              //   clearTimeout(invalidConnTimer.current);
              //   invalidConnTimer.current = null;
              // }
              // invalidConnTimer.current = setTimeout(() => {
              //   setInvalidConnectionTooltipOpen(false);
              // }, 3000);
              return false;
            }
          }

          return true;
        }}
      />
      <div className="min-w-[300px] min-h-[300px] px-2 flex flex-col">
        <div className="flex justify-center gap-2 mb-3 text-base">
          <div>Action Sequence</div>
        </div>
        <StackedList
          data={actions}
          setData={setActions}
          draggable={true}
          separatorElement={() => (
            <span>
              <ArrowDownIcon width={20} className="text-center mx-auto" />
            </span>
          )}
          render={(action, ind: number) => {
            return (
              <ActionNode
                actionSequenceNodeId={id}
                shadowDefs={shadowDefs}
                data={{
                  id: action.id,
                  nodeId: id,
                  actionData: actionData[id] ? actionData[id][action.id] : {},
                  setEditAction: data.setEditAction,
                  setActionData: (dataOrFunc) =>
                    typeof dataOrFunc === "function"
                      ? setActionData((prev) => ({
                          ...prev,
                          [id]: {
                            ...prev[id],
                            [action.id]: dataOrFunc(prev[id][action.id])
                          }
                        }))
                      : setActionData((prev) => ({
                          ...prev,
                          [id]: {
                            ...prev[id],
                            [action.id]: dataOrFunc
                          }
                        }))
                }}
                isConnectable={true}
              />
            );
          }}
        />
        <div className="nodrag flex mt-3">
          <button
            disabled={false}
            className="bg-green-500 text-white w-full px-2 py-1 rounded-l-sm disabled:opacity-50"
            onClick={() => {
              setActionData((prev) => ({
                ...prev,
                [id]: {
                  ...(prev[id] || {}),
                  ["action-node-" + Object.keys(prev[id] || {}).length]: {
                    name: "",
                    id: "action-node-" + Object.keys(prev[id] || {}).length
                  }
                }
              }));
            }}
          >
            Add Action
          </button>
          <TransitionedMenu
            buttonComponent={
              <div className="bg-green-500 w-full h-full hover:bg-green-400 px-2 ml-[1px] text-white rounded-r-sm">
                <ChevronDownIcon width={18} />
              </div>
            }
          >
            {existingActions?.map((action) => (
              <Menu.Item key={"fetched-action-" + action.id}>
                {({ active }) => (
                  <button
                    className={`w-full flex gap-1 items-center uppercase ${
                      active && "bg-background-layer2"
                    } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
                    onClick={() => onExistingActionSelect(action)}
                  >
                    {action.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </TransitionedMenu>
        </div>
      </div>
    </NodeTemplate>
  );
};

export default memo(ActionSequenceNode);
