import React from "react";
import { Formik, Form } from "formik";
import StyledField from "@/app/shared/components/styled-field.component";
import dateService from "@/services/date.service";
import { useFleetAndDevicesStore } from "@/store";

type IFleetSettings = {};

const FleetSettings: React.FC<IFleetSettings> = () => {
  const fleet = useFleetAndDevicesStore((state) => state.selectedFleet);

  return (
    <>
      <div className="flex gap-8 mt-4 mx-8 h-full flex-grow overflow-y-auto">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form className="flex flex-row gap-8">
            <div className="flex flex-col flex-wrap">
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Fleet ID
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="fleetID"
                    name="fleetID"
                    value={fleet.id}
                    placeholder="Fleet ID"
                    showCopyButton
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Fleet Name
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="fleetName"
                    name="fleetName"
                    value={fleet.fleet_name}
                    placeholder="Fleet Name"
                    showCopyButton
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Shadow Definition ID
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="shadowDefID"
                    name="shadowDefID"
                    value={fleet.shadow_definition_id}
                    placeholder="Shadow Definition ID"
                    showCopyButton
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Created At
                </label>

                <div className="flex items-end w-full">
                  <StyledField
                    type="text"
                    id="createdAt"
                    name="createdAt"
                    value={dateService.convertUTCToLocalDate(fleet.created_at)}
                    placeholder="Created At"
                    showCopyButton
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default FleetSettings;
