import { AddOverlayLayout } from "@/app/shared/components";
import { IDataPolicy, IOption } from "@/interfaces";
import React, { useEffect, useState } from "react";
import {
  dataCompressionOptions,
  policyInputFormatOptions
} from "../../device/utils/fad-apply-data-points-helper";
import ReactSelect from "react-select";
import {
  EDeviceDataInputCompression,
  EDeviceDataInputFormat
} from "@/interfaces/data-policies.interface";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { Button, Callout } from "@tremor/react";
import { useUpdateDeviceDataPolicy } from "@/app/shared/hooks/patch/update-device-data-policy";
import { ICreateDeviceDataPolicyPayload } from "@/app/shared/hooks/post/device-data-policy";
import { toast } from "react-toastify";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface IEditDeviceDataPolicyProps {
  overlayOpen: boolean;
  policy: IDataPolicy;
  fleetId: string;
  setOverlayOpen: (x: boolean) => void;
}

const EditDeviceDataPolicy: React.FC<IEditDeviceDataPolicyProps> = ({
  overlayOpen,
  fleetId,
  policy: initialPolicy,
  setOverlayOpen
}) => {
  const [policy, setPolicy] = useState({
    name: initialPolicy?.policy_name,
    inputFormat: policyInputFormatOptions.find(
      (opt) => opt.value === initialPolicy?.policy?.input_format
    ),
    compression: dataCompressionOptions.find(
      (opt) => opt.value === initialPolicy?.policy?.input_compression
    )
  });

  const updateMutation = useUpdateDeviceDataPolicy();

  useEffect(() => {
    setPolicy({
      name: initialPolicy?.policy_name,
      inputFormat: policyInputFormatOptions.find(
        (opt) => opt.value === initialPolicy?.policy?.input_format
      ),
      compression: dataCompressionOptions.find(
        (opt) => opt.value === initialPolicy?.policy?.input_compression
      )
    });
  }, [initialPolicy]);

  const onUpdate = () => {
    const payload: ICreateDeviceDataPolicyPayload = {
      policy_name: policy.name,
      policy: {
        ...initialPolicy.policy,
        input_compression: policy.compression.value,
        input_format: policy.inputFormat.value,
        v: initialPolicy.policy.v + 1
      }
    };

    updateMutation.mutate(
      { data: payload, fleetId, policyId: initialPolicy.id },
      {
        onSuccess: () => {
          toast.success("Updated Device Data Policy");
        }
      }
    );
  };

  if (!policy) return null;

  return (
    <AddOverlayLayout
      title="Edit Device Data Policy"
      overlayOpen={overlayOpen}
      setOverlayOpen={setOverlayOpen}
    >
      <div className="flex flex-col h-full items-center">
        <div>
          <label>
            <span className="text-xs text-contentColorLight">Policy Name</span>
            <input
              placeholder="Policy Name"
              value={policy.name}
              onChange={(e) =>
                setPolicy((prev) => ({
                  ...prev,
                  name: e.target.value
                }))
              }
              className="block min-w-[300px] px-3 py-2 bg-background placeholder:text-contentColorLighter border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
            />
          </label>
          <div className="flex mt-2 gap-2 w-full">
            <label>
              <span className="text-xs text-contentColorLight">
                Input Format
              </span>
              <ReactSelect
                placeholder="Input Format"
                isSearchable={false}
                options={policyInputFormatOptions}
                value={policy.inputFormat}
                onChange={(opt: IOption<EDeviceDataInputFormat>) =>
                  setPolicy((prev) => ({
                    ...prev,
                    inputFormat: opt
                  }))
                }
                classNames={reactSelectClassNames}
                // menuPortalTarget={document.body}
                className="min-w-[200px]"
              />
            </label>
            <label>
              <span className="text-xs text-contentColorLight">
                Compression Format
              </span>
              <ReactSelect
                placeholder="Compression"
                isSearchable={false}
                options={dataCompressionOptions}
                value={policy.compression}
                onChange={(opt: IOption<EDeviceDataInputCompression>) =>
                  setPolicy((prev) => ({
                    ...prev,
                    compression: opt
                  }))
                }
                classNames={reactSelectClassNames}
                // menuPortalTarget={document.body}
                className="min-w-[200px]"
              />
            </label>
          </div>
        </div>
        <Callout
          title="NOTE!"
          className="mt-4"
          color={"yellow"}
          icon={ExclamationTriangleIcon}
        >
          Updating the Device Data Policy will affect all the devices where
          this policy is being used!
        </Callout>
        <Button
          onClick={onUpdate}
          loading={updateMutation.isLoading}
          disabled={policy?.name?.trim().length === 0}
          className="w-full mt-8"
        >
          Update
        </Button>
      </div>
    </AddOverlayLayout>
  );
};
export default EditDeviceDataPolicy;
