import React, { useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { useFleetAndDevicesStore } from "@/store";
import { useGetFleetProps } from "@/app/shared/hooks/get/fleet-props";
import { useUpdateFleetProps } from "@/app/shared/hooks/patch/fleet-props";
import { EFleetProp, IDataPolicy } from "@/interfaces";
import EnableDeviceConnectionEvents from "@/app/shared/components/enable-device-connection-events.component";
import { useGetDeviceDataPolicies } from "@/app/shared/hooks/get/device-data-policies";
import { GenericTable } from "@/app/shared/components/generic-table";
import EditDeviceDataPolicy from "./components/edit-device-data-policy.component";
import { Badge, Button } from "@tremor/react";
import { useLocation } from "react-router-dom";

const FleetPolicies: React.FC<{}> = () => {
  const fleet = useFleetAndDevicesStore((state) => state.selectedFleet);
  const state = useLocation().state as { policyToEdit?: IDataPolicy };

  const { isLoading: fleetPropsLoading, data: fleetProps } = useGetFleetProps(
    fleet.id
  );

  const [editPolicyOpen, setEditPolicyOpen] = useState(
    state?.policyToEdit ? true : false
  );
  const [selectedPolicy, setSelectedPolicy] = useState<IDataPolicy>(
    state?.policyToEdit
  );

  const { data: policies, isLoading: policiesLoading } =
    useGetDeviceDataPolicies(fleet.id);

  const connectionEventMutation = useUpdateFleetProps(
    fleet.id,
    EFleetProp.TRACK_CONNECTION_EVENTS
  );

  const columns = useMemo(
    () => ["policy_name", "input_format", "input_compression", "update"],
    []
  );

  const rows = useMemo(
    () =>
      policies?.map((policy) => ({
        policy_name: policy.policy_name,
        input_format: (
          <Badge tooltip="Input Format">{policy.policy.input_format}</Badge>
        ),
        input_compression: (
          <Badge className="ml-2" color={"indigo"} tooltip="Input Compression">
            {policy.policy.input_compression}
          </Badge>
        ),
        update: (
          <Button
            onClick={() => {
              setSelectedPolicy(policy);
              setEditPolicyOpen(true);
            }}
          >
            Update
          </Button>
        )
      })) || [],
    [policies]
  );

  return (
    <div>
      <div className="flex gap-8 mt-4 mx-8 h-full flex-grow overflow-y-auto">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form className="flex flex-row gap-8">
            <div className="w-full">
              <EnableDeviceConnectionEvents
                className="mb-5 mt-1"
                connectionEventMutation={connectionEventMutation}
                properties={fleetProps}
                propsLoading={fleetPropsLoading}
              />
            </div>
          </Form>
        </Formik>
      </div>

      <hr />

      <div className="px-8 mt-4">
        <h2 className="mb-2 ">Device Data Point Policies </h2>
        {!policiesLoading ? (
          <GenericTable
            column={columns}
            row={rows}
            searchField={columns[0]}
            searchTerm=""
          />
        ) : null}
      </div>

      {
        <EditDeviceDataPolicy
          fleetId={fleet.id}
          overlayOpen={editPolicyOpen}
          policy={selectedPolicy}
          setOverlayOpen={setEditPolicyOpen}
        />
      }
    </div>
  );
};

export default FleetPolicies;
