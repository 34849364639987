import {
  deserializeVariable,
  isObject,
  serializeVariable
} from "@app/shared/utils/helper.util";
import {
  ICaseClause,
  IJoin,
  IQuery,
  ISelect,
  TWhere,
  Operator
} from "@interfaces/query-builder";

export type TQueryType = "user-context" | "device-data";

export interface IServerQuery {
  data_point_id?: string;
  user_context_id?: string;
  alias?: string;
  group?: string[];
  limit?: number;
  offset?: number;
  with?: { alias: string; query: IServerQuery }[];
  select?: IServerSelect[];
  where?: any;
  join?: any;
}

interface IServerSelect {
  distinct?: any;
  param?: any;
  alias?: any;
  agg?: any;
  expression?: { pattern: any; values: any };
  case?: { when_then: any[]; else: any };
  sub_query?: any;
}

export function isIQuery(value: any): value is IQuery {
  return (
    typeof value !== "string" &&
    typeof value !== "number" &&
    typeof value !== "boolean" &&
    !Array.isArray(value)
  );
}

// ---------------------- deserialize ---------------------- //

export function deserializeQueryJSON(json: IServerQuery): IQuery {
  // validate JSON:
  let valid = true;
  if (!json.data_point_id && !json.user_context_id) {
    valid = false;
  }

  if (!valid) {
    throw new Error(
      "Invalid Input JSON: One of `data_point_id` or `user_context_id` is required!"
    );
  }

  const query: IQuery = {
    dataPointId: json.data_point_id ?? "",
    contextDefinitionId: json.user_context_id ?? "",
    tableName: "",
    select: [],
    alias: json.alias ?? "",
    view: "",
    group: json.group ?? [],
    order: [],
    limit: json.limit ?? 0,
    offset: json.offset ?? 0,
    createView: false
  };

  if (json.with?.length) {
    query.withClause = json.with.map((_with) => ({
      alias: _with.alias,
      query: deserializeQueryJSON(_with.query)
    }));
  }

  let selects: ISelect[] = [];

  if (json.select?.length) {
    selects = json.select.map((_select: any) => {
      return parseSelect(_select);
    });

    query.select = selects;
  }

  if (json.where) {
    query.where = parseWhere(json.where);
  }

  if (json.join?.length) {
    query.joinClause = parseJoin(json.join);
  }

  return query;
}

const parseSelect = (_select: IServerSelect): ISelect => {
  const select: ISelect = {
    distinct: _select.distinct ?? false,
    param: _select.param ?? "",
    alias: _select.alias ?? "",
    agg: _select.agg ?? "",
    caseClause: null,
    expression: null,
    subQuery: null
  };

  if (select.param) {
    return select;
  }

  if (_select.expression) {
    if (!_select.expression.pattern) {
      throw new Error(
        "Invalid Input JSON: One of the select is an expression type but does not have a `pattern` key!"
      );
    }

    select.expression = {
      pattern: _select.expression.pattern,
      values: { ..._select.expression.values }
    };

    return select;
  }

  if (_select.case) {
    const when: ICaseClause["when"] = _select.case.when_then.map(
      (whenThen: { when: any; then: any }) => ({
        when: parseWhere(whenThen.when),
        then: parseSelect(whenThen.then)
      })
    );
    select.caseClause = {
      when,
      else: parseSelect(_select.case.else)
    };

    return select;
  }

  if (_select.sub_query) {
    const query = deserializeQueryJSON(_select.sub_query);
    select.subQuery = query;

    return select;
  }

  if (!_select.param) {
    throw new Error(
      "Invalid Input JSON: One of `case` or `expression` or `sub_query` or `param` is required in Select!"
    );
  }
};

const parseWhere = (
  _where: { [x: string]: { [x: string]: any } }[]
): TWhere => {
  const where: TWhere = _where.map((_whereAnd) =>
    Object.keys(_whereAnd).map((param) => ({
      param: param,
      condition: Object.keys(_whereAnd[param]).map((operator) => ({
        operator: operator as Operator,
        value: isObject(_whereAnd[param][operator])
          ? deserializeQueryJSON(_whereAnd[param][operator])
          : _whereAnd[param][operator]
      }))
    }))
  );

  return where;
};

const parseJoin = (_joins: any[]): IJoin[] => {
  const join: IJoin[] = _joins.map(
    (_join: {
      type: any;
      data_point_id: any;
      alias: any;
      where: any;
      on: { [x: string]: any };
    }) => ({
      type: _join.type,
      dataPointId: _join.data_point_id,
      alias: _join.alias,
      where: _join.where ? parseWhere(_join.where) : null,
      on: Object.keys(_join.on).map((a) => ({ a, b: _join.on[a] }))
    })
  );

  return join;
};

// ---------------------- serialize ---------------------- //

export function serializeQueryJSON(query: IQuery): IServerQuery {
  const out: IServerQuery = {
    data_point_id: query.dataPointId,
    user_context_id: query.contextDefinitionId,
    alias: query.alias || null,
    group: query.group,
    limit: query.limit,
    offset: query.offset
  };

  if (query.withClause?.length) {
    out.with = query.withClause.map((_with) => ({
      alias: _with.alias || null,
      query: serializeQueryJSON(_with.query)
    }));
  }

  if (query.joinClause) {
    out.join = query.joinClause.map((join) => ({
      ...join,
      on: join.on.reduce((acc, cur) => {
        acc[cur.a] = cur.b;
        return acc;
      }, {}),
      where: join.where ? serializeWhere(join.where) : null
    }));
  }

  if (query.where) {
    out.where = serializeWhere(query.where);
  }

  if (query.select?.length) {
    out.select = query.select.map((select) => serializeSelect(select));
  }

  return out;
}
function serializeSelect(select: ISelect): IServerSelect {
  const out: IServerSelect = {
    distinct: select.distinct || null,
    param: select.param || null,
    alias: select.alias || null,
    agg: select.agg || null
  };

  if (select.caseClause) {
    out.case = {
      else: serializeSelect(select.caseClause.else),
      when_then: select.caseClause.when.map((whenThen) => ({
        when: serializeWhere(whenThen.when),
        then: serializeSelect(whenThen.then)
      }))
    };
  }

  if (select.expression) {
    out.expression = select.expression;
  }

  if (select.subQuery) {
    out.sub_query = serializeQueryJSON(select.subQuery);
  }

  if (!out.param && !out.case && !out.expression && !out.sub_query) {
    throw new Error(
      "One of `case` or `expression` or `sub_query` or `param` is required in Select!"
    );
  }

  return out;
}

function serializeWhere(
  where: TWhere
): { [x: string]: { [x: string]: any } }[] {
  const out = where.map((andPart) =>
    andPart.reduce((acc, cur) => {
      if (!cur.param.trim()) {
        throw new Error(
          "One of the Where conditions does not have a param, which is required!"
        );
      }
      acc[cur.param] = cur.condition.reduce((accCond, cond) => {
        accCond[cond.operator] = isIQuery(cond.value)
          ? serializeQueryJSON(cond.value)
          : serializeVariable(cond.value);

        return accCond;
      }, {});
      return acc;
    }, {})
  );

  return out;
}
