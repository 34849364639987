import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { EProjectPropType } from "@/interfaces/project.interface";

interface IUpdateProjectPropResponse {
  ok: number;
}

export const updateProjectProp = async (
  projectId: string,
  orgId: string,
  property: EProjectPropType,
  value: any
) => {
  const { ok } = await networkService.patch<IUpdateProjectPropResponse>(
    `projects/${projectId}/properties/${property}`,
    value,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateProjectProps = (property: EProjectPropType) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (value: any) =>
      await updateProjectProp(
        selectedProject.id,
        user.selectedOrg.id,
        property,
        value
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjectProps");
      }
    }
  );

  return res;
};
