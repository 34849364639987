import React from "react";
import { Tooltip, TooltipRefProps } from "react-tooltip";
import { getPanelTimeFilterOptions } from "../dash.helper";
import { PANEL_TIME_FILTER_TOOLTIP_ID } from "../dash-constants";
import { IDashboardPanel } from "@/interfaces";

type Props = {
  tooltipRef: React.RefObject<TooltipRefProps>;
  panels: IDashboardPanel[];
  setPanels: (panels: IDashboardPanel[]) => void;
};

const DashPanelFilterTooltip: React.FC<Props> = ({
  tooltipRef,
  panels,
  setPanels
}) => {
  return (
    <>
      <Tooltip
        id="panel-custom-time-filter"
        className="z-[100] transform shadow-md"
        variant="light"
        style={{
          zIndex: 100
        }}
      />
      <Tooltip
        id={PANEL_TIME_FILTER_TOOLTIP_ID}
        ref={tooltipRef}
        variant="light"
        clickable
        openOnClick
        closeEvents={{ click: true }}
        opacity={1}
        className="z-[100] transform shadow-md"
        render={({ activeAnchor }) => (
          <div className="p-2">
            <h4 className="font-medium mb-2">Select Time Filters </h4>

            <div className="flex flex-col rounded-md border border-background-layer3">
              {getPanelTimeFilterOptions().map((opt) => (
                <div
                  key={opt.value}
                  className={`flex flex-col gap-1 p-2 cursor-pointer hover:bg-background-layer2 `}
                  onClick={() => {
                    const panelId = activeAnchor.getAttribute("data-panel-id");

                    const newPanels = [...panels].map((panel) => ({
                      ...panel,
                      customTimeFilters:
                        panel.id === panelId
                          ? {
                              start: opt.startDate,
                              end: opt.endDate
                            }
                          : { ...panel.customTimeFilters }
                    }));

                    setPanels(newPanels);
                    tooltipRef.current?.close();
                  }}
                >
                  <h5 className="font-medium">{opt.label}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
      />
    </>
  );
};

export default DashPanelFilterTooltip;
