import { IDashboardPanel } from "@/interfaces";
import { GRID_COLS } from "./dash-constants";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import dateService from "@/services/date.service";

export const sortCarouselPanels = (obj: Record<string, IDashboardPanel[]>) => {
  const sortedObj: Record<string, IDashboardPanel[]> = {};
  const rowKeys = Object.keys(obj);

  if (rowKeys.length) {
    rowKeys.forEach((row) => {
      const sortedPanels = obj[row].sort(
        (a, b) => a.definition.panel_position - b.definition.panel_position
      );

      sortedObj[row] = sortedPanels;
    });
  }
  return { ...sortedObj };
};

export const getInitialLayoutState = (
  panelsArr: IDashboardPanel[],
  savedLayouts
) => {
  return {
    currentBreakpoint: "lg",
    compactType: "vertical",
    resizeHandles: ["se"],
    mounted: true,
    // generate layouts according to the current breakpoint,
    // and merge them with the saved layouts
    layouts: {
      ...Object.keys(GRID_COLS).reduce((acc, cur) => {
        acc[cur] = generateLayout(panelsArr, GRID_COLS[cur], ["se"]);
        return acc;
      }, {}),
      ...savedLayouts
    }
  };
};

export function generateLayout(panels, maxCols: number, resizeHandles) {
  const layout = [];

  let y = 0;

  let x = 0;
  panels.forEach((panel) => {
    let curW = 1,
      curH = 1;
    if (x + curW > maxCols) {
      x = 0;
      y++;
    }

    layout.push({
      i: panel.id,
      x: x,
      y: y,
      w: curW,
      h: curH,
      minW: 1,
      minH: 1,
      // maxW: maxCols,
      static: false,
      isResizable: true,
      resizeHandles: resizeHandles
    });
    x += curW;

    if (x > maxCols) {
      x = 0;
      y++;
    }
  });

  return layout;
}

export function convertBlueprintPanelsToDashboardPanels(
  blueprintPanels: IBlueprint[]
) {
  const panelsArr: IDashboardPanel[] = [];

  // convert blueprint panels to standard panels
  blueprintPanels.forEach((bp) => {
    // typescript type check
    if ("type" in bp.definition) {
      const panel: IDashboardPanel = {
        id: bp.source_id,
        blueprint_id: bp.id,
        isBlueprint: true,
        title: bp.definition.title,
        panel_description: bp.definition.description,
        panel_type: bp.definition.type,
        definition: bp.definition.panel_definition,
        data_config: bp.data_config?.config,
        dashboard_id: "",
        project_id: bp.project_id,
        org_id: "",
        created_at: bp.created_at,
        customTimeFilters: {
          start: null,
          end: null
        }
      };
      panelsArr.push(panel);
    }
  });

  return panelsArr;
}

export const getPanelTimeFilterOptions = () => {
  const timeFilterOptions = [
    {
      value: "TODAY",
      label: (
        <>
          Today
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .startOf("day")
              .add(1, "second")
              .format("Do MMMM 'YY")}{" "}
            - {dateService.getMomentDate().format("Do MMMM 'YY")})
          </span>
        </>
      ),
      startDate: dateService.getMomentDate().startOf("day").add(1, "second"),
      endDate: dateService.getMomentDate()
    },
    {
      value: "LAST_24_HOURS",
      label: (
        <>
          Last 24 Hours
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .subtract(24, "hours")
              .format("Do MMMM 'YY")}{" "}
            - {dateService.getMomentDate().format("Do MMMM 'YY")})
          </span>
        </>
      ),
      startDate: dateService.getMomentDate().subtract(24, "hours"),
      endDate: dateService.getMomentDate()
    },
    {
      value: "LAST_7",
      label: (
        <>
          Last 7 Days
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .subtract(7, "days")
              .format("Do MMMM 'YY")}{" "}
            - {dateService.getMomentDate().format("Do MMMM 'YY")})
          </span>
        </>
      ),
      startDate: dateService.getMomentDate().subtract(7, "days"),
      endDate: dateService.getMomentDate()
    },
    {
      value: "LAST_WEEK",
      label: (
        <>
          Last Week
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .subtract(1, "week")
              .startOf("week")
              .format("Do MMMM 'YY")}{" "}
            -
            {dateService
              .getMomentDate()
              .subtract(1, "week")
              .endOf("week")
              .format("Do MMMM 'YY")}
            )
          </span>
        </>
      ),
      startDate: dateService
        .getMomentDate()
        .subtract(1, "week")
        .startOf("week"),
      endDate: dateService.getMomentDate().subtract(1, "week").endOf("week")
    },
    {
      value: "LAST_30",
      label: (
        <>
          Last 30 Days
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .subtract(30, "days")
              .format("Do MMMM 'YY")}
            - {dateService.getMomentDate().format("Do MMMM 'YY")})
          </span>
        </>
      ),
      startDate: dateService.getMomentDate().subtract(30, "days"),
      endDate: dateService.getMomentDate()
    },
    {
      value: "LAST_MONTH",
      label: (
        <>
          Last Month
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .subtract(1, "months")
              .startOf("month")
              .format("Do MMMM 'YY")}
            -
            {dateService
              .getMomentDate()
              .subtract(1, "months")
              .endOf("month")
              .format("Do MMMM 'YY")}
            )
          </span>
        </>
      ),
      startDate: dateService
        .getMomentDate()
        .subtract(1, "months")
        .startOf("month"),
      endDate: dateService.getMomentDate().subtract(1, "months").endOf("month")
    },
    {
      value: "MONTH_TO_DATE",
      label: (
        <>
          Month to Date
          <span className="text-xs">
            {" "}
            (
            {dateService
              .getMomentDate()
              .startOf("month")
              .format("Do MMMM 'YY")}{" "}
            -{dateService.getMomentDate().format("Do MMMM 'YY")})
          </span>
        </>
      ),
      startDate: dateService.getMomentDate().startOf("month"),
      endDate: dateService.getMomentDate()
    }
  ];

  return timeFilterOptions;
};
