import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { EProjectPropType } from "@/interfaces/project.interface";

interface IProjectPropsResponse {
  ok: number;
  data: Record<EProjectPropType, any>;
}

export const getProjectProps = async (
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IProjectPropsResponse>(
    `/projects/${projectId}/properties`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetProjectProps = (
  params: any = {},
  successCb: ((data: Record<EProjectPropType, any>) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getProjectProps", user.selectedOrg.id, selectedProject.id, params],
    async () => {
      const props = await getProjectProps(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(props);

      return props;
    },
    {
      enabled: !!user.selectedOrg.id && !!selectedProject.id
    }
  );

  return res;
};
