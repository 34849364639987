import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { EFleetProp } from "@/interfaces";
import {
  IDataPolicyBody,
  ITrackConnectionEventsBody
} from "@/interfaces/props.interface";

interface IUpdateFleetPropResponse {
  ok: number;
}

export const updateFleetProp = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  property: EFleetProp,
  value: ITrackConnectionEventsBody | IDataPolicyBody
) => {
  const { ok } = await networkService.patch<IUpdateFleetPropResponse>(
    `projects/${projectId}/fleets/${fleetId}/properties/${property}`,
    value,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateFleetProps = (fleetId: string, property: EFleetProp) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (value: ITrackConnectionEventsBody | IDataPolicyBody) =>
      await updateFleetProp(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        property,
        value
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFleetProps");
      }
    }
  );

  return res;
};
