import { copyToClipboard } from "@app/shared/utils/helper.util";
import { IUserContextDefinition } from "@interfaces/user-contexts.interface";
import dateService from "@services/date.service";
import { Field, Form, Formik } from "formik";
import React from "react";

interface IUserContextDefinitionDetailsProps {
  context: IUserContextDefinition;
}

const UserContextDefinitionDetails: React.FC<
  IUserContextDefinitionDetailsProps
> = ({ context }) => {
  return (
    <div className="flex-1 flex gap-8 mt-4">
      <div className="w-[60%]">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="w-full ">
              <DetailsItem value={context.name} label={"Name"} />
              <DetailsItem value={context.id} label={"Context ID"} />
              <DetailsItem
                value={dateService.convertUTCToLocalDate(context.created_at)}
                label={"Created At"}
              />
              <DetailsItem
                value={context.version.toString()}
                label={"Version"}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default UserContextDefinitionDetails;

const DetailsItem = ({ value, label }: { value: string; label: string }) => (
  <div className="mb-5">
    <label className="text-sm font-medium text-contentColor">{label}</label>

    <div className="flex items-end w-full">
      <Field
        type="text"
        id="shadowId"
        name="shadowId"
        value={value}
        readOnly
        placeholder="{label}"
        className="block w-[350px] p-3 mt-2 bg-background-layer1 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
      />
      <div>
        <button
          type="button"
          onClick={() => copyToClipboard(value)}
          className="block ml-3.5 bg-[#166ff629] p-3 rounded"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
              fill="#546CCC"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
);
