export enum EProjectPropType {
  TIER = "tier",
  HOSTING_PLATFORM = "hosting_platform",
  REGION = "region",
  TIER_LIMIT = "tier_limit",
  CONSUMER_ISSUER_URL = "consumer_issuer_url",
  CONSUMER_CLIENT_ID = "consumer_client_id",
  CONSUMER_CONNECTION_TYPE = "consumer_connection_type",
  CONSUMER_CONNECTION_NAME = "consumer_connection_name",
  TRACK_CONNECTION_EVENTS = "track_connection_events",
  LOGGING_POLICY = "logging_policy",
  DATA_POLICY = "data_policy",
  CONSUMER_ENABLE_SIGNUPS = "consumer_enable_signups",
  CONSUMER_USER_METADATA_KEY_PERMISSIONS = "consumer_user_metadata_key_permissions",
  CONSUMER_DEVICE_ASSOCIATION_PERMISSIONS = "consumer_device_association_permissions"
}
