import Modal from "@app/shared/components/modal.component";

import { Button } from "@tremor/react";

interface IDPPInfoModalProps {
  open: boolean;
  setOpen: (x: boolean | ((x: boolean) => boolean)) => void;
}

const DataPointPoliciesInfoModal = ({ open, setOpen }: IDPPInfoModalProps) => {
  return (
    <Modal
      className="overflow-visible max-w-xl w-full"
      open={open}
      setOpen={setOpen}
    >
      <div className="p-4">
        <h1 className="text-2xl mb-2 pb-2 border-b border-background-layer3 font-medium">
          Data Point Policies
        </h1>
        {/* <Callout
          title="INFO"
          icon={InformationCircleIcon}
          className="mt-4 text-sm"
        >
          {newRuleData.triggerType === "PLATFORM" ? (
            <>
              Need to notify your team when a <b>device goes offline?</b> This
              rule will help you execute any arbitrary action or call external
              APIs whenever your device is disconnected from Golain.
              <p className="mt-2 text-xs italic">
                <b>Note: </b>Please define a{" "}
                <Link
                  className="underline"
                  to={"/fleet-and-devices/projects?tab=details"}
                >
                  {" "}
                  fleet-level policy
                </Link>{" "}
                or a{" "}
                <Link
                  className="underline"
                  to={"/settings/project-settings/project-details"}
                >
                  project-level policy
                </Link>{" "}
                for tracking device disconnection events.
              </p>
            </>
          ) : newRuleData.triggerType === "HTTP" ? (
            <>
              Define a custom <b>HTTP endpoint</b> using this rule type and
              execute any business logic! This Rule can be executed by making
              an HTTP request to the endpoint defined in the trigger.
            </>
          ) : newRuleData.triggerType === "MQTT" ? (
            <>
              This Rule will be triggered every time the device changes it's
              shadow or posts a new data point.
              <p className="mt-2 text-xs italic">
                <b>Note: </b>You can filter the devices that will execute this
                Rule inside the trigger definition.
              </p>
            </>
          ) : newRuleData.triggerType === "LOCATION" ? (
            <>
              This Rule will be triggered as soon as a device satisfies the
              GeoFence event chosen (Enter, Exit or Near).
              <p className="mt-2 text-xs italic">
                <b>Note: </b>You can create a GeoFence by drawing a polygon on
                the map, and editing it as needed.
              </p>
            </>
          ) : null}
        </Callout> */}
        <div className="flex w-full justify-end gap-2 mt-6">
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DataPointPoliciesInfoModal;
